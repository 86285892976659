import axios from 'axios';
import { useEffect } from 'react';
import { useAuthContext } from './auth';

const AxiosInterceptorsSetup = ({
    setIsNavigate
}) => {
    const { dispatch } = useAuthContext();

    useEffect(() => {
        axios.interceptors.response.use(
            response => {
                return response
            },
            error => {
                if (error.response.status === 401) {
                    dispatch({type: 'LOGOUT'});
                    window.localStorage.clear();
                    setIsNavigate({
                        path: '/login',
                        error: error.response?.data?.error ? error.response?.data?.error : ''
                    });
                    return '';
                }
                return Promise.reject(error);
            }
        );
    }, [])
};

export default AxiosInterceptorsSetup;