import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';

const Loader = ({ showLoader, loan }) => {
    const texts = [
        'Loading...',
        'Please wait a moment',
        'Waiting for response...',
        'Retrieving bank statement',
        'Processing bank statement...',
        'Almost there...'
    ];
    const [count, setCount] = useState(0);

    useEffect(() => {
        setInterval(() => {
            setCount(prev => prev === 5 ? prev - 5 : prev + 1);
        }, 2000);
    }, []);

    return (
        <>
            <Modal
            show={showLoader}
            id="progressModal"
            backdrop="static"
            centered="true"
            size="lg"
            keyboard={false}>
                <Modal.Body className="text-center">
                    <FontAwesomeIcon icon={'fas fa-compass'} className='fa-5x fa-spin'/>
                </Modal.Body>
                {
                    loan && loan.stagesId === 2 && (
                        <p className="text-center">{ texts[count] }</p>
                    )
                }
            </Modal>
        </>
    );
}

export default Loader