import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthContext } from '../../hooks/auth'
import { useOutletContext } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';

const EditMember = () => {
    const salutations = ['Mr', 'Mrs', 'Miss', 'Ms'];
    const { consumer } = useAuthContext();
    const [consumerDetails, setConsumerDetails, loan] = useOutletContext();
    const [passwordError, setPasswordError] = useState(null);
    const [contactError, setContactError] = useState(null);
    const [reminderError, setReminderError] = useState(null);
    const [passwordSpin, setPasswordSpin] = useState(false);
    const [contactSpin, setContactSpin] = useState(false);
    const [reminderSpin, setReminderSpin] = useState(false);
    const [validationError, setValidationError] = useState([]);
    const [successType, setSuccessType] = useState(null);
    const [formData, setFormData] = useState(null);

    const validateFields = (e) => {
        const id = e.target.id;
        if(id === 'addressSearch') {
            if(e.target.value) {
                const index = validationError.findIndex(x => x === 'address');
                if(index !== -1) {
                    validationError.splice(index, 1);
                    setValidationError([...validationError]);
                }
            }else{
                if(!validationError.includes('address')){
                    validationError.push('address');
                    setValidationError([...validationError]);
                }
            }
        } else if(id === 'mobile') {
            if(e.target.value) {
                const index = validationError.findIndex(x => x === 'mobile');
                if(index !== -1) {
                    validationError.splice(index, 1);
                    setValidationError([...validationError]);
                }

                if(!/^04[\d]{8}$/.test(e.target.value)){
                    if(!validationError.includes('mobile-format')){
                        validationError.push('mobile-format');
                    }
                } else {
                    const index = validationError.findIndex(x => x === 'mobile-format');
                    if(index !== -1) {
                        validationError.splice(index, 1);
                        setValidationError([...validationError]);
                    }
                }
            }else{
                if(!validationError.includes('mobile')){
                    validationError.push('mobile');
                    setValidationError([...validationError]);
                }
            }
        } else if(id === 'newPassword') {
            if(!e.target.value) {
                if(!validationError.includes('password')){
                    validationError.push('password');
                    setValidationError([...validationError]);
                }
                return false;
            } else {
                const index = validationError.findIndex(x => x === 'password');
                if(index !== -1) {
                    validationError.splice(index, 1);
                    setValidationError([...validationError]);
                }
            }
            
            if(e.target.value?.length < 6) {
                if(!validationError.includes('password-min-length')){
                    validationError.push('password-min-length');
                    setValidationError([...validationError]);
                }
            } else {
                const index = validationError.findIndex(x => x === 'password-min-length');
                if(index !== -1) {
                    validationError.splice(index, 1);
                    setValidationError([...validationError]);
                }
            }

            if(e.target.value > 12) {
                if(!validationError.includes('password-max-length')){
                    validationError.push('password-max-length');
                    setValidationError([...validationError]);
                }
            }else {
                const index = validationError.findIndex(x => x === 'password-max-length');
                if(index !== -1) {
                    validationError.splice(index, 1);
                    setValidationError([...validationError]);
                }
            }
            
            if (!/^(?=.*[a-zA-Z])(?=.*[0-9]).{0,50}$/.test(e.target.value)) {
                if(!validationError.includes('password-format')){
                    validationError.push('password-format');
                    setValidationError([...validationError]);
                }
            } else {
                const index = validationError.findIndex(x => x === 'password-format');
                if(index !== -1) {
                    validationError.splice(index, 1);
                    setValidationError([...validationError]);
                }
            }

            if(e.target.value !== formData.confirmPassword) {
                if(!validationError.includes('password-match')){
                    validationError.push('password-match');
                    setValidationError([...validationError]);
                }
            }else {
                const index = validationError.findIndex(x => x === 'password-match');
                if(index !== -1) {
                    validationError.splice(index, 1);
                    setValidationError([...validationError]);
                }
            }
        } else if(id === 'newConfirmPassword') {
            if(e.target.value !== formData.password) {
                if(!validationError.includes('password-match')){
                    validationError.push('password-match');
                    setValidationError([...validationError]);
                }
            }else {
                const index = validationError.findIndex(x => x === 'password-match');
                if(index !== -1) {
                    validationError.splice(index, 1);
                    setValidationError([...validationError]);
                }
            }
        }
    }

    const updateConsumer = (e) => {
        const id = e.target.id;
        var query;
        if(id === 'updateContact') {
            query = 'contact';
            setContactSpin(true);
        } else if(id === 'changePassword') {
            query = 'password';
            setPasswordSpin(true);
        } else if(id === 'reminderOff' || id === 'reminderOn') {
            query = 'reminder';
            formData.isReminder = (id === 'reminderOn' ? 1 : 0);
            setFormData({...formData});
            setReminderSpin(true);
        }

        if(validationError.length !== 0){
            return false;
        }

        axios.post('/consumers/' + consumer.token + '?type=' + query, formData)
        .then((response) => {
            if(id === 'updateContact') {
                setContactSpin(false);
            } else if(id === 'changePassword') {
                setPasswordSpin(false);
                document.querySelector('#newPassword').value = '';
                document.querySelector('#newConfirmPassword').value = '';
            } else if(id === 'reminderOff' || id === 'reminderOn') {
                setReminderSpin(false);
            }

            if(response.status === 200){
                if(id === 'updateContact') {
                    setSuccessType('contact');
                    var ls = localStorage.getItem('consumer');
                    if(ls) {
                        ls = JSON.parse(ls);
                        ls.address = response.data.data.address;
                        localStorage.setItem('consumer', JSON.stringify(ls));
                    }
                } else if(id === 'changePassword') {
                    setSuccessType('password');
                } else if(id === 'reminderOff' || id === 'reminderOn') {
                    setSuccessType('reminder');
                }
            }
        })  
        .catch((err) => {
            const error = err.response?.data?.error ? err.response?.data?.error : err.message;
            if(err.response?.data?.validationError) {
                if(id === 'updateContact') {
                    setContactSpin(false);
                } else if(id === 'changePassword') {
                    setPasswordSpin(false);
                } else if(id === 'reminderOff' || id === 'reminderOn') {
                    setReminderSpin(false);
                }
                setValidationError(err.response?.data?.validationError);
            }else{
                if(id === 'updateContact') {
                    setContactSpin(false);
                    setContactError(error);
                } else if(id === 'changePassword') {
                    setPasswordSpin(false);
                    setPasswordError(error);
                } else if(id === 'reminderOff' || id === 'reminderOn') {
                    setReminderSpin(false);
                    setReminderError(error);
                }
            }
        })
    }

    useEffect(() => {
        if(consumerDetails) {
            setFormData({
                mobile: consumerDetails?.address?.mobile,
                phoneHome: consumerDetails?.address?.phoneHome,
                rawAddress: consumerDetails?.address?.rawAddress,
                city: consumerDetails?.address?.city,
                postcode: consumerDetails?.address?.postcode,
                state: consumerDetails?.address?.regionName,
                unit: consumerDetails?.address?.unit,
                streetName: consumerDetails?.address?.streetName,
                streetNumber: consumerDetails?.address?.streetNumber,
                country: consumerDetails?.address?.countryName,
                isReminder: consumerDetails?.isReminder,
                password: null,
                confirmPassword: null
            })
        }
    }, [consumerDetails])

    return (
        <div className="profile">
            <h3>Edit Profile</h3>
            {
                consumerDetails && (
                    <div className="card form">
                        <div className="card-body">
                            <p className="card-title">
                                <FontAwesomeIcon icon={'fas fa-exclamation-triangle'} style={{ color: 'var(--bg-theme)' }}/>
                                You will not be able to update your details while an advance request is being processed.</p>
                        
                            <div className="card">
                                <p className="card-header">Your personal details</p>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <label htmlFor="title">Title</label>
                                                <select id="title" className="form-control custom-select" defaultValue={consumerDetails?.title} disabled>
                                                    {
                                                        salutations.map((salutation) => {
                                                            return (
                                                                <option value={salutation} key={salutation}>{salutation}</option>
                                                            )
                                                        })
                                                    }
                                                    <option></option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <label htmlFor="firstName">First Name</label>
                                                <input type="text" id="firstName" className="form-control" defaultValue={consumerDetails?.firstName} disabled/>
                                            </div>
                                        </div>
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <label htmlFor="middleName">Middle Name(s)</label>
                                                <input type="text" id="middleName" className="form-control" defaultValue={consumerDetails?.middleName} disabled/>
                                            </div>
                                        </div>
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <label htmlFor="lastName">Last Name</label>
                                                <input type="text" id="lastName" className="form-control" defaultValue={consumerDetails?.lastName} disabled/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <label htmlFor="email">Email</label>
                                                <input type="email" className="form-control" autoComplete="off" defaultValue={consumerDetails?.email} disabled/>
                                            </div>
                                        </div>
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <label htmlFor="dateofbirth">Date of Birth <small>(dd/mm/yyyy)</small></label>
                                                <input type="text" className="form-control" placeholder="DD/MM/YYYY" defaultValue={moment(consumerDetails?.dateofbirth).format('DD/MM/YYYY')} maxLength={10} disabled/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <p className="card-header border-top">Contact details</p>
                                <div className="card-body contact">
                                    {
                                        loan && loan?.isVerifiedMobile ? 
                                            <>
                                                <div className="row">
                                                    <div className="col-sm">                              
                                                        <div className="form-group">
                                                            <label htmlFor="mobile">Mobile Number</label>
                                                            {
                                                                consumer.address.verifiedMobile ?
                                                                <>
                                                                    <div className="input-group">
                                                                        <input type="text" id="mobile" className="form-control" defaultValue={formData?.mobile} maxLength={10} disabled/>
                                                                        <div className="input-group-append">
                                                                            <div className="input-group-text verified-mobile">
                                                                                <FontAwesomeIcon icon={'fas fa-check'}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <small id="mobileHelp" className="form-text text-primary">Mobile Number is verified.</small>
                                                                </> : 
                                                                <div>
                                                                    <input type="text" className="form-control" maxLength={10} defaultValue={formData?.mobile} disabled/>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-sm">
                                                        <div className="form-group">
                                                            <label htmlFor="phoneHome">Home Phone</label>
                                                            <input type="text" id="phoneHome" className="form-control" defaultValue={formData?.phoneHome} maxLength={10} disabled/>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group mb-4">
                                                    <label htmlFor="address">Address</label>
                                                    <input className="form-control" id="address" defaultValue={formData?.rawAddress} disabled/>
                                                </div>
                                            </>
                                        :
                                        <>
                                            <div className="row">
                                                <div className="col-sm">
                                                    <div className="form-group">
                                                        <label htmlFor="mobile">Mobile Number</label>
                                                        {
                                                            consumer.address?.verifiedMobile ? 
                                                            <>
                                                                <div className="input-group">
                                                                    <input type="text" id="mobile" className="form-control" maxLength={10} defaultValue={formData?.mobile} disabled/>
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text verified-mobile">
                                                                            <FontAwesomeIcon icon={'fas fa-check'}/>
                                                                            <FontAwesomeIcon icon={'fas fa-star-of-life'} className="d-none"/>
                                                                        </div>
                                                                        <button className="btn btn-danger cancel-mobile d-none" type="button">
                                                                            <FontAwesomeIcon icon={'fas fa-times'}/>
                                                                        </button>
                                                                        <button className="btn btn-primary edit-mobile" type="button">
                                                                            <FontAwesomeIcon icon={'far fa-edit'}/>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <small id="mobileHelp" className="form-text text-primary">Mobile Number is verified.</small>
                                                            </> 
                                                            :
                                                            <>
                                                                <div className="input-group">
                                                                    <input 
                                                                    type="text" 
                                                                    id="mobile" 
                                                                    className="form-control" 
                                                                    defaultValue={formData?.mobile} 
                                                                    maxLength={10}
                                                                    onChange={(e) => {
                                                                        validateFields(e);
                                                                        setFormData(prev => ({...prev, mobile: e.target.value}))
                                                                    }}
                                                                    style={{ borderColor: validationError && (validationError.includes('mobile') || validationError.includes('mobile-format')) && 'red' }}/>
                                                                    <div className="input-group-append">
                                                                        <div className="input-group-text">
                                                                            <FontAwesomeIcon icon={'fas fa-star-of-life'}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-sm">
                                                    <div className="form-group">
                                                        <label htmlFor="phoneHome">Home Phone</label>
                                                        <input 
                                                        type="text" 
                                                        id="phoneHome" 
                                                        className="form-control" 
                                                        defaultValue={formData?.phoneHome} 
                                                        onChange={(e) => {
                                                            setFormData(prev => ({...prev, phoneHome: e.target.value}))
                                                        }}
                                                        maxLength={10}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group mb-4">
                                                <label htmlFor="addressSearch">Address</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">
                                                            <FontAwesomeIcon icon={'fas fa-search-location'}/>
                                                        </div>
                                                    </div>
                                                    <input 
                                                    type="text" 
                                                    id="addressSearch" 
                                                    className="form-control" 
                                                    autoComplete="off" 
                                                    defaultValue={formData?.rawAddress}
                                                    onChange={(e) => {setFormData(prev => ({...prev, rawAddress: e.target.value}));validateFields(e);}}
                                                    style={{ borderColor: validationError && validationError.includes('address') && 'red' }}/>
                                                    <div className="input-group-append">
                                                        <div className="input-group-text">
                                                            <FontAwesomeIcon icon={'fas fa-star-of-life'} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <input type="text" id="addressSearchJson" hidden/>
                                            </div>
                                            
                                            <div className="row">
                                                <div className="form-group col-6 col-sm-3">
                                                    <label htmlFor="unit">Unit</label>
                                                    <input 
                                                    type="text" 
                                                    id="unit" 
                                                    className="form-control form-control-sm" 
                                                    defaultValue={formData?.unit}
                                                    onChange={(e) => {setFormData(prev => ({...prev, unit: e.target.value}))}}/>
                                                </div>

                                                <div className="form-group col-6 col-sm-3">
                                                    <label htmlFor="streetNumber">Street Number</label>
                                                    <input 
                                                    type="text"
                                                    className="form-control form-control-sm" 
                                                    id="streetNumber" 
                                                    defaultValue={formData?.streetNumber}
                                                    onChange={(e) => {setFormData(prev => ({...prev, streetNumber: e.target.value}))}}/>
                                                </div>

                                                <div className="form-group col-sm">
                                                    <label htmlFor="streetName">Street Name</label>
                                                    <input 
                                                    type="text" 
                                                    id="streetName" 
                                                    className="form-control form-control-sm" 
                                                    defaultValue={formData?.streetName}
                                                    onChange={(e) => {setFormData(prev => ({...prev, streetName: e.target.value}))}}/>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col">
                                                    <label htmlFor="city">City</label>
                                                    <input type="text" 
                                                    id="city" 
                                                    className="form-control form-control-sm" 
                                                    defaultValue={formData?.city}
                                                    onChange={(e) => {setFormData(prev => ({...prev, city: e.target.value}))}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <label htmlFor="regionName">State</label>
                                                    <input type="text" 
                                                    id="regionName" 
                                                    className="form-control form-control-sm" 
                                                    defaultValue={formData?.state}
                                                    onChange={(e) => {setFormData(prev => ({...prev, state: e.target.value}))}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <label htmlFor="postcode">Postcode</label>
                                                    <input type="text" 
                                                    id="postcode" 
                                                    className="form-control form-control-sm" 
                                                    defaultValue={formData?.postcode}
                                                    onChange={(e) => {setFormData(prev => ({...prev, postcode: e.target.value}))}}/>
                                                </div>
                                                <div className="form-group col">
                                                    <label htmlFor="countryName">Country</label>
                                                    <input type="text" 
                                                    className="form-control form-control-sm" 
                                                    defaultValue={formData?.countryName}
                                                    onChange={(e) => {setFormData(prev => ({...prev, country: e.target.value}))}}/>
                                                </div>
                                            </div>
                                            {
                                                contactError && (
                                                    <div className="alert alert-danger">{contactError}</div>
                                                )
                                            }
                                            {
                                                validationError && validationError.includes('mobile') && (
                                                    <div className="alert alert-danger">Mobile is required</div>
                                                )
                                            }
                                            {
                                                validationError && validationError.includes('mobile-format') && (
                                                    <div className="alert alert-danger">Invalid mobile number format.</div>
                                                )
                                            }
                                            {
                                                validationError && validationError.includes('address') && (
                                                    <div className="alert alert-danger">Address is required.</div>
                                                )
                                            }
                                            {
                                                successType && successType === 'contact' && (
                                                    <div className="alert alert-success">Successfully updated.</div>
                                                )
                                            }
                                            <div className="text-end">
                                                {
                                                    contactSpin && (
                                                        <FontAwesomeIcon icon={'fas fa-circle-notch'} className="fa-spin contact-spin"/>
                                                    )
                                                }
                                                <button className="btn btn-primary update-contact" id="updateContact" onClick={updateConsumer}>Update</button>
                                            </div>
                                        </>
                                    }
                                </div>

                                <p className="card-header border-top">Bank details</p>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <label htmlFor="bsb">BSB</label>
                                                <input type="text" id="bsb" className="form-control" value={loan?.bankStatement?.latestSelectedAccount?.accountBSB} disabled/>
                                            </div>
                                        </div>
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <label htmlFor="accountNumber">Account number</label>
                                                <input type="text" id="accountNumber" className="form-control" value={loan?.bankStatement?.latestSelectedAccount?.accountNumber}  disabled/>
                                            </div>
                                        </div>
                                        <div className="col-sm">
                                            <div className="form-group">
                                                <label htmlFor="accountHolder">Account holder(s)</label>
                                                <input type="text" className="form-control" id="accountHolder" value={loan?.bankStatement?.latestSelectedAccount?.accountHolder}  disabled/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <p className="card-header border-top">Change Password</p>
                                <div className="card-body password">            
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="newPassword">New Password</label>
                                                <input 
                                                type="password" 
                                                id="newPassword" 
                                                className="form-control" 
                                                maxLength={12} 
                                                autoComplete="off"
                                                onChange={(e) => {
                                                    validateFields(e);
                                                    setFormData(prev => ({...prev, password: e.target.value}))
                                                }}/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="confirmPassword">Confirm Password</label>
                                                <input 
                                                type="password" 
                                                id="newConfirmPassword"
                                                className="form-control" 
                                                maxLength={12} 
                                                autoComplete="off"
                                                onChange={(e) => {
                                                    validateFields(e);
                                                    setFormData(prev => ({...prev, confirmPassword: e.target.value}))
                                                }}/>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        passwordError && (
                                            <div className="alert alert-danger">
                                                { passwordError }
                                            </div>
                                        )
                                    }
                                    {
                                        validationError && validationError.includes('password-match') && (
                                            <div className="alert alert-danger">Password doesn't match confirmation</div>
                                        )
                                    }
                                    {
                                        validationError && validationError.includes('password-min-length') && (
                                            <div className="alert alert-danger">Password is too short. Minimum 6 characters.</div>
                                        )
                                    }
                                    {
                                        validationError && validationError.includes('password-max-length') && (
                                            <div className="alert alert-danger">Password is too long. Maximum 12 characters.</div>
                                        )
                                    }
                                    {
                                        validationError && validationError.includes('password-format') && (
                                            <div className="alert alert-danger">Password should contain letter and number</div>
                                        )
                                    }
                                    {
                                        successType && successType === 'password' && (
                                            <div className="alert alert-success">Successfully updated.</div>
                                        )
                                    }

                                    <div className="text-end">
                                        {
                                            passwordSpin && (
                                                <FontAwesomeIcon icon={'fas fa-circle-notch'} className="fa-spin password-spin"/>
                                            )
                                        }
                                        <button className="btn btn-primary change-password" id="changePassword" onClick={updateConsumer}>Change</button>
                                    </div>
                                </div>

                                <p className="card-header border-top">Reminder</p>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <p className="card-text">Turn off if you do not want to receive a notification of your repayment 1 day and 3 days before .</p>
                                        </div>
                                        <div className="col-sm-6 text-end">
                                            {
                                                reminderSpin && (
                                                    <FontAwesomeIcon icon={'fas fa-circle-notch'} className="reminder-spin fa-spin"/>
                                                )
                                            }
                                            <div className="btn-group btn-group-toggle reminder" data-toggle="buttons">
                                                <label className={"btn btn-primary " + (formData?.isReminder && 'active')}>
                                                    <input type="radio" 
                                                    name="reminder" 
                                                    id="reminderOn" 
                                                    autoComplete="off" hidden 
                                                    defaultChecked={formData?.isReminder}
                                                    onClick={updateConsumer}/> On
                                                </label>
                                                <label className={"btn btn-primary " + (!formData?.isReminder && 'active')}>
                                                    <input type="radio" 
                                                    name="reminder" 
                                                    id="reminderOff" 
                                                    autoComplete="off" 
                                                    hidden 
                                                    defaultChecked={!formData?.isReminder}
                                                    onClick={updateConsumer}/> Off
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        reminderError && (
                                            <div className="alert alert-danger mt-3">{reminderError}</div>
                                        )
                                    }
                                    {/* {
                                        successType && successType === 'reminder' && (
                                            <div className="alert alert-success">Successfully updated.</div>
                                        )
                                    } */}
                                </div>                   
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default EditMember