import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import axios from 'axios'
import Spinner from 'react-bootstrap/Spinner';
import { AiFillCloseCircle } from "react-icons/ai";

const Attachment = ({ index, attachment, removeAttachment }) => {
    const [showError, setShowError] = useState(false);
    const [extension, setExtension] = useState(null);
    const [mouseover, setMouseOver] = useState(false);

    useEffect(() => {
        if(attachment.filename) {
            const extension = attachment.filename.split('.')[attachment.filename.split('.').length - 1];
            setExtension(extension);
        }else {
            const extension = attachment.name.split('.')[attachment.name.split('.').length - 1];
            setExtension(extension);
        }
    }, []);

    return (
        <div style={{ width: '100px', margin: '16px' }}>
            <div className="attachment-card card position-relative" 
            data-token={attachment.token}
            data-index={index}
            onMouseOver={(e) => {setMouseOver(true)}}
            onMouseLeave={(e) => {setMouseOver(false)}}
            style={{ 
                height: '100px', 
                borderRadius: '20px', 
                border: 'none',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundImage: extension && 
                (extension === 'png' || 
                extension === 'jpeg' || 
                extension === 'jpg' || 
                extension === 'webp' ||
                extension === 'heic') 
                ? (`url(${attachment.location ? (global.config.properties.apiURL + attachment.location + attachment.filename) : attachment.base64})`) 
                : (`linear-gradient(to bottom,#eee,#ddd)`)
                }}>
                <div className="card-body d-flex flex-column justify-content-between" 
                style={{ padding: '1.5em 1em', color: attachment.uploading ? 'lightgrey' : '' }}>
                    {
                        mouseover && (
                            <>
                                <div className="file-size bg-white text-center">
                                    <strong>{attachment.fileStat?.size}</strong>{attachment.fileStat?.unit.toUpperCase()}
                                </div>
                                <div className="file-name text-center" 
                                style={{ whiteSpace: 'pre', overflow: 'hidden' }}>
                                    {
                                        attachment.filename && (
                                            <span className="bg-light p-1">{ attachment.filename }</span>
                                        )
                                    }
                                </div>
                            </>
                        )
                    }
                </div>
                {
                    attachment.uploading && !attachment.isFailed && (
                        <div className="position-absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <Spinner animation='border'/>
                        </div>
                    )
                }
                {
                    attachment.isFailed === 1 && (
                        <>
                            <div className="position-absolute" style={{ bottom: '5%', left: '50%', transform: 'translateX(-50%)' }}>
                                <AiFillCloseCircle 
                                className="text-danger" 
                                style={{ fontSize: '28px' }} 
                                onMouseOver={() => {setShowError(true)}}
                                onMouseLeave={() => {setShowError(false)}}/>
                            </div>
                            {
                                showError && (
                                    <div className="alert alert-danger position-absolute" 
                                    style={{ top: '100%', left: '50%', transform: 'translateX(-50%)', zIndex: 99 }}>{ attachment.error }</div>
                                )
                            }
                        </>
                    )
                }
            </div>
            {
                attachment.uploading && !attachment.isFailed && (
                    <p className="pt-3 text-center text-primary" onClick={removeAttachment}>Cancel upload</p>
                )
            }
            {
                attachment.isFailed && (
                    <p className="pt-3 text-center text-primary" onClick={removeAttachment}>Remove file</p>
                )
            }
            {
                !attachment.uploading && (
                    <>
                        <div>
                            <input type="date" className="form-control text-secondary mt-3" value={''} disabled/>
                        </div>
                        <p className="pt-3 text-center text-primary" onClick={removeAttachment}>Remove file</p>
                    </>
                )
            }
        </div>
    )
}

const StageFour = ({
    loan, 
    setLoan, 
    loanCriteria, 
    setLoanCriteria, 
    showLoader, 
    setShowLoader, 
    getLoanDetails 
}) => {
    const [validationError, setValidationError] = useState([]);
    const [error, setError] = useState(null);

    const uploadAttachment = async (e) => {
        const token = $(e.currentTarget).parents('.form-group').attr('data-token');
        const group = $(e.currentTarget).parents('.form-group').attr('data-group');
        const files = e.target.files;

        var formdata = new FormData();
        formdata.append('token', token);
        for(var i=0;i<files.length;i++) {
            const file = e.target.files[i];
            const type = file.type.split('/')[0];
            formdata.append('file[]', file);
            console.log(file.name)
            if (group === 'employmentDetails') {
                var applicationIndex = loan.applications[group].findIndex(x => x.token === token);
                if(applicationIndex === -1) {
                    global.config.methods.errorResponse('Error! Attachment not found!');
                    return false;
                }
                file.uploading = true;
                if(type === 'image') {
                    const base64 = await global.config.methods.getBase64(file);
                    file.base64 = base64;
                }
                loan.applications[group][applicationIndex].attachments.push(file);
            } else { 
                file.uploading = true;
                if(type === 'image') {
                    const base64 = await global.config.methods.getBase64(e.target.files[0]);
                    file.base64 = base64;
                }
                loan.applications[group].attachments.push(file);
            }
            console.log(file.base64)
        }
        setLoan({...loan});

        axios.post('/loans/' + loan.token + '/attachments', formdata)
        .then((response) => {
            if(response.status === 200) {
                const attachments = response.data.data.attachments;
                for(var i=0;i<files.length;i++){
                    const file = files[i];
                    var index;
                    if(group === 'employmentDetails') {
                        index = loan.applications[group][applicationIndex].attachments.findIndex(x => x === file);
                        if(index === -1) {
                            global.config.methods.errorResponse('Error. Something went wrong.');
                            return false;
                        }
                        loan.applications[group][applicationIndex].attachments[index] = attachments[i];
                    }else{
                        index = loan.applications[group].attachments.findIndex(x => x === file);
                        if(index === -1) {
                            global.config.methods.errorResponse('Error. Something went wrong.');
                            return false;
                        }
                        loan.applications[group].attachments[index] = attachments[i];
                    }
                }
                setLoan({...loan});
            }
        })
        .catch((err) => {
            const error = err.response?.data?.error ? err.response?.data?.error : err.message;
            global.config.methods.errorResponse(error);
        }) 
    }

    const triggerFile = (e) => {
        const file = $(e.currentTarget).find('input[type=file]');
        file.trigger('click');
    }

    const removeAttachment = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const target = $(e.currentTarget);
        const token = target.siblings('.attachment-card').attr('data-token');
        const groupToken = target.parents('.form-group').attr('data-token');
        const groupName = target.parents('.form-group').attr('data-group');
        const index = target.siblings('.attachment-card').attr('data-index');
        if(index === -1) {
            global.config.methods.errorResponse('HTML DOM element not found.');
            return false;
        }
        const group = loan.applications[groupName];
        if(!group) {
            global.config.methods.errorResponse('Error. Group not found.');
            return false;
        }

        if(groupName === 'employmentDetails') {
            const employment = group.find(x => x.token === groupToken);
            if(!employment) {
                global.config.methods.errorResponse('Employment details not found.');
                return false;
            }

            if(!token) {
                employment.attachments.splice(index, 1);
                setLoan({...loan});
                return false;
            }
        } else {
            if(!token) {
                group.attachments.splice(index, 1);
                setLoan({...loan});
                return false;
            }
        }
        
        axios.delete('/loans/' + loan.token + '/attachments/' + token)
        .then((response) => {
            if(response) {
                if(groupName === 'employmentDetails') {
                    const employment = group.find(x => x.token === groupToken);
                    if(!employment) {
                        global.config.methods.errorResponse('Employment details not found.');
                        return false;
                    }
                    employment.attachments.splice(index, 1);
                } else {
                    group.attachments.splice(index, 1);
                }
                setLoan({...loan});
            }
        })
        .catch((err) => {
            const error = err.response?.data?.error ? err.response?.data?.error : err.message;
            global.config.methods.errorResponse(error);
        })
    }

    const next = () => {
        setShowLoader(true);
        const agreedUptodate = $('#agreedUptodate');
        if(!agreedUptodate) {
            setShowLoader(false);
            global.config.methods.errorResponse('HTML element not found.');
            return false;
        }
        if(!agreedUptodate.is(':checked')) {
            if(!validationError.includes('agreedUptodate')) {
                validationError.push('agreedUptodate');
                setValidationError([...validationError]);
            }
            setShowLoader(false);
            return false;
        }

        if(validationError.length === 0) {
            var formdata = new FormData();
            formdata.append('agreeToUpdate', (agreedUptodate.is(':checked') ? 1 : 0));

            axios.post('/loans/' + loan.token + '/manage', formdata)
            .then((response) => {
                setShowLoader(false);
                if(response.status === 200) {
                    getLoanDetails();
                } 
            })
            .catch((err) => {
                setShowLoader(false);
                const error = err.response?.data?.error ? err.response?.data?.error : err.message;
                setError(error);
            })
        }
    }

    const dragOverHandler = (e) => {
        e.preventDefault();
        $(e.currentTarget).css('opacity', .5);
    }

    const dragLeaveHandler = (e) => {
        e.preventDefault();
        $(e.currentTarget).css('opacity', 1);
    }

    const dropHandler = (e) => {
        e.preventDefault();
        const target = $(e.currentTarget)
        const file = e.dataTransfer.files[0];
        const files = e.dataTransfer.files;
        const token = target.parents('.form-group').attr('data-token');
        const group = target.parents('.form-group').attr('data-group');
        const application = loan.applications[group].find(x => x.token === token);

        var formdata = new FormData();
        formdata.append('token', token);

        for(var i=0;i<files.length;i++) {
            const file = files[i];
            formdata.append('file[]', file);
            target.css('opacity', 1);
            file.uploading = 1;
            if(!application) {
                global.config.methods.errorResponse('Error. Something went wrong.');
                return false;
            }
            application.attachments.push(file);
        }
        setLoan({...loan});

        axios.post('/loans/' + loan.token + '/attachments', formdata)
        .then((response) => {
            if(response.status === 200) {
                const attachments = response.data.data.attachments;
                for(var i=0;i<files.length;i++){
                    const file = files[i];
                    const index = application.attachments.findIndex(x => x === file);
                    if(index === -1) {
                        global.config.methods.errorResponse('Error. Something went wrong.');
                        return false;
                    }
                    application.attachments[index] = attachments[i];
                }
                setLoan({...loan});
            }
        })
        .catch((err) => {
            const error = err.response?.data?.error ? err.response?.data?.error : err.message;
            var uploadingFile = application.attachments.find(x => x === file);
            uploadingFile.isFailed = 1;
            uploadingFile.error = error;
            setLoan({...loan});
        }) 
    }
    
    return (
        <div className="step6 application-step position-relative mb-4" id="applicationStep6">
            {
                error && (
                    <div className="alert alert-danger">{ error }</div>
                )
            }
            <div className="card form mb-4">
                <div className="card-body uploadFiles" id="uploadFiles">
                    <h3 className="card-title text-center">Upload your files</h3>
                    <p className="card-subtitle mb-3 text-muted text-center">
                        Please upload your files, then click &quot;Submit&quot; to finish your application.
                    </p>
                    <p className="card-subtitle mb-3 text-muted text-center">
                        You may find below has preloaded file which you provided in previous application and still valid to be used.
                        <br/>
                        You are required to upload if has more recent document. Otherwise, you may click &quot;Submit&quot;
                    </p>
                    <div id="all_file_upload_div">
                        <div className="row" id="income_upload_div">
                        </div>
                    </div>
                    {
                        loan && loan.applications.employmentDetails.map((employment) => {
                            return (
                                <div className="col-sm-12" key={employment.id}>
                                    <div className="form-group" data-token={employment.token} data-group="employmentDetails">
                                        <span className="file-upload-label">Upload your payslip for {employment.value.companyName}</span>
                                        <div className="card upload-card" 
                                        onClick={triggerFile} 
                                        onDragOver={dragOverHandler}
                                        onDragLeave={dragLeaveHandler}
                                        onDrop={dropHandler}>
                                            <div className="card-body bg-light p-0 dropzone">
                                                {
                                                    employment.attachments.length > 0
                                                    ?
                                                    <div className="d-flex overflow-scroll">
                                                    {
                                                        employment.attachments.map((attachment, index) => {
                                                            return(
                                                                <Attachment 
                                                                key={index}
                                                                index={index}
                                                                attachment={attachment} 
                                                                removeAttachment={removeAttachment}/>
                                                            )
                                                        })
                                                    }
                                                    </div>
                                                    :
                                                    <p className="dz-default dz-message bg-secondary text-white text-center">
                                                        <span>Drop files here to upload</span>
                                                    </p> 
                                                }
                                                <input type="file" onChange={uploadAttachment} multiple={true} hidden/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <div className="col-sm-12">
                        <div className="form-group medicare" data-token={loan.applications.medicare.token} data-group="medicare">
                            <span className="file-upload-label">Upload the photo of your medicare</span>
                            <div className="card upload-card" 
                            onClick={triggerFile}
                            onDragOver={dragOverHandler}
                            onDragLeave={dragLeaveHandler}
                            onDrop={dropHandler}>
                                <div className="card-body bg-light p-0 dropzone">
                                    {
                                        loan.applications.medicare.attachments?.length > 0 
                                        ? 
                                        <div className="d-flex overflow-scroll">
                                        {
                                            loan.applications.medicare.attachments.map((attachment, index) => {
                                                return(
                                                    <Attachment 
                                                    key={index}
                                                    index={index}
                                                    attachment={attachment} 
                                                    removeAttachment={removeAttachment}/>
                                                )
                                            })
                                        }
                                        </div>
                                        :
                                        <p className="dz-default dz-message bg-secondary text-white text-center">
                                            <span>Drop files here to upload</span>
                                        </p>
                                    }
                                    <input type="file" onChange={uploadAttachment} multiple={true} hidden/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="form-group photo-id" data-token={loan.applications.photoId.token} data-group="photoId">
                            <span className="file-upload-label">Upload the photo of your identification</span>
                            <div className="card upload-card" 
                            onClick={triggerFile}
                            onDragOver={dragOverHandler}
                            onDragLeave={dragLeaveHandler}
                            onDrop={dropHandler}>
                                <div className="card-body bg-light p-0 dropzone">
                                    {
                                        loan.applications.photoId.attachments?.length > 0 
                                        ? 
                                        <div className="d-flex overflow-scroll">
                                        {
                                            loan.applications.photoId.attachments.map((attachment, index) => {
                                                return(
                                                    <Attachment 
                                                    key={index}
                                                    index={index}
                                                    attachment={attachment} 
                                                    removeAttachment={removeAttachment}/>
                                                )
                                            })
                                        }
                                        </div>
                                        :
                                        <p className="dz-default dz-message bg-secondary text-white text-center">
                                            <span>Drop files here to upload</span>
                                        </p>
                                    }
                                    <input type="file" onChange={uploadAttachment} multiple={true} hidden/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="summaryCriteria">
                <div className="form-group form-check">
                    <input type="checkbox" className="form-check-input" id="agreedUptodate" onClick={(e) => {
                        if(e.target.checked) {
                            const index = validationError.findIndex(x => x === e.target.id);
                            if(index !== -1) {
                                validationError.splice(index, 1);
                                setValidationError([...validationError]);
                            }
                        } else {
                            if(!validationError.includes(e.target.id)){
                                validationError.push(e.target.id);
                                setValidationError([...validationError]);
                            }
                        } 
                    }}/>
                    <label className={"form-check-label " + (validationError && validationError.includes('agreedUptodate') && 'text-danger')} htmlFor="agreedUptodate">I agree that all information in my application is true and up-to-date.</label>
                    {
                        validationError && validationError.includes('agreedUptodate') && (
                            <div className="invalid-feedback show-error">Must be answered.</div>
                        )
                    }
                </div>
            </div>
            <div className="row">
                <div className="col text-center">
                    <button 
                    className="btn btn-lg btn-warning text-white submit btn-goto-next" 
                    onClick={next}
                    style={{ width: '200px' }}>Submit</button>
                </div>
            </div>
        </div>
    )
}

export default StageFour