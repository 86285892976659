import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../hooks/auth';

const WarningModal = () => {
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    const { consumer } = useAuthContext();

    return (
      <>
        <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        centered="true"
        size="lg"
        keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h5 className="modal-title" id="warningModalCenterTitle">
                        <FontAwesomeIcon icon={'fas fa-exclamation-triangle'}/>
                        <strong> WARNING - Do you really need a loan today? *</strong>
                    </h5>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>It can be expensive to borrow small amounts of money and borrowing may not solve your money problems.</p>
                <p>Check your options <u>before</u> you borrow:</p>
                <ul>
                    <li>For information about other options for managing bills and debts, 
                        ring 1800 007 007 from anywhere in Australia to talk to a free and independent financial counsellor</li>
                    <li>Talk to your electricity, gas, phone or water provider to see if you can work out a payment plan</li>
                    <li>
                        If you are on government benefits, ask if you can receive an advance from Centrelink: <br/>
                        <Link to={'http://www.humanservices.gov.au/advancepayments'} target="_blank" style={{ textDecoration: "none" }}>www.humanservices.gov.au/advancepayments</Link>
                    </li>
                </ul>
                <p>
                    The Government's 
                    <Link to={"https://www.moneysmart.gov.au/"} target="_blank" style={{ textDecoration: "none" }}> MoneySmart </Link>
                    website shows you how small amount loans work and suggests other options that may help you.
                </p>

                <p className="text-center">
                    <small>*This statement is an Australian Government requirement <br/>
                    under the National Consumer Credit Protection Act 2009.</small>
                </p>

                <div className="text-center">
                    <div className="row">
                        <div className="col-sm-2"></div>
                        {
                            consumer ? 
                            (<div className="col-sm-8">
                                <Button variant="secondary" className='btn btn-block btn-primary w-100' onClick={handleClose}>
                                CONTINUE
                                </Button>
                            </div>) :
                            (
                                <>
                                    <div className="col-sm-4 mb-1 mb-sm-0">
                                        <Link to={'/login'} className='btn btn-block btn-dark w-100' role='button' aria-pressed='true'>LOGIN</Link>
                                    </div>
                                    <div className="col-sm-4">
                                        <Button variant="secondary" className='btn btn-block btn-primary w-100' onClick={handleClose}>
                                        CONTINUE
                                        </Button>
                                    </div>
                                </>
                            )
                        }
                        <div className="col-sm-2"></div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
      </>
    );
}

export default WarningModal