import $ from 'jquery';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const ForgotPassword = () => {
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const { state } = useLocation();

    const submitHandler = (e) => {
        e.preventDefault();
        
        const form = $(e.currentTarget).get(0);
        const formdata = new FormData(form);

        axios.post('/consumers/password/forgot', formdata)
        .then((response) => {
            if(response.status === 200) {
                setSuccess('Success! Please check your messages for an email reset password');
            }
        })
        .catch((err) => {
            const formError = err.response?.data?.error ? err.response?.data?.error : err.message;
            setError(formError);
        })
    }

    useEffect(() => {
        if(state){
            setError(state.error);
            window.history.replaceState({}, document.title);
        }
        // eslint-disable-next-line
    }, [state]);

    return (
        <div className="container public-container py-4">
            {
                success && (
                    <div className='alert alert-success'>{ success }</div>
                )
            }
            {
                error && (
                    <div className='alert alert-danger'>{ error }</div>
                )
            }
            <div className="row mb-5">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                    <div className="card my-5">
                        <div className="card-body bg-light">
                            <h2 className="card-title">Forgot Password?</h2>
                            <form onSubmit={submitHandler}>
                                <div className="form-group">
                                    <label htmlFor="email">Email</label>
                                    <input type="text" className="form-control" name="email"/>
                                </div>
                                <button type="submit" className="btn btn-block btn-primary w-100">Send</button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-md-3"></div>
            </div>
        </div>
    )
}

export default ForgotPassword