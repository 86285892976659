import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Next = ({ callback, text }) => {
    return (
        <div className="text-center">
            <button className="btn btn-lg next-button text-white" 
            style={{ width: '200px', backgroundColor: '#17a2b8' }}
            onClick={callback}>
                {
                    text ? 
                    <span style={{ marginLeft: '5px' }}>
                        {text}
                    </span>
                     : 
                     <>
                        <FontAwesomeIcon icon={'fas fa-step-forward'}/>
                        <span style={{ marginLeft: '5px' }}>
                            Next
                        </span>
                     </>
                }
            </button>
        </div>
    )
}
