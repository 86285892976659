import $ from 'jquery';
import axios from 'axios';
import ContractTemplate from './contractTemplate';
import ExtraContractTemplate from './extraContractTemplate';
import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../../hooks/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useOutletContext, useParams, Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

const DocumentContract = () => {
    const { consumer } = useAuthContext();
    const [consumerDetails, setConsumerDetails] = useOutletContext();
    const { contractToken } = useParams();
    const [contract, setContract] = useState(null);
    const [confirmPurpose, setConfirmPurpose] = useState(false);
    const [confirmAgreement, setConfirmAgreement] = useState(false);
    const [confirmRepayment, setConfirmRepayment] = useState(false);

    const getContract = () => {
        axios.get('/consumers/' + consumer.token + '/contracts/' + contractToken)
        .then((response) => {
            if(response.status === 200) {
                setContract(response.data.data.contract);
            }
        })
        .catch((err) => {
            const error = err.response?.data?.error ? err.response?.data?.error : err.message;
            global.config.methods.errorResponse(error);
        })
    }

    const print = () => {
        var printContents = $('.loan-contract-document').html();
        $('body').html(printContents);
        window.print();
        window.location.reload();
}

    useEffect(() => {
        getContract()
    }, []);

    return (
        <div>
            <div className="row mb-3">
                <div className="col-6">
                    <Link to={'/members/documents'} className="btn btn-primary">
                        <FontAwesomeIcon icon={'fas fa-step-backward'}/>
                        <span style={{ marginLeft: '5px' }}>LIST</span>
                    </Link>
                </div>
                <div className="col-6 text-end">
                    <button className="btn btn-primary" id="print-contract" onClick={print}>
                        <FontAwesomeIcon icon={'fas fa-print'}/>
                    </button>
                </div>
            </div>
            
            {
                contract && (
                    contract.parentContractsId ?
                    <ExtraContractTemplate
                    loan={contract.loan}
                    contract={contract}
                    consumer={consumerDetails}
                    setConfirmAgreement={setConfirmAgreement}
                    setConfirmPurpose={setConfirmPurpose}
                    setConfirmRepayment={setConfirmRepayment}
                    />
                    :
                    <ContractTemplate 
                    loan={contract.loan}
                    contract={contract}
                    consumer={consumerDetails}
                    setConfirmAgreement={setConfirmAgreement}
                    setConfirmPurpose={setConfirmPurpose}
                    setConfirmRepayment={setConfirmRepayment}/>
                )
            }
        </div>
    )
}

export default DocumentContract