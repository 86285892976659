import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import axios from 'axios';
import * as helper from './helper';
import { AuthContextProvider } from './context/authContextRedux';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import posthog from 'posthog-js';
import { PostHogProvider} from 'posthog-js/react';
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GA_ID);

posthog.init(
    process.env.REACT_APP_PUBLIC_POSTHOG_KEY,
    {
        api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
        disable_session_recording: true,
    }
);

const root = ReactDOM.createRoot(document.getElementById('root'));
const apiHeader = { 'Content-Type': 'multipart/form-data' } ;
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.withCredentials = true;
axios.defaults.headers = apiHeader;

axios.interceptors.request.use(function (config) {
    const consumer = JSON.parse(localStorage.getItem('consumer'));
    if(consumer){
        config.headers.Authorization = 'Bearer ' + consumer.accessToken.token;
    }
    return config;
});

global.config = {
    properties: {
        baseURL: window.location.origin + '/',
        // apiURL: 'http://localhost:3000/',
        apiURL: process.env.REACT_APP_BASE_URL + '/',
        apiHeader: apiHeader,
        googleKey: process.env.REACT_APP_GOOGLE_KEY
    },
    methods: helper
}

root.render(
    // <React.StrictMode>
        <AuthContextProvider>
            <PostHogProvider client={posthog}>
                <App />
            </PostHogProvider>
        </AuthContextProvider>
    // </React.StrictMode>
);