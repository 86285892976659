import React from 'react'
import { Link } from 'react-router-dom'

const ApplicationSubmitted = () => {
    return (
        <div className="stepFinal application-step position-relative mb-4" id="applicationStepFinal">
            <div className="card-body bg-light">
                <h3 className="card-title text-center mb-4">Your application has been submitted.</h3>
                <p className="card-text text-center">
                    It is now going through a responsible lending assessment, prior to preparing a contract for you, if the application is approved.
                </p>
            </div>
        </div>
    )
}

const IdentityCheck = () => {
    return ( 
        <div className="card identityCheck mb-3">
            <div className="card-body bg-light">
                <h3 className="card-title text-center mb-4">Quick identity check</h3>
                <p className="card-text">We just need a little extra information.</p>
                <p className="card-text">Keep your phone handy and we'll contact you to confirm some more details.</p>
                <p className="card-text">Alternatively, you can email us on <a href="mailto:customercare@cashbridge.com.au">customercare@cashbridge.com.au</a>.</p>
                <p className="card-text"><strong>The Cash Bridge Team</strong></p>
            </div>
        </div>
    )
}

const StageSix = ({ loan }) => {
    return (
        <div>
            <div>
                {
                    loan.identityCheck ? 
                    <IdentityCheck />
                    : 
                    <ApplicationSubmitted/>
                }
                <div className="text-center">
                    <Link to={'/members/dashboard'} className="btn btn-primary">Dashboard</Link>
                </div>
            </div>
        </div>
    )
}

export default StageSix