import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const Contact = () => {
    return (
        <div className="container-fluid public-container">
            <div className="container mb-5">
                <h2 className="text-center text-bolder sub-text mb-4" style={{ color: '#e5735c' }}>Reach out to us</h2>
                <h1 className="text-center font-weight-bold text-uppercase mb-5">We are always here to help</h1>

                <div className="row">
                    <div className="col-md-6 mb-5">
                        <p className="mb-1"><strong>Business Hours</strong> <span>9.00am – 6.00pm (AEST) Monday to Saturday</span></p>
                        <p className="mb-1"><strong>Facebook <FontAwesomeIcon icon="fab fa-facebook-messenger" /></strong><span><a href="https://m.me/CashBridge.com.au/" target="_blank" style={{ textDecoration: 'none' }}>@CashBridge.com.au</a></span></p>
                        <p className="mb-3"><strong>E-mail</strong> <span><a href="mailto:customercare@cashbridge.com.au" style={{ textDecoration: 'none' }}>customercare@cashbridge.com.au</a></span></p>

                        <p className="mb-1"><strong>Address</strong> <span>Level 9, 530 Little Collins St, Melbourne VIC 3000</span></p>	
                        <p className="mb-1"><strong>ABN</strong> <span>39 611 822 916</span></p>
                        <p className="mb-3"><strong>Austalian Credit License Number</strong> <span>505 901</span></p>
                        {/* if login display below */}
                        {/* <p className="mb-1">All communication with Cash Bridge will be made via email or Facebook Message. If your issue/problem is not resolved with customercare, please file a complaint via internal dispute resolution at <a href="mailto:idr@cashbridge.com.au">idr@cashbridge.com.au</a>.</p> */}
                    </div>
                    <div className="col-md-6">
                        <h5 className="font-weight-light">ABOUT US</h5>			

                        <p className="font-weight-light">Cash Bridge is a humble family owned Australian company.</p>
                        <p className="font-weight-light">We commit to ensure all our customers enjoy a friendly and enjoyable experience during the life cycle of their loan with us. Consider C.B before everyone else and make Cash Bridge your only provider for all your financial needs. ABN: 39 611 822 916. Australian Credit License: 505 901.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Contact