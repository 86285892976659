import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import $ from 'jquery'
import { useAuthContext } from '../../hooks/auth'

const ReplyFailure = () => {
    const { scheduleToken } = useParams();
    const [expired, setExpired] = useState(0);
    const [loan, setloan] = useState(null);
    const [contract, setContract] = useState(null);
    const [schedule, setSchedule] = useState(null);
    const [replyOptionToken, setReplyOptionToken] = useState(null);
    const [error, setError] = useState(null);
    const location = useLocation();
    const { consumer } = useAuthContext();

    useEffect(() => {
        const tokenArray = scheduleToken.split('-');
        tokenArray.pop();
        const contractToken = tokenArray.join('-');

        if(consumer) {
            axios.get('/consumers/' + consumer.token + '/contracts/' + contractToken)
            .then((response) => {
                if(response.status === 200) {
                    let contract = response.data.data.contract;
                    let schedule = contract.schedules.find(x => x.token === scheduleToken);
                    setloan(contract.loan);
                    setContract(contract);
                    setSchedule(schedule);
                }
            })
            .catch((err) => {
                const error = err.response?.data?.error ? err.response?.data?.error : err.message;
                setError(error);
            })
        }
    }, [consumer]);

    const selectOption = (e) => {
        $('.form-check-input').attr('checked', false);
        const target = $(e.currentTarget).siblings('input[type=radio]');
        if(!target) {
            global.config.methods.errorResponse('Error!')
            return false;
        }
        target.attr('checked', true);
        setReplyOptionToken(target.val());
    }

    const submit = (e) => {
        e.preventDefault();
        if(!selectOption){
            global.config.methods.errorResponse('Sorry, the option that you chose is not available.');
            return false;
        }

        axios.post('/loans/' + loan.token + '/contracts/' + contract.token + '/schedules/' + scheduleToken + '/reply', {replyToken: replyOptionToken})
        .then((response) => {
            if(response.status === 200) {
                window.location.reload();
            }
        })
        .catch((err) => {
            const error = err.response?.data?.error ? err.response?.data?.error : err.message;
            setError(error);
        })
    }

    useEffect(() => {
        if(schedule) {
            const now = moment();
            const expired = moment(schedule.failedAt);
            const diff = moment.duration(expired.diff(now)).asDays();
            if(Math.floor(Math.abs(diff)) > 0) {
                setExpired(1);
            }
        }
    }, [schedule]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [error]);

    return (
        schedule && 
        <div className="content reply-failure">
            {
                error && (
                    <div className="alert alert-danger">{ error }</div>
                )
            }
            {
                schedule.latestReply ? 
                <>
                    <h3 className="text-center m-4">Thank you for replying.</h3>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Your option below.</h5>
                            <div className="card-text">
                                <div className="card">
                                    <div className="card-body bg-secondary text-white">
                                        <p className="card-title text-primary">{ schedule.latestReply.option.numDesc }</p>
                                        <p className="card-text" dangerouslySetInnerHTML={{ __html: schedule.latestReply.option.description }}></p>
                                    </div>
                                </div>
                                {
                                    (schedule.latestReply.option.token === 'rOiC' ||
                                    schedule.latestReply.option.token === '7eCy') && (
                                        <div className="card">
                                            <div className="card-body text-dark">
                                                <h5 className="text-center">If you make payment <strong>TODAY</strong></h5>
                                                <div className="row">
                                                    <div className="col-md-3"></div>
                                                    <div className="col-md-6">
                                                        <table className="table table-sm mb-0">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Payment amount</td>
                                                                    <td className="text-right">${ global.config.methods.toCurrency(schedule.amount) }</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="border-top-0">Default fee</td>
                                                                    <td className="border-top-0 text-right">$35.00</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="">Sub Total</td>
                                                                    <td className="text-right">${ global.config.methods.toCurrency(parseFloat(schedule.amount) + 35) }</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className="border-top-0 text-danger">Discount</td>
                                                                    <td className="border-top-0 text-right text-danger">-$20.00</td>
                                                                </tr>
                                                                <tr>
                                                                    <td className=""><strong>Total(only today)</strong></td>
                                                                    <td className="text-right"><strong>${ global.config.methods.toCurrency(parseFloat(schedule.amount) + 35 - 20) }</strong></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="col-md-3"></div>
                                                </div>
                                                <hr/>
                                                <div className="row">
                                                    <div className="col-md-2"></div>
                                                    <div className="col-md-8">
                                                        <p>To make manual repayment, please perform a direct transfer to the nominated account.</p>
                                                        <p className=""><strong>Your contract number : { contract.contractNo }</strong></p>
                                                        <p className="mb-0"><strong>Manual Payment Details</strong></p>
                                                        <div className="mb-3">
                                                            {
                                                                consumer.va ? 
                                                                <table className="virtualaccount-details">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="pr-2">Account Name</td>
                                                                            <td>Sunbow Finance Pty Ltd</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="pr-2">BSB</td>
                                                                            <td className="bsb">{ consumer.va.bsb }</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="pr-2">Account number</td>
                                                                            <td className="accountNumber">{ consumer.va.accountNumber }</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                :
                                                                <>
                                                                    <button className="btn btn-primary btn-block btn-sm create-virtualaccount">Show Payment Details</button>
                                                                    <table className="virtualaccount-details">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="pr-2">Account Name</td>
                                                                                <td>Sunbow Finance Pty Ltd</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="pr-2">BSB</td>
                                                                                <td className="bsb"></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="pr-2">Account number</td>
                                                                                <td className="accountNumber"></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </>
                                                            }
                                                        </div>
                                                        <p className="mb-0"><strong>IMPORTANT:</strong></p>
                                                        <ol>
                                                            <li>Please ensure you put your contract number as reference number.</li>
                                                            <li>Once manual payment is made, please ensure you send a copy of the receipt to <a href="mailto:customercare@cashbridge.com.au">customercare@cashbridge.com.au</a></li>
                                                            <li>Account Update &#45; It may take up to two business days for the banks to clear manual repayment. Once the payment is clear, your account will be updated automatically.</li>
                                                            <li>The payment details are unique for each account, please keep it safe.</li>
                                                        </ol>
                                                    </div>
                                                    <div className="col-md-2"></div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            <p className="card-text">We will update your status, and contact you if necessary.</p>
                            <p className="card-text">If you have any queries, please do not hesitate to contact us at <a href="mailto:customercare@cashbridge.com.au">customercare@cashbridge.com.au</a></p>
                        </div>
                    </div>
                </>
                :
                <>
                    <h3 className="text-center m-4">Oops!! You have missed a repayment.</h3>
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title text-center">You have miss a repayment on <strong>the { moment(schedule.dueDate).format('Do of MMMM YYYY') }</strong>.</h5>
                            <p className="card-text text-center">Please select one of the options to prevent enforcement action within 48 hours.</p>
                            
                            <div className="card mb-3">
                                <div className="card-body bg-light failure-options">
                                    {
                                        schedule.options && schedule.options.map((option) => {
                                            return (
                                                <div className="form-check mb-2 cursor-pointer" key={ option.id }>
                                                    {
                                                        (option.token === 'rOiC' ||
                                                        option.token === '7eCy') && 
                                                        expired === 1 ?
                                                        <>
                                                            <input className="form-check-input" type="radio" name="failure-option" id={ "failureRadio" + option.order} value={ option.token } disabled/>
                                                            <label className="form-check-label w-100" htmlFor={"failureRadio" + option.order}></label>
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <p className="card-title text-primary">
                                                                        <span style={{ textDecoration: "line-through" }}>{ option.numDesc }</span>
                                                                        <span className="text-darkrh">(Expired)</span>
                                                                    </p>
                                                                    <p className="card-text" style={{ textDecoration: "line-through" }}>{ option.description }</p>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <input className="form-check-input" type="radio" name="failure-option" id={"failureRadio" + option.order} value={ option.token } />
                                                            <label className="form-check-label w-100" htmlFor={"failureRadio" + option.order}></label>
                                                            <div className="card" onClick={selectOption}>
                                                                <div className="card-body">
                                                                    <p className="card-title text-primary">{ option.numDesc }</p>
                                                                    <p className="card-text" dangerouslySetInnerHTML={{ __html: option.description }}></p>
                                                                    {
                                                                        (option.token === 'rOiC' ||
                                                                        option.token === '7eCy') && 
                                                                        expired === 0 && (
                                                                            <div className="card">
                                                                                <div className="card-body text-dark">
                                                                                    <div className="row">
                                                                                        <div className="col-md-3"></div>
                                                                                        <div className="col-md-6">
                                                                                            <h5 className="text-center">If you make payment <strong>TODAY</strong></h5>
                                                                                            <table className="table table-sm mb-0">
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td>Payment amount</td>
                                                                                                        <td className="text-right">${ global.config.methods.toCurrency(schedule.amount) }</td>
                                                                                                    </tr>
                                                                                                    <tr className="border-top-0">
                                                                                                        <td className="border-top-0">Default fee</td>
                                                                                                        <td className="border-top-0 text-right">${ global.config.methods.toCurrency(35) }</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td className="">Sub Total</td>
                                                                                                        <td className="text-right">${ global.config.methods.toCurrency(parseFloat(schedule.amount) + 35) }</td>
                                                                                                    </tr>
                                                                                                    <tr className="border-top-0">
                                                                                                        <td className="border-top-0 text-danger">Discount</td>
                                                                                                        <td className="border-top-0 text-right text-danger">-${ global.config.methods.toCurrency(20) }</td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td className=""><strong>Total(only today)</strong></td>
                                                                                                        <td className="text-right"><strong>${ global.config.methods.toCurrency(parseFloat(schedule.amount) + 35 - 20) }</strong></td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                        <div className="col-md-3"></div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {
                                    schedule.replyOverdue && (
                                        <>
                                            <div className="card-body bg-light position-absolute w-100 h-100" style={{ opacity: "0.8" }}>
                                            </div>
                                            <div className="card-body bg-light py-auto position-absolute w-100">
                                                <div className="card">
                                                    <div className="card-body bg-white">
                                                        <h3 className="text-center">Option Expired.</h3>
                                                        <h5 className="text-center">Please contact us at <a href="mailto:customercare@cashbridge.com.au">customercare@cashbridge.com.au</a> to stop $5/day default fee.</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                            <p className="card-text">If you have any queries, please do not hesitate to contact us at <a href="mailto:customercare@cashbridge.com.au">customercare@cashbridge.com.au</a></p>
                            <div className="text-center">
                                {
                                    !schedule.replyOverdue && replyOptionToken && (
                                        <button className="btn btn-primary" onClick={submit}>SUBMIT</button>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </>
            }  
        </div>
    )
}

export default ReplyFailure