import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAuthContext } from '../../../hooks/auth';
import axios from 'axios';
import StageOne from '../stage1';
import StageTwo from '../stage2';
import StageThree from '../stage3';
import StageFour from '../stage4';
import StageFive from '../stage5';
import StageSix from '../stage6';
import PreStage from '../prestage';
import $ from 'jquery';

const BusinessLoan = ({
    ready, 
    loan, 
    setLoan, 
    loanAmount, 
    setLoanAmount, 
    loanCriteria, 
    setLoanCriteria,
    setError,
    showLoader,
    setShowLoader
}) => {
    const { loanToken } = useParams();
    const { consumer } = useAuthContext();

    const getLoanDetails = () => {
        axios.get('/loans/' + loanToken)
        .then((response) => {
            if(response.status === 200){
                const loan = response.data.data.loan;
                setLoan(loan);
            }
        })
        .catch((err) => {
            const error = err.response?.data?.error ? err.response?.data?.error : err.message;
            setError(error);
        })
    }

    useEffect(() => {
        setLoanAmount(50000);
    }, []);

    useEffect(() => {
        if(loanToken && consumer) {
            getLoanDetails();
        }
    }, [loanToken]);

    return (
        <>
        {
            ready && !loanToken ? 
                <PreStage 
                type={'business'}
                showLoader={showLoader}
                setShowLoader={setShowLoader}
                setLoan={setLoan}
                loanAmount={loanAmount}
                setLoanAmount={setLoanAmount}/> :
            ready && loan && loanCriteria && loan.stagesId === 1 ?
                <StageOne 
                loan={loan} 
                setLoan={setLoan} 
                loanCriteria={loanCriteria}
                setLoanCriteria={setLoanCriteria}
                showLoader={showLoader} 
                setShowLoader={setShowLoader}
                getLoanDetails={getLoanDetails}/> : 
            ready && loan && loanCriteria && loan.stagesId === 15 ? 
                <StageTwo 
                loan={loan} 
                setLoan={setLoan} 
                loanCriteria={loanCriteria}
                setLoanCriteria={setLoanCriteria}
                showLoader={showLoader} 
                setShowLoader={setShowLoader}
                getLoanDetails={getLoanDetails}/> : 
            ready && loan && loanCriteria && loan.stagesId === 2 ? 
                <StageThree 
                loan={loan} 
                setLoan={setLoan} 
                loanCriteria={loanCriteria}
                setLoanCriteria={setLoanCriteria}
                showLoader={showLoader} 
                setShowLoader={setShowLoader}
                getLoanDetails={getLoanDetails}/> :
            ready && loan && loanCriteria && loan.stagesId === 3 ? 
                <StageFour
                loan={loan} 
                setLoan={setLoan} 
                loanCriteria={loanCriteria}
                setLoanCriteria={setLoanCriteria}
                showLoader={showLoader} 
                setShowLoader={setShowLoader}
                getLoanDetails={getLoanDetails}/> :
            ready && loan && loanCriteria && loan.stagesId === 19 ? 
                <StageFive loan={loan} 
                setLoan={setLoan} 
                loanCriteria={loanCriteria}
                setLoanCriteria={setLoanCriteria}
                showLoader={showLoader} 
                setShowLoader={setShowLoader}
                getLoanDetails={getLoanDetails}/> 
                : 
            ready && loan && loanCriteria && loan.stagesId === 4 && (
                <StageSix loan={loan}/>
            )
        }
        </>
    )
}

export default BusinessLoan