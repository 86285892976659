import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom'
import { useAuthContext } from '../hooks/auth'
import './TopNav.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const TopNav = () => {
    const [currentNav, setNav] = useState('home');
    const [toggle, setToggle] = useState(false);
    const { consumer, dispatch } = useAuthContext();
    const { loanToken } = useParams();
    const navigate = useNavigate();
    const state = useLocation();
    const [mobile, setMobile] = useState(window.innerWidth < 768 ? true : false);

    const memberSubMenus = {
        '/members/dashboard': 'Dashboard',
        '/members/documents': 'Documents',
        '/members/edit': 'Edit Profile'
    };

    const pathNavMap = {
        '/members': 'member',
        '/contact-us': 'contact',
        '/apply': 'apply',
        '/login': 'login',
        '/': 'home',
    };

    const logoutConsumer = () => {
        localStorage.clear();
        setToggle(false);
        dispatch({type: 'LOGOUT'});
        navigate('/login');
    }

    const setNavMenuActive = () => {
        if(mobile) {
            setNav(memberSubMenus[state.pathname]);
        } else{
            if(state.pathname === '/') {
                setNav(pathNavMap[state.pathname]);
            } else{
                const module = state.pathname.split('/')[1];
                setNav((pathNavMap['/' + module]));
            }
        }

        if(loanToken){
            setNav('apply')
        }
    }

    const resize = () => {
        if(window.innerWidth < 768) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    }

    useEffect(() => {
        window.addEventListener('resize', resize);
        setNavMenuActive();
    }, [state.pathname]);

    useEffect(() => {
        setNavMenuActive();
    }, [mobile]);

    useEffect(() => {
        setToggle(false);
    }, [currentNav]);

    return (
        <div className="header">
            <div className="navbar navbar-light fixed-top navbar-expand-md bg-white px-md-5 py-0 border-bottom" style={{ height: '110px' }}>
                <Link to="/" className="h-100">
                    <img src={global.config.properties.baseURL + '/img/cashbridge_logo.jpg'} alt="logo" className="h-100"/>
                </Link>
                <button 
                className="navbar-toggler border-0" 
                type="button" 
                data-toggle="collapse" 
                data-target="#navbarSupportedContent" 
                aria-controls="navbarSupportedContent" 
                aria-expanded="false" 
                aria-label="Toggle navigation" onClick={(e) => {setToggle(!toggle)}}>
                    <span className="navbar-toggler-icon"></span>
                </button>
            
                <div className={"collapse navbar-collapse justify-content-end bg-white " + (toggle && 'show')} id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto h-100">
                        <li onClick={() => {setNav('home')}} className={"nav-item " + (currentNav === 'home' ? 'active' : '') }><Link to='/' className='w-100 nav-link btn btn-link'>HOME</Link></li>
                        <li onClick={() => {setNav('contact')}} className={"nav-item " + (currentNav === 'contact' ? 'active' : '') }><Link to='/contact-us' className='w-100 nav-link btn btn-link'>CONTACT</Link></li>
                        <li onClick={() => {setNav('apply')}} className={"nav-item " + (currentNav === 'apply' ? 'active' : '') }><Link to='/apply' className='w-100 nav-link btn btn-link'>APPLY</Link></li>
                        {
                            consumer ? 
                            <>
                                <li onClick={() => {setNav('member')}} className={"d-flex justify-content-center flex-column member-nav-link nav-item " + (currentNav === 'member' ? 'active' : '') }>
                                    {
                                        mobile ? 
                                        <>
                                            <div className={'w-100 text-center member-li'} style={{  }}>MEMBER <FontAwesomeIcon icon={'fas fa-angle-down'}/></div>
                                            <div className="w-100 dropdown-menu text-center border-0 rounded-0 py-0 show" aria-labelledby="navbarDropdown">
                                                {
                                                    Object.keys(memberSubMenus).map((key) => {
                                                        return (
                                                            <Link className={"dropdown-item " + (memberSubMenus[key] === currentNav && 'active')} to={key} key={key}>{ memberSubMenus[key] }</Link>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </>
                                        :
                                        <Link to='/members/dashboard' className='w-100 nav-link btn btn-link'>MEMBER</Link>
                                    }
                                </li>
                                <li onClick={logoutConsumer} className={"nav-item " + (currentNav === 'logout' ? 'active' : '') }>
                                    <Link className='w-100 nav-link btn btn-link'>LOGOUT</Link>
                                </li>
                            </>
                            :
                            <li onClick={() => {setNav('login')}} className={"nav-item " + (currentNav === 'login' ? 'active' : '')}><Link to='/login' className='w-100 nav-link btn btn-link'>LOGIN</Link></li>
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default TopNav