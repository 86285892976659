import axios from 'axios'
import Illion from './illion'
import CreditSense from './creditsense'
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation, useSearchParams } from 'react-router-dom'

const SelectAccounts = ({ loan, setShowLoader, getLoanDetails }) => {
    const [selectedAccount, setSelectAccount] = useState(null);
    const [error, setError] = useState(null);

    const next = (e) => {
        e.preventDefault();

        if(!selectedAccount) {
            setError('You have not choosen any account.');
            return false;
        }
        setShowLoader(true);

        const formdata = new FormData();
        formdata.append('accountToken', selectedAccount);

        axios.post('/loans/' + loan.token + '/manage', formdata)
        .then((response) => {
            setShowLoader(false);
            if(response.status === 200) {
                getLoanDetails();
            }
        })
        .catch((err) => {
            setShowLoader(false);
            const error = err.response?.data?.error ? err.response?.data?.error : err.message;
        })
    }

    return ( 
        <div 
        className="step3 application-step position-relative mb-4" 
        id="applicationStep3">
            {
                error && (
                    <div className="alert alert-danger">{ error }</div>
                )
            }
            <div className="card form mb-4">
                <div className="card-body">
                    <h3 className="card-title text-center">Select account</h3>
                    <p className="card-subtitle mb-3 text-muted text-center">Select the account your pay goes into so we can verify your income and expenses.</p>
                    <div className="card" id="accountList">
                        <div className="card-body">
                            <h4 className="card-title bankName">{ loan.bankStatement?.accounts[0]?.bankName }</h4>
                            {
                                loan && 
                                loan.bankStatement && 
                                loan.bankStatement.accounts &&
                                loan.bankStatement.accounts.map((account) => {
                                    return (
                                        <div className="row mb-2 newAccountLine" key={ account.id }>
                                            <div className="col-6 accountName">
                                                <div className="form-check">
                                                    <input className="form-check-input" 
                                                    type="radio" 
                                                    name="accountList" 
                                                    id={ account.token } 
                                                    defaultValue={ account.token }
                                                    onClick={(e) => {setSelectAccount(account.token)}}
                                                    defaultChecked={ (selectedAccount === account.token) ? true : false }/>
                                                    <label className="form-check-label" htmlFor={ account.token }>{ account.accountHolder }</label>
                                                </div>
                                            </div>
                                            <div className="col-3 accountNumber">{ account.accountNumber }</div>
                                            <div className="col-3 accountType">{ account.accountType }</div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                </div>
            </div>
            <div className="text-center">
                <button 
                className="btn btn-info btn-lg" 
                id="proceedStep4" 
                style={{ width: "200px" }}
                onClick={next}>
                    <FontAwesomeIcon icon={'fas fa-step-forward'}/>
                    <span style={{ marginLeft: '5px' }}>Next</span>
                </button>
            </div>
        </div>
    )
}

const StageThree = ({ 
    loan, 
    setLoan, 
    loanCriteria, 
    setLoanCriteria, 
    showLoader, 
    setShowLoader, 
    getLoanDetails 
}) => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const [initiateWhileLoop, setInitiateWhileLoop] = useState(false);

    useEffect(() => {
        if(initiateWhileLoop) {
            setShowLoader(true);

            setInterval(() => {
                getLoanDetails();
            }, 10000)
        }
    }, [initiateWhileLoop]);

    useEffect(() => {
        if(loan.isSavedBankStatement === 1) {
            setShowLoader(false);
        }
    }, [loan])

    return (
        loan.isSavedBankStatement === 0 
        ?
            parseInt(query.get('bankStmCh')) === 1 ? 
                <CreditSense loan={loan} setInitiateWhileLoop={setInitiateWhileLoop}/>
            : parseInt(query.get('bankStmCh')) === 2 ?  
                <Illion loan={loan} setInitiateWhileLoop={setInitiateWhileLoop}/>
            : !query.get('bankStmCh') && (
                loan.requestedBankStmCh ? 
                <CreditSense loan={loan} setInitiateWhileLoop={setInitiateWhileLoop}/>
                :
                <Illion loan={loan} setInitiateWhileLoop={setInitiateWhileLoop}/>
            )
        :
        <SelectAccounts 
        loan={loan} 
        setShowLoader={setShowLoader} 
        getLoanDetails={getLoanDetails} />
    )
}

export default StageThree