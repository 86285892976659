import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoanCalculator from '../../components/LoanCalculator';

const Home = () => {
    const [loanAmount, setLoanAmount] = useState(2000);
    const [loanPeriod, setLoanPeriod] = useState(10);
    
    return (
        <div className='container-fluid public-container' style={{ padding: 0 }}>
            <div className="card border-0 row">
            <div id="carouselTitleIndicators" className="carousel slide" data-ride="carousel" style={{ padding: 0 }}>
                <div className="carousel-inner card-img rounded-0">
                    <div className="carousel-item active">
                        <img className="d-block w-100" src="/img/Slider8.jpg" alt="First slide"/>
                    </div>
                </div>
            </div>

            <div className="card-img-overlay text-white">
                <div className="row h-100 align-items-center">
                    <div className="col-sm-2"></div>
                    {/* <!--For desktop view--> */}
                    <div className="col-sm-8 d-none d-md-block">
                        <p className="h3 text-uppercase">Cash Bridge</p>
                        <p className="display-4 text-uppercase font-weight-bold">Simply the bridge to your next paycheck.</p>
                        <div className="col-sm-2" style={{ padding: 0}}>
                        <a href="#product" className="btn apply-button action-btn">APPLY NOW</a></div>
                    </div>

                    {/* <!--For mobile view--> */}
                    <div className="col-sm-8 d-md-none">
                        <p className="h5 text-uppercase">Cash Bridge</p>
                        <p className="h1 text-uppercase font-weight-bold">Simply the bridge to your next paycheck.</p>
                        <a href="#product" className="btn btn-sm btn-primary apply-button-mobile">APPLY NOW</a>
                    </div>
                    <div className="col-sm-2"></div>
                </div>
            </div>
        </div>

        <div style={{ padding: '120px 0px' }}> 
            <div className="container">
                <h1 className="text-uppercase text-center font-weight-bold mb-3">Your loan calculation</h1>
                <h2 className="sub-text text-primary text-center mb-5"></h2>
                <LoanCalculator 
                loanAmount={loanAmount}
                setLoanAmount={setLoanAmount}
                loanPeriod={loanPeriod}
                setLoanPeriod={setLoanPeriod}
                hideFooterText={true}
                />
                <p className="sub-text text-center text-uppercase px-4"> 
                    The above example includes an establishment fee of 20% and monthly fees of 4% per month of the loan amount over the selected pay period. 
                    The example is also based on the assumption that repayments are made on time as per the original loan contract terms otherwise other fees 
                    and charges are payable.</p>
            </div>
        </div>

        <div style={{ backgroundColor: '#fff7ee', padding: '120px 0px' }}> 
            <div className="container">
                <h1 className="text-uppercase text-center font-weight-bold mb-3">Friendly and Enjoyable Experience</h1>
                <h2 className="sub-text text-primary text-center mb-5">Cash Bridge loan process</h2>
                <div className="row">
                    <div className="col-lg-4 mb-5 mb-lg-0">
                        <div className="row">
                            <div className="col-auto">
                                <span className="fa-stack fa-2x text-primary rounded position-relative">
                                    <FontAwesomeIcon className='h-100' icon={'fas fa-circle fa-stack-2x'}/>
                                    <FontAwesomeIcon className='text-white position-absolute' icon={'fas fa-check fa-stack-1x fa-inverse'} style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}/>
                                </span>
                            </div>
                            <div className="col">
                                <h4 className="text-uppercase">Easy Application Process</h4>
                                <span className="text-black-50">
                                    Apply using our easy online application process. 
                                    We only ask for essential information to allow us to conduct the financial assessment and customise your loan.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-5 mb-lg-0">
                        <div className="row">
                            <div className="col-auto">
                                <span className="fa-stack fa-2x text-primary rounded position-relative">
                                    <FontAwesomeIcon className='h-100' icon={'fas fa-circle fa-stack-2x'}/>
                                    <FontAwesomeIcon className='position-absolute text-white' icon={'fas fa-star fa-stack-1x fa-inverse'} style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}/>
                                </span>
                            </div>
                            <div className="col">
                                <h4 className="text-uppercase">Free Income and Expenses Assessment</h4>
                                <span className="text-black-50">
                                    We provide a free service to identify your income and expenses and customise the loan best suited for you.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-5 mb-lg-0">
                        <div className="row">
                            <div className="col-auto">
                                <span className="fa-stack fa-2x text-primary rounded position-relative">
                                    <FontAwesomeIcon className='h-100' icon={'fas fa-circle fa-stack-2x'}/>
                                    <FontAwesomeIcon className='text-white position-absolute' icon={'fas fa-globe fa-stack-1x fa-inverse'} style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}/>
                                </span>
                            </div>
                            <div className="col">
                                <h4 className="text-uppercase">Friendly and Enjoyable Experience</h4>
                                <span className="text-black-50">
                                    We commit to ensure all our customers enjoy a friendly and enjoyable experience during the life cycle of your loan with us.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style={{ padding: '120px 0px' }}> 
            <div className="container">
                <h1 className="text-uppercase text-center font-weight-bold mb-3">Bridging Loan</h1>
                <h2 className="sub-text text-primary text-center mb-5">Easy Application Process</h2>
                <div className="row">
                    <div className="col-md-6 mb-3"><img src="/img/bridging-Loan.jpg" className="img-fluid" alt="responsive"/></div>
                    <div className="col-md-6">
                        <p><strong>If the principal amount you borrow is between $300 – $2,000 you’ll pay:</strong></p>
                        <p><strong>Establishment fee:</strong><br/><span className="text-black-50">20% of the principal amount</span></p>
                        <p><strong>Monthly loan fee:</strong><br/><span className="text-black-50">4% of the principal a month</span></p>
                        <p><strong>Payment options range from 30 days to 6 months:<br/></strong>
                            <span className="text-black-50">
                                Actual loan term may vary depending on approved amount and your individual circumstances.
                            </span>
                        </p>
                        <p><strong>Fund transfer directly into your account.</strong></p>
                    </div>
                </div>
            </div>
        </div>

        <div className="pb-5" style={{ backgroundColor: '#fff7ee', paddingTop: '120px' }} id="product"> 
            <div className="container">
                <h1 className="text-uppercase text-center font-weight-bold mb-3">Personal and Business Finance</h1>
                <h2 className="sub-text text-primary text-center mb-5">Easy Application Process</h2>
                <div className="row mb-5">
                    <div className="col-lg-6 pr-5 mb-5 mb-lg-0 text-end">
                        <h4 className="text-uppercase">Small Amount Credit Contract</h4>
                        <h5>Personal Loan</h5>
                        <h3 className="mb-3">$300 to $2,000</h3>

                        <p className="text-black-50 mb-1">If the principal amount you borrow is between $300 – $2,000 you’ll pay:</p>
                        <p className="text-black-50 mb-1">• Establishment fee: 20%</p>
                        <p className="text-black-50">• Interest Rate of 4% per month</p>
                            
                        <Link to={'/loans/apply?type=sacc'} className={'btn btn-lg btn-outline-primary'}><span>Apply Now</span> <FontAwesomeIcon className="fas fa-edit"/></Link>
                    </div>
                    <div className="col-lg-6 pl-5 mt-5 mt-lg-0">
                        <h4 className="text-uppercase">All Other Credit Contract</h4>
                        <h5>Business Loan</h5>
                        <h3 className="mb-3">$10,000 to $100,000</h3>

                        <p className="text-black-50 mb-1">• Interest only loans from 1.5% per month</p>
                        <p className="text-black-50 mb-1">• Establishment fee: 1.5% Upfront</p>
                        <p className="text-black-50">• Compliance fee: 0.59%</p>
                            
                        <Link to={'/loans/apply?type=business'} className={'btn btn-lg btn-outline-primary'}><span>Apply Now</span> <i className="fas fa-edit"></i></Link>
                    </div>
                </div>
            </div>

            <div className="container mt-5">
                <h1 className="text-uppercase text-center font-weight-bold mb-3">Motocycle Finance</h1>
                <h2 className="sub-text text-primary text-center mb-5">Quick, easy, and hassle-free motorbike loans</h2>
                <div className="row mb-5">
                    <div className="col-lg-6 pr-5 mb-5 mb-lg-0 text-end">
                        <h4 className="text-uppercase">Motorbike Loan Contract</h4>
                        <h5></h5>
                        <h3 className="mb-3">up to $15,000</h3>

                        <p className="text-black-50 mb-1">Secured Motorycle Loan with rates from as low as 6.99% p.a.</p>
                        <p className="text-black-50">(comparison rate from 7.68% p.a.)**</p>

                        <Link to={'/loans/apply?type=motorcycle'} className='btn btn-lg btn-outline-primary'><span>Apply Now</span> <i className="fas fa-edit"></i></Link>
                    </div>
                </div>
                <p className="mb-0">
                    <small>
                        **The comparison rate for Medium Cash Loans is based on an unsecured loan of $2,100 over a 2 year term and a $400 establishment fee. 
                        The comparison rates for Personal Loans are based on an unsecured loan of $10,000 over a 3 year term and a $195 establishment fee. 
                        The comparison rate for a loan is based on a secured loan of $30,000 over 5 years with a $495 establishment fee. 
                        Cash Bridge may not offer loans for these amounts over these terms. 
                        See our full <a href="docs/feeStatement" target="_blank">Fee statement</a> for fee details.
                    </small>
                </p>
            </div>
        </div>
        </div>
    )
}

export default Home