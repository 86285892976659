import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';

const PayoutModal = ({ 
	consumerDetails, 
	setConsumerDetails, 
	show, 
	setShow 
}) => {
	
	const handleClose = () => {
		setShow(false);
	}

	const createVirtualAccount = () => {
        axios.post('consumers/' + consumerDetails.token + '/va')
        .then((response) => {
            if(response.status === 200) {
				const va = response.data.data.virtualAccount;
				consumerDetails.va = va;
				setConsumerDetails({...consumerDetails});
            }else {
                global.config.methods.warningResponse(response.data.message);
            }
        })
        .catch((err) => {
            const error = err.response?.data?.error ? err.response?.data?.error : err.message;
            global.config.methods.errorResponse(error);
        })
    }

    return (
      	<Modal 
		show={show}
		onHide={handleClose}
		backdrop="static"
		centered="true"
		size="lg"
		keyboard={false}>
			<Modal.Header>
				<Modal.Title>How do I pay out?</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p>To payout your loan, please perform a direct transfer to the nominated account.</p>
				<div className="mb-0">
					<strong>Manual Payment Details</strong>
					{
						consumerDetails.va ?
						<table className="virtualaccount-details">
							<tbody>
								<tr>
									<td style={{ paddingRight: '10px' }}>Account Name</td>
									<td>Sunbow Finance Pty Ltd</td>
								</tr>
								<tr>
									<td style={{ paddingRight: '10px' }}>BSB</td>
									<td className="bsb">{ consumerDetails.va.bsb }</td>
								</tr>
								<tr>
									<td style={{ paddingRight: '10px' }}>Account number</td>
									<td className="accountNumber">{ consumerDetails.va.accountNumber }</td>
								</tr>
							</tbody>
						</table> :
						<div>
							<button className="btn btn-primary btn-block btn-sm w-100" onClick={createVirtualAccount}>Show Payment Details</button>
						</div>
					}
					<p className="mb-0 mt-3"><strong>IMPORTANT:</strong></p>
					<ol>
						<li>Please ensure you put your contract number as reference number.</li>
						<li>Once manual payment is made, please ensure you send a copy of the receipt to <a href="mailto:customercare@cashbridge.com.au">customercare@cashbridge.com.au</a></li>
						<li>Account Update – It may take up to two business days for the banks to clear manual repayment. Once the payment is clear, your account will be updated automatically.</li>
						<li>The payment details are unique for each account, please keep it safe.</li>
					</ol>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-secondary" onClick={handleClose}>Close</button>
			</Modal.Footer>
      	</Modal>
    )
}

export default PayoutModal