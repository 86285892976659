import React from 'react'

const FeeStatement = () => {
    return (
        <div className="container public-container py-4">
            <h1>FEE STATEMENT</h1>
            <h5>We'll always show you the costs upfront, all in plain English and big print. There are no sneaky hidden fees to worry about.</h5>
            <br/>
            <br/>

            <div className="card mb-3">
                <div className="card-body">
                    <h3 className="card-title">SMALL LOAN</h3>
                    <h5 className="card-subtitle mb-2 text-muted">(If the principal amount you borrow is between $300 and $2,000)</h5>

                    <p className="card-text">
                        When you make all scheduled repayments on time, you'll only be charged the loan establishment fee and the monthly loan fee.
                        Dishonour and default fees will apply if you miss any repayments.
                    </p>

                    <table className="table table-bordered">
                        <tbody>
                            <tr>
                                <td style={{ width: '70%' }}>
                                    <strong>Establishment fee</strong>
                                    <br/>
                                    One-off fee payable per loan
                                </td>
                                <td className="text-center" style={{ width: '30%' }}>20% of principal</td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Monthly Loan Fee</strong>
                                    <br/>
                                    Charged per month or part thereof, where there is an outstanding debt balance.
                                </td>
                                <td className="text-center">4% of principal per month</td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="table table-bordered">
                        <tbody>
                            <tr>
                                <td style={{ width: '70%' }}>
                                    <strong>Dishonour Fee</strong>
                                    <br/>
                                    Charged each time a direct debit or other payment is not made in full and on time.
                                    This fee includes our reasonable internal administrative costs and external third-party costs.
                                </td>
                                <td className="text-center" style={{ width: '30%' }}>$35.00</td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Default Fee</strong>
                                    <br/>
                                    Daily recovery fee to cover reasonable costs of administrating your account while your account remains in default.
                                </td>
                                <td className="text-center">$5.00 per day</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="card">
                <div className="card-body">
                    <h3 className="card-title">MEDIUM LOAN</h3>
                    <h5 className="card-subtitle mb-2 text-muted">(If the principal amount you borrow is between $2,001 and $5,000)</h5>

                    <p className="card-text">
                        When you make all scheduled repayments on time, you'll only be charged the loan establishment fee and
                        interest charges calculated based on the applicable interest rate. Other fees will apply if you miss or reschedule any repayments.
                    </p>

                    <table className="table table-bordered">
                        <tbody>
                            <tr>
                                <td style={{ width: '70%' }}>
                                    <strong>Establishment fee</strong>
                                    <br/>
                                    One-off fee payable per loan
                                </td>
                                <td className="text-center" style={{ width: '30%' }}>$400.00</td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Interest Rate</strong>
                                    <br/>
                                    This is the Annual Percentage Rate. Interest accrues daily - and is charged monthly in arrears - on the outstanding balance of your loan.
                                </td>
                                <td className="text-center">47.6158% p.a.</td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Comparison Rate</strong>
                                    <br/>
                                    This comparison rate is based on an unsecured loan for an amount of $2,500 over 2 years and a $400 establishment fee.
                                    <br/>
                                    <strong>WARNING:</strong> This comparison rate is true only for the example given and may not include all fees and charges.
                                    Different terms, fees or other loan amounts might result in a different comparison rate.
                                    <br/>
                                    This is a prescribed calculation and Cash Bridge does not offer loans for $2,500 over a term of 2 years.
                                </td>
                                <td className="text-center">65.6597% p.a.</td>
                            </tr>
                        </tbody>
                    </table>

                    <p className="card-text">Fees that may apply</p>
                    <table className="table table-bordered">
                        <tbody>
                            <tr>
                                <td style={{ width: '70%' }}>
                                    <strong>Dishonour Fee</strong>
                                    <br/>
                                    Charged each time a direct debit or other payment is not made in full and on time.
                                    This fee includes our reasonable internal administrative costs and external third party costs.
                                </td>
                                <td className="text-center" style={{ width: '30%' }}>$35.00</td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>Reschedule Fee</strong>
                                    <br/>
                                    Charged each time a repayment is rescheduled.
                                    This is a service fee which includes our reasonable internal administrative costs.
                                </td>
                                <td className="text-center">$15.00</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default FeeStatement