import moment from 'moment';
import React, { useEffect, useState } from 'react'

const ContractTemplate = ({ 
    consumer,
    loan, 
    contract,
    setConfirmPurpose,
    setConfirmAgreement,
    setConfirmRepayment 
}) => {
    const [amountPerMonth, setAmountPerMonth] = useState(null);
    const [totalAmountToBeRepaid, setTotalAmountToBeRepaid] = useState(null);
    
    useEffect(() => {
        if(loan) {
            setAmountPerMonth(((
                parseFloat(contract.amount) + 
                parseFloat(contract.fee) + 
                parseFloat(contract.interest * contract.loanMonths)) / 
                parseInt(contract.loanMonths)).toFixed(2));

            setTotalAmountToBeRepaid(((
                parseFloat(contract.amount) + 
                parseFloat(contract.fee) + 
                parseFloat(contract.interest * contract.loanMonths))).toFixed(2));

            if(contract.signedAt) {
                setConfirmPurpose(true);
                setConfirmAgreement(true);
                setConfirmRepayment(true);
            }
        }
    }, [consumer])

    return (
        consumer && loan && contract && (
            <div className="card mb-3 bg-light loan-contract-document" id={loan.token}>
                <div className="card-body">
                    <h5 className="card-title text-center">Loan Contract</h5>
                    <p className="card-text text-center">You need to scroll to you on the terms set out in this loan contract. By electronically signing this document, you indicate your acceptance of our offer.</p>

                    <div className="card mb-3">
                        <div className="card-body">
                            <div id="contractPage" className="contractPage mb-4">
                                <p className="card-text">This <strong>Small Amount Credit Contract</strong> is made between:</p>
                                <p className="card-text">
                                    Sunbow Finance Pty Ltd, t/as Cash Bridge, ABN No: 39 611 822 916, Registered office: 912/530 Little Collins St, Melbourne VIC 3000, Email: customercare@cashbridge.com.au, Australian Credit Licence No: 505901, being the credit provider; and
                                </p>

                                <p className="card-title">I, the first Consumer (full name): { consumer.firstName }</p>
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered">
                                        <tbody>
                                            <tr>
                                                <td scope="row">Address</td>
                                                <td colSpan="3">{ consumer.address.rawAddress }</td>
                                            </tr>
                                            <tr>
                                                <td scope="row">Email</td>
                                                <td style={{ minWidth: '100px' }}>{ consumer.email }</td>
                                                <td scope="row">Mobile Number</td>
                                                <td style={{ minWidth: '100px' }}>{ consumer.address.mobile }</td>
                                            </tr>
                                            <tr>
                                                <td scope="row">Home Phone</td>
                                                <td>{ consumer.address.phoneHome }</td>
                                                <td scope="row">Work Phone</td>
                                                <td>{ consumer.address.phoneWork }</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <p className="card-title" style={{ display: 'none' }}>and I, the second Consumer (if any) (full name): </p>
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered" style={{ display: 'none' }}>
                                        <tbody>
                                            <tr style={{ display: 'none' }}>
                                                <td scope="row">Address</td>
                                                <td colSpan="3"></td>
                                            </tr>
                                            <tr style={{ display: 'none' }}>
                                                <td scope="row">Email</td>
                                                <td style={{ minWidth: '100px' }}></td>
                                                <td scope="row">Mobile Number</td>
                                                <td style={{ minWidth: '100px' }}></td>
                                            </tr>
                                            <tr style={{ display: 'none' }}>
                                                <td scope="row">Home Phone</td>
                                                <td></td>
                                                <td scope="row">Work Phone</td>
                                                <td></td>
                                            </tr>
                                            <tr style={{ display: 'none' }}>
                                                <td scope="row" colSpan="4">Where there are two Consumers, the term "Consumer" applies to both, jointly and severally.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <p className="card-title">Credit provider&#39;s representative: Cash Bridge</p>
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered">
                                        <tbody>
                                            <tr>
                                                <td scope="row" colSpan="4" className="text-center"><strong><h3>FINANCIAL TABLE</h3></strong></td>
                                            </tr>
                                            <tr>
                                                <td scope="row" colSpan="3">
                                                    <strong>CONSUMER&#39;S NET LOAN AMOUNT</strong> 
                                                    (Adjusted Credit Amount)
                                                </td>
                                                <td className="text-end" style={{ minWidth: '100px' }}>${ contract.amount }</td>
                                            </tr>
                                            <tr>
                                                <td scope="row" colSpan="4">
                                                    <strong>Ascertainable permitted fees under this Contract</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td scope="row" colSpan="3">Permitted Establishment Fee, payable on settlement date</td>
                                                <td className="text-end">${ contract.fee }</td>
                                            </tr>
                                            <tr>
                                                <td scope="row" colSpan="3">First Permitted Monthly Fee, payable on settlement date</td>
                                                <td className="text-end">${ contract.interest }</td>
                                            </tr>
                                            <tr>
                                                <td scope="row" colSpan="3"><strong>Sub-total ascertainable permitted fees,</strong> payable on settlement date</td>
                                                <td className="text-end">${ (parseFloat(contract.fee) + (contract.interest * contract.loanMonths)).toFixed(2) }</td>
                                            </tr>
                                            <tr>
                                                <td scope="row" colSpan="3"><strong>Sub-total, loan (credit amount/loan principal),</strong> as at settlement date</td>
                                                <td className="text-end">${ contract.amount }</td>
                                            </tr>
                                            <tr>
                                                <td scope="row" colSpan="4">Other permitted monthly fees (if applicable), due on the monthly anniversary of the settlement date and apportioned over the relevant payments: </td>
                                            </tr>
                                            <tr>
                                                <td scope="row">Amount per month</td>
                                                <td className="text-end" style={{ minWidth: '100px' }}>
                                                    {
                                                        contract.loanMonths && (
                                                            <span>${ amountPerMonth }</span>
                                                        )
                                                    }
                                                </td>
                                                <td scope="row">No. of months after 1st month</td>
                                                <td className="text-end">
                                                    {
                                                        contract.loanMonths && (
                                                            <span>{contract.loanMonths - 1}</span>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td scope="row" colSpan="3">Total permitted fees payable, whole contract term</td>
                                                <td className="text-end">${ contract.fee}</td>
                                            </tr>
                                            <tr>
                                                <td scope="row" colSpan="3">Total amount of interest charged</td>
                                                <td className="text-end">${ (contract.interest * contract.loanMonths).toFixed(2) }</td>
                                            </tr>
                                            <tr>
                                                <td scope="row" colSpan="3"><strong>TOTAL AMOUNT TO BE REPAID UNDER THIS CONTRACT</strong> (sum of repayments, including permitted fees and principal, subject to satisfactory repayment by the Consumer)</td>
                                                <td className="text-end">${ totalAmountToBeRepaid }</td>
                                            </tr>
                                            <tr>
                                                <td scope="row" colSpan="3"><strong>TERM OF LOAN</strong>(subject to satisfactory repayment by the Consumer)</td>
                                                <td className="text-end">
                                                    {
                                                        contract.loanWeeks && (
                                                            <span>{ contract.loanWeeks } weeks</span>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td scope="row">Commencement date of contract</td>
                                                {
                                                    contract.createdAt ?
                                                    <td>{ moment(contract.createdAt).format('DD/MM/YYYY') }</td>
                                                    :
                                                    <td>{ moment().format('DD/MM/YYYY') }</td>
                                                }
                                                <td scope="row">Purpose of Loan</td>
                                                <td><strong>Personal</strong></td>
                                            </tr>
                                            <tr>
                                                <td scope="row" colSpan="4"><strong>Repayment amounts, inclusive of principal and all permitted fees (equally apportioned)</strong> (payable after settlement date): </td>
                                            </tr>
                                            <tr>
                                                <td scope="row">Amount of each repayment (equal)</td>
                                                <td className="text-end">${ contract.repaymentAmount} {contract.repaymentFrequency}</td>
                                                <td scope="row">Number of repayments</td>
                                                <td className="text-end">{ contract.repayments }</td>
                                            </tr>
                                            <tr>
                                                <td scope="row">Amount of final repayment (if different)</td>
                                                {
                                                    contract.finalRepaymentAmount ? 
                                                    <td className="text-end">${ contract.finalRepaymentAmount }</td>
                                                    :
                                                    <td className="text-end"></td>
                                                }
                                                <td scope="row" colSpan="2">To facilitate exact repayment</td>
                                            </tr>
                                            <tr>
                                                <td scope="row">
                                                    <strong>Date of first repayment</strong>
                                                </td>
                                                <td className="text-end">{ moment(contract.firstRepaymentDate).format('DD/MM/YYYY') }</td>
                                                <td scope="row">
                                                    <strong>Date of final repayment</strong>
                                                </td>
                                                <td className="text-end">
                                                    {
                                                        contract.finalRepayment && (
                                                            <span>{ moment(contract.finalRepayment.dueDate).format('DD/MM/YYYY') }</span>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td scope="row" colSpan="4"><strong>Unascertainable fees and charges&#44;</strong> due at or from the day after date of default occurrence, being the event of default, and payable at the next scheduled repayment date by way of additional periodic payments at the end of the contracted term.</td>
                                            </tr>
                                            <tr>
                                                <td scope="row" colSpan="3">Direct Debit non-payment fee</td>
                                                <td className="text-end">$35.00</td>
                                            </tr>
                                            <tr>
                                                <td scope="row" colSpan="3">Default Management Fee - charged daily while the consumer is in default</td>
                                                <td className="text-end">$5.00</td>
                                            </tr>
                                            <tr>
                                                <td scope="row" colSpan="3">Direct Debit or payroll deduction rearrangement fee, following default</td>
                                                <td className="text-end">$20.00</td>
                                            </tr>
                                            <tr>
                                                <td scope="row" colSpan="3">Rescheduling fee following default</td>
                                                <td className="text-end">$30.00</td>
                                            </tr>
                                            <tr>
                                                <td scope="row" colSpan="3">Letter fee concerning default</td>
                                                <td className="text-end">$15.00</td>
                                            </tr>
                                            <tr>
                                                <td scope="row" colSpan="3">Email fee concerning default</td>
                                                <td className="text-end">$10.00</td>
                                            </tr>
                                            <tr>
                                                <td scope="row" colSpan="3">Enforcement expenses (debt recovery) following un-remedied default - due on day invoice reciived</td>
                                                <td className="text-end">$10.00</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p className="card-text"><strong>Repayment Schedule</strong> - payments due on the following dates</p>
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Date</th>
                                                <th scope="scope">Repayment No.</th>
                                                <th scope="scope" className="text-center">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                contract.schedules && contract.schedules.map((schedule) => {
                                                    return (
                                                        <tr key={schedule.id}>
                                                            <th>{ moment(schedule.dueDate).format('DD/MM/YYYY') }</th>
                                                            <td>{ schedule.repaymentNum }</td>
                                                            <td className='text-end'>${ schedule.amount }</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="contract-declarations">
                                    <p className="card-text">
                                        <strong>IF YOU CANNOT MAKE A PAYMENT, CONTACT CASH BRIDGE IMMEDIATELY TO DISCUSS YOUR SITUATION BY PHONE ON 03 9909 7099 OR AT <a href="http://localhost:3001/contact-us" target="_blank">http://localhost:3001/contact-us</a>
                                        </strong>.
                                    </p>
                                    <p className="card-text"><strong>Declarations</strong></p>
                                    <p className="card-text">By electronically signing this loan contract:</p>
                                    <ol>
                                        <li>You declare that you have carefully read this loan contract and that you understand that it establishes a legal contract between you and us&#x3b;</li>
                                        <li>You warrant that all information you have given directly or indirectly to us and our agents is accurate and not misleading&#x3b;</li>
                                        <li>You acknowledge that we are relying on the information you have given to enter this transaction&#x3b;</li>
                                        <li>You acknowledge that you can afford to make the repayments without undue hardship&#x3b;</li>
                                        <li>Other than this loan contract, you have not relied on any promise or representation by anybody when deciding to enter this loan contract.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-body contract-print-scroll">
                            <div className="termsconditions">
                                <div className="termsAndConditions mb-4">
                                    <h5 className="card-title text-center">TERMS AND CONDITIONS</h5>
                                    <ol>
                                        <li className="mb-3"><strong>Repayment Arrangements</strong>
                                            <ul className="list-unstyled">
                                                <li>1.1 The Credit Provider agrees to advance the credit amount/loan principal on Settlement Day.</li>
                                                <li>1.2	The Consumer agrees to repay the credit amount/loan principal and all fees listed and applicable, in accordance with this Contract.</li>
                                                <li>1.3	The Consumer agrees to repayment by way of direct debit facility from the Consumer&#39;s financial institution.  The Credit Provider does not charge for any specific arrangement, but the Consumer&#39;s financial institution may charge a fee in the relevant circumstances.</li>
                                                <li>1.4	The direct debit facility may be processed by a third party service provider, under a separate contract between the Consumer and that provider, which will incur a direct debit processing fee.  This processing fee will be paid by the Credit Provider, who shall not receive any direct financial benefit and shall not be liable for any element of that arrangement, except for payment of the service provided.</li>
                                                <li>1.5	The Consumer must ensure that their relevant bank account has sufficient funds to accommodate repayment obligations when the funds are required.</li>
                                                <li>1.6	Should the Consumer wish to alter their repayment arrangement in any way, the Consumer must contact the Credit Provider at their earliest convenience.   If this contact is not at least forty-eight (48) hours before the due date for processing a direct debit repayment, because of the necessary administration, it may not be possible to stop the transaction proceeding and this may create a default.</li>
                                                <li>1.7	The Consumer must not cancel the relevant Direct Debit Authority, or close the relevant bank account, without making timely alternate repayment arrangements.</li>
                                                <li>1.8	If any direct debit is dishonoured and reversed, the repayment will be treated as not having been made.</li>
                                                <li>1.9	If any repayment is scheduled for a non-business day and payment cannot be accommodated by the relevant financial institution, payment is presumed due on the next business day.</li>
                                                <li>1.10 Should a monthly repayment (if applicable) be due on the 29th, 30th or 31st day of a calendar month then, in a month where no such date exists, the repayment must be made on the nearest preceding business day.</li>
                                            </ul>
                                        </li>
                                        <li className="mb-3"><strong>Disbursement of Total Loan or Credit Amount</strong>
                                            <ul className="list-unstyled">
                                                <li>At settlement date, the following amounts from the total loan or credit amount will be disbursed as indicated.
                                                    <div className="table-responsive">
                                                        <table className="table table-sm table-bordered">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Net loan amount (adjusted credit amount), payable to the Consumer</td>
                                                                    <td className="text-end">${ contract.amount }</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Permitted establishment fee, payable to the Credit Provider</td>
                                                                    <td className="text-end">${ contract.fee }</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Permitted first monthly fee, payable to the Credit Provider</td>
                                                                    <td className="text-end">${ contract.interest }</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="mb-3"><strong>Unascertainable Fees and Charges</strong>
                                            <ul className="list-unstyled">
                                                <li>3.1	During the Term of the Contract, the Consumer may become liable, following the event of default, for one or more of the following credit fees or charges, which are unascertainable at the time of entering this contract.  Due the day after the activity and payable before or with the next scheduled repayment or, if applicable and at the Credit Provider&#39;s discretion, by periodic payment/s at the end of the contracted term, with the exception of the enforcement expenses.
                                                    <div className="table-responsive">
                                                        <table className="table table-sm table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th className="w-25">Other Fees and Charges</th>
                                                                    <th className="w-25 text-center align-middle">Amount $</th>
                                                                    <th className="align-middle">Description</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Direct debit default, or other non-payment fee </td>
                                                                    <td className="text-end">$35.00</td>
                                                                    <td>When any scheduled direct debit repayment is dishonoured, or other payment due on a particular day is not received (miss-payment) and no other substitute payment has been received</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Default management fee</td>
                                                                    <td className="text-end">$5.00</td>
                                                                    <td>Charged daily from the day after a default in payment occurs, until that and any later default incurred is repaired or remedied by full payment of the total amount outstanding (up to a legislated maximum amount of twice the original net loan funds).</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Direct Debit rearrangement fee</td>
                                                                    <td className="text-end">$20.00</td>
                                                                    <td>When rearrangement is requested by the Consumer, following a default</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Rescheduling fee following default</td>
                                                                    <td className="text-end">$30.00</td>
                                                                    <td>When repayment/s are rescheduled by the Credit Provider following default</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Letter fee</td>
                                                                    <td className="text-end">$15.00</td>
                                                                    <td>When written communications are provided to the consumer</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Email fee</td>
                                                                    <td className="text-end">$10.00</td>
                                                                    <td>When emailed communications are provided to the consumer</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Enforcement expenses (debt recovery) </td>
                                                                    <td className="text-end">At reasonable cost</td>
                                                                    <td>Follows an un-remedied default, payable following recovery activity, due on day invoice received</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </li>
                                                <li>3.2 The above fees, all incurred because of a default, represent a reasonable calculation of the average referrable costs to the Credit Provider.</li>
                                                <li>3.3 Enforcement expenses are reimbursement of reasonable sub-contracted third party fees and disbursements (debt collectors, lawyers, etc.), plus reasonable in-house debt administration and collection activities.</li>
                                                <li>3.4 Should all or any part of the above fees be incurred by the Consumer and remain unpaid at the end of the contract term, regardless of whether or not any loan principal is still outstanding, they will attract further permitted monthly fees, being 4% of the original net loan amount and equal to the monthly fees paid during the original loan term.</li>
                                            </ul>
                                        </li>
                                        <li className="mb-3"><strong>The Consumer&#39;s Right to Terminate or Pay Out the Contract</strong>
                                            <ul className="list-unstyled">
                                                <li>4.1	The Consumer may terminate this Contract at any time before the receipt of loan funds, without paying a discharge or termination fee.</li>
                                                <li>4.2	Following receipt of the net amount of funds, the Consumer is entitled to pay out this Small Amount Credit Contract at any time prior to the final payment date specified in this contract, without payment of a termination fee.  Please note that there is no reimbursement for any fee or charge that has become payable before the termination.  Any amount due that remains unpaid prior to termination, plus the balance of the credit amount/loan principal, must be paid before the Contract is terminated by the Consumer.</li>
                                            </ul>
                                        </li>
                                        <li className="mb-3"><strong>Statement of Pay Out Figure</strong>
                                            <ul className="list-unstyled">
                                                <li>At the Consumer&#39;s request, the Credit Provider will provide a written statement of the amount required to pay out this Small Amount Credit Contract, as at the nominated date of payout.  Such statement will be provided within fourteen (14) days of the Consumer’s request.</li>
                                            </ul>
                                        </li>
                                        <li className="mb-3"><strong>Termination of this Contract by the Credit Provider</strong>
                                            <ul className="list-unstyled">
                                                <li>6.1	The Credit Provider may terminate this contract in the following circumstances:
                                                    <ul className="list-unstyled pl-3">
                                                        <li>(a) the Consumer is in default; and/or</li>
                                                        <li>(b)	information provided by the Consumer is found to be false, inaccurate and/or forged, or is reasonably believed to be so; and/or.</li>
                                                        <li>(c)	information provided by the Consumer during the assessment period is discovered to have been materially or significantly changed by the Consumer since its provision, and has not resulted in a timely reporting of the change to the Consumer.</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="mb-3"><strong>Breach or Default and Acceleration</strong>
                                            <ul className="list-unstyled">
                                                <li>7.1 In the unfortunate event that the Consumer breaches or defaults, under this contract, in particular failing to make any payment on time and in full, as required under this contract, the Consumer agrees to pay the amounts indicated in the above unascertainable fees and charges table, as applicable.</li>
                                                <li>7.2 In the event of such payment in total not being received by the Credit Provider on, or by, the repayment date/s, and/or the Consumer failing to repair the breach or default to the satisfaction of the Credit Provider, the Credit Provider reserves the right to recover all money owed, plus all repayments of principal due over the balance of the term of the loan, by way of debt collection and/or prosecution in the relevant court or tribunal.</li>
                                                <li>7.3 This money includes the total credit amount/loan principal, all permitted ascertainable and unascertainable fees outstanding at the time of the default, all permitted ascertainable fees due during the remainder of the term until the full amount owed by the Consumer is paid, administration and recovery expenses reasonably incurred by the use of the Credit Provider’s staff and facilities, plus the cost involved in the engagement of a debt collector, lawyer and/or other relevant professional adviser, to assist in the debt recovery process.  The Credit Provider will seek recovery of these costs in full.</li>
                                                <li>7.4 In an alternative to the actions prescribed in Clause 7.2 above, if the Consumer fails to make a direct debit payment on time, the Consumer agrees that the Credit Provider may reschedule the direct debit for that payment to another day, at the Credit Provider’s discretion.</li>
                                                <li>7.5 The maximum total amount that the Credit Provider may recover under the contract, net of enforcement expenses, includes all default and default associated fees and is twice the net amount of the loan funds originally provided, being the adjusted credit amount.</li>
                                            </ul>
                                        </li>
                                        <li className="mb-3"><strong>Postponement of Exercise of Rights by Debtor</strong>
                                            <ul className="list-unstyled">
                                                <li>8.1 If the Consumer has been given a Default Notice - at any time before the end of the remedy period specified in the notice, the Consumer may contact the Credit Provider to attempt to negotiate a postponement of the Credit Provider&#39;s enforcement proceedings, or of any action taken under such proceedings.  Such postponement will be at the Credit Provider&#39;s discretion.</li>
                                                <li>8.2 If postponement is granted, the Credit Provider will give written notice of the conditions of a postponement, no later than twenty-one (21) days after agreement is reached.</li>  
                                                <li>8.3 The written notice will also set out the consequences if the Consumer does not comply with the conditions of the postponement.</li>
                                                <li>8.4 Should the Consumer not comply with the conditions of a postponement, under the National Credit Code, the Credit Provider is not required to give further notice before proceeding with enforcement action.</li>
                                            </ul>
                                        </li>
                                        <li className="mb-3"><strong>Default Notices</strong>
                                            <ul className="list-unstyled">
                                                <li>9.1 In the event of a default, the Credit Provider will send the Consumer a standard Default Notice (Section 88, National Credit Code), a Form 11A - Direct Debit Default Notice (if applicable) and a Form 12A - Information About Debtor&#39;s Rights After Default Notice (National Consumer Credit Protection Regulations), together, within fourteen (14) days after the default.</li>
                                                <li>9.2 The Default Notices will specify the default and the actions necessary to remedy or resolve it.  It will state that, if the default is not remedied by the Consumer within the thirty (30) day period applicable, or should the remedy applied to the original default subsequently fail during the period specified for remedy, the default may be the subject of enforcement proceedings by the Credit Provider without further notice.</li>
                                                <li>9.3 If the default is remedied within the specified time, the contract is deemed to be reinstated and no further action for recovery will occur for that particular default.</li>
                                            </ul>
                                        </li>
                                        <li className="mb-3"><strong>When a Default Notice is Not Required</strong>
                                            <ul className="list-unstyled">
                                                <li>10.1 The Credit Provider is not required to give a default notice, or wait until the period specified in the default notice has elapsed before beginning proceedings, if:
                                                    <ul className="list-unstyled">
                                                        <li>(a) the Credit Provider believes, on reasonable grounds, that it was induced to provide the loan by fraudulent actions on the part of the Consumer; and/or</li>
                                                        <li>(b) the Credit Provider has made reasonable attempts to locate the Consumer, but without success; and/or</li>
                                                        <li>(c) the Court authorises the Credit Provider to begin the enforcement proceedings.</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="mb-3"><strong>Notices and Other Written Communications</strong>
                                            <ul className="list-unstyled">
                                                <li>11.1 Subject to any changes in contact details during the term of this Contract, the Parties will address all notices and other written communications to the addresses on page 1 of this Contract.</li>
                                                <li>11.2 With the exception of the Section 88 (National Credit Code) Default Notice, which must be posted, these notices and other written communications may be by way of personal delivery, electronic transfer, prepaid Australia Post, or by such manner as is specifically prescribed by law for the particular notice, from time to time.</li>
                                                <li>11.3 Notices will be presumed delivered immediately when sent by email, facsimile, SMS and/or delivered personally, and within 5 days when sent by post.</li>
                                                <li>11.4 Should action requested by either party in any notice or written communication, not occur by the prescribed time, or where none has been prescribed a reasonable time, the sending party will use another means of communication to check that the recipient party actually received the (first) notice or other written communication, before presuming that notice was ignored by the recipient party.</li>
                                                <li>11.5 Notices and any other written communication issued by the Credit Provider may be signed by the company&#39;s authorised directors, managers, employees, solicitor or agent, on behalf of the company.</li>
                                            </ul>
                                        </li>
                                        <li className="mb-3"><strong>Default Cost and Extension of Loan Term</strong>
                                            <ul className="list-unstyled">
                                                <li>In the event that repayments, including principal, permitted monthly fees and unascertainable fees and charges (if any) remain outstanding, in part or whole, at the time the loan was scheduled to conclude in accordance with this contract or, during the original loan term, the parties agree to extend the loan term.  The Consumer hereby agrees that the extended loan term will incur a continuation of the permitted monthly fee liability until the full amount has been repaid, or such total amount owing has reached a gross amount permitted for collection, as prescribed in the National Consumer Credit Protection Act.</li>
                                            </ul>
                                        </li>    
                                        <li className="mb-3"><strong>Other Dishonour Fee/s</strong>
                                            <ul className="list-unstyled">
                                                <li>The Consumer may be charged dishonour fees by their financial institution, should the Consumer default on their repayment, resulting in a declined direct debit.   The Consumer should avoid defaulting on repayments to limit these fees.</li>
                                            </ul>
                                        </li>
                                        <li className="mb-3"><strong>Changed Financial Circumstances</strong>
                                            <ul className="list-unstyled">
                                                <li>14.1 In the event of the Consumer&#39;s financial circumstances adversely changing during the period of the loan, due to illness, unemployment, domestic violence or other reasonable circumstances, the Consumer is invited to contact the Credit Provider at the earliest opportunity.</li>
                                                <li>14.2 Only in such reasonable and true adverse circumstances, and subject to agreement by both the Consumer and The Credit Provider, will the terms of this contract be varied.  These changes may include extending the period of the contract and reducing the payments, and/or postponing, for a specific period, the dates on which payments are due under the contract.</li>
                                                <li>14.3 However, while hardship applications will be considered at any time, due to processing time if the hardship application is not received and agreed to forty-eight (48) hours before the next repayment is due, the implementation of an agreement to change the next direct debit payment cannot be guaranteed to happen in time.</li>
                                            </ul>
                                        </li>
                                        <li className="mb-3"><strong>Disputes</strong>
                                            <ul className="list-unstyled">
                                                <li>15.1 In the event of a dispute, the consumer should first contact the Internal Dispute Resolution Manager (IDR) on tel: (03) 9909 7099, or email: joe@avi2u.com, as soon as possible.  Disputes can usually be resolved without delay.</li>
                                                <li>15.2 If the matter is not resolved to the consumer&#39;s satisfaction, the Consumer may then contact the Credit Provider&#39;s External Dispute Resolution (EDR) scheme.</li>
                                                <li>15.3 The EDR scheme is the Australian Financial Complaints Authority (AFCA): 
                                                    <table className="table table-sm table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <td>Phone: 1800 931 678</td>
                                                                <td>Postal address: PO Box 3, Melbourne, Vic  3001</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Website: www.afca.org.au</td>
                                                                <td>Email: info@afca.org.au</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="mb-3"><strong>Statement of Accounts</strong>
                                            <ul className="list-unstyled">
                                                <li>No periodic statements are provided under this contract.  However, the Credit Provider will provide a Statement indicating the amount outstanding, within fourteen (14) days of a request by the Consumer.  The Consumer must make the request in writing.</li>
                                            </ul>
                                        </li>
                                        <li className="mb-3"><strong>Commission</strong>
                                            <ul className="list-unstyled">
                                                <li>17.1 The Credit Provider does not pay commission to anyone for the introduction of credit business, or business financed by the contract.</li>
                                                <li>17.2 However, the Credit Provider may pay a referrer fee, or provide a gift to a person referring the Consumer to the Credit Provider, when the Consumer takes out a loan.  This referr fee, or gift, will be entirely at the Credit Provider&#39;s expense.</li>
                                            </ul>
                                        </li>
                                        <li className="mb-3"><strong>Insurance Financed by Contract</strong>
                                            <ul className="list-unstyled">
                                                <li>No credit insurance premium is payable under this contract.</li>
                                            </ul>
                                        </li>
                                        <li className="mb-3"><strong>Changed Residential Address/Contact Details</strong>
                                            <ul className="list-unstyled">
                                                <li>In the event of changes to the Consumer&#39;s residential address and/or associated contact details, during the period of the loan, the Consumer agrees to contact the Credit Provider at the earliest possible opportunity to notify the Credit Provider of such change.  In the absence of such notification, the Credit Provider shall not be liable to the Consumer for any loss, embarrassment or inconvenience caused by the Consumer&#39;s failure to do so.</li>
                                            </ul>
                                        </li>
                                        <li className="mb-3"><strong>Privacy Policy</strong>
                                            <ul className="list-unstyled">
                                                <li>20.1 Details of the company&#39;s policies and procedures in relation to privacy and to electronic transactions are included in the Your Privacy and the Provision of Credit document.  This document includes the Privacy Consent Agreement and the Electronic Transactions Agreement, provided to the Consumer and Guarantor (if any), prior to the responsible lending assessment for this contract.</li>
                                                <li>20.2 In regard to privacy, the company&#39;s policies can also be found in the Credit Information (Privacy) Management Policy, which is available for viewing on the company&#39;s website at www.cashsmart.net.  A paper or electronic copy of this policy is available to the Consumer on request, at no charge.</li>
                                            </ul>
                                        </li>
                                        <li className="mb-3"><strong>Waivers</strong>
                                            <ul className="list-unstyled">
                                                <li>21.1 A right of the Credit Provider, under this credit contract, may be waived for a period or permanently, as the Credit Provider may determine at its absolute discretion.  When for a period, the right will resume at the conclusion of that specified period.  When permanently, the waiver will be confirmed in writing as an amendment to this credit contract.</li>
                                                <li>21.2 Waivers may be for the whole, or some part, of the Credit Provider’s right.</li>
                                                <li>21.3 The Credit Provider does not waive any right or Consumer obligation prescribed in this Contract, due to any delay in executing, or non-execution of such right or obligation by the Credit Provider, unless the waiver is agreed by both Parties and confirmed in writing.</li>
                                            </ul>
                                        </li>
                                        <li className="mb-3"><strong>Alteration or Amendment of any Term or Condition</strong>
                                            <ul className="list-unstyled">
                                                <li>No alteration or amendment of any term or condition of this Contract can be made without the agreement of both Parties.  This agreement must be in writing, unless a simple amendment with a duration of no longer than (ninety) 90 days.</li>
                                            </ul>
                                        </li>
                                        <li className="mb-3"><strong>Contract Continuity</strong>
                                            <ul className="list-unstyled">
                                                <li>Any provision of this credit contract that is rendered void, declared illegal, or otherwise made unenforceable, or which may give rise to a penalty at law, will only be ineffective to the extent determined by law and will otherwise be effective and binding upon the Parties to this credit contract.  All other provisions not so effected shall remain binding upon the Parties to their full effect.</li>
                                            </ul>
                                        </li>
                                        <li className="mb-3"><strong>Assignment of Contract</strong>
                                            <ul className="list-unstyled">
                                                <li>24.1 The Credit Provider may assign or novate this contract to another entity, at its absolute discretion, subject to the requirements of the National Consumer Credit Protection Act 2009.</li>
                                                <li>24.2 In preparation for and when seeking or negotiating with an assignee, the Credit Provider hereby has the permission of the Consumer to disclose information and documentation that was provided when the Parties were negotiating and undertaking the lawful “not unsuitable assessment” of the Consumer’s application, that resulted in the provision of this credit contract and during the term of the contract thereafter.</li> 
                                                <li>24.3 At all times, the party/parties to which this information is disclosed will be subject to a robust confidentiality deed.</li>
                                                <li>24.4 The Consumer may only assign this contract and associated security, if any, with the Credit Provider’s written consent.  This consent shall be at the Credit Provider’s absolute discretion.</li>
                                            </ul>
                                        </li>
                                    </ol>
                                </div>
                            
                                <div className="acceptance">
                                    <h5 className="card-title text-center">ACCEPTANCE OF SMALL AMOUNT CREDIT CONTRACT OFFER</h5>
                                    <p>By signing this Small Amount Credit Contract (Personal), I/we the undersigned (being the Consumer/s):</p>
                                    <ol>
                                        <li>attest that I am/we are over 18 years of age;</li>
                                        <li>nderstand that, until this Contract document is signed by a representative of the Credit Provider and myself/ourselves, it constitutes an offer only;</li>
                                        <li>attest that I/we have read and understood all documentation provided, including the Credit Provider&#39;s Credit Guide, the Privacy Protection Consent Agreement, the Electronic Transactions Agreement, the SPAM Act Agreement, the AML/CTF Agreement, the Form 5 Information Statement, the Pre-contractual Statement and this Contract; and</li>
                                        <li>attest that, where English is not my/our first language, I/we have arranged for translation and interpreter services, as may have been required in order to assist understanding of all documents provided; and</li>
                                        <li>have been advised by the Credit Provider&#39;s signage, documentation and/or verbally by the Credit Provider&#39;s representative, to consider obtaining independent legal or other appropriate professional advice regarding the above listed documentation and the associated financial commitment; and</li>
                                        <li>acknowledge receiving a copy of the above listed documents (point 3), to provide to professional advisers of my/our choice, for their consideration and for me/us to study and keep; and</li>
                                        <li>attest that I/we have the full power and authority to perform my/our obligations as outlined in this Contract.  There is no trustee or administrator involved, or likely to become involved, in the administration of my financial affairs; and</li>
                                        <li>attest that I/we are in receipt of a regular income, or have access to adequate financial resources that provide me/us with a reasonable belief that I am/we are capable of fulfilling my/our financial obligations under this Contract; and</li>
                                        <li>attest that I/we have been truthful and fulsome in revealing my/our personal financial circumstances; and  </li>
                                        <li>attest that I/we have revealed the existence of all credit contracts, leases, hire purchase, pawn broking  and/or other arrangements requiring regular payments over a period of time, that I/we may have entered into in any jurisdiction, and that are still current and requiring continuing and/or future payment/s; and</li>
                                        <li>attest that I/we will not allocate any part of the funds received under this Contract to repay another (prior) Small Amount Credit Contract ($2,000 or less, borrowed for one (1)year or less) either in part or full.</li>
                                        <li>have been told exactly how much the repayments under this Contract will be and when such will be due; and</li>
                                        <li>have had any question/s satisfactorily answered by the representative; and</li>
                                        <li>understand that, once I/we have signed this Contract, I/we am/are obliged to pay all amounts due and payable to the Credit Provider and there are strictly applied financial costs (the relevant unascertainable fees and charges and permitted fees) if I/we fail to meet my/our obligations; and</li>
                                        <li>understand that the Credit Provider will be entitled to take enforcement action against me/us in the event of a default, including court proceedings; and</li>
                                        <li>understand that, upon signing by the Credit Provider and myself/ourselves, a copy of this signed Contract will be provided to me/us to keep; and</li>
                                        <li>understand that, if the Contract is signed by two or more Consumers, then each Consumer is liable, individually and collectively (jointly and severally), to pay the full amount of the loan; and</li>
                                        <li>understand that this Contract specifies permitted fees, as allowed under the National Consumer Credit Code, and that other providers of credit may offer lower cost provision of funds; and</li>
                                        <li>have been advised by the representative to visit the ASIC MoneySmart website; and</li>
                                        <li>accept that the provision of funds under this Contract is not contingent on using any other Credit Provider, or Credit Provider-recommended product or service; and </li>
                                        <li>accept that the provision of funds is for a specified period and that there is no actual or implied agreement to extend or renew or refinance this Contract after the Term specified; and</li>
                                        <li>accept that, if any of the provisions in this Contract are contrary to law, or become contrary to law at any time during the loan term, the affected provisions will cease to have effect, while the remainder of the Contract terms will continue to bind both Parties; and</li>
                                        <li>attest that I/we do not currently have any unsatisfied court judgements recorded against me/us in any jurisdiction; and</li>
                                        <li>attest that there is no litigation, tax claim, proceeding or dispute pending, in any jurisdiction, the adverse determination of which might materially and adversely affect my/our ability to satisfy the obligations as listed above; and</li>
                                        <li>attest that, prior to signing this Contract, I/we were not aware of committing an act of bankruptcy, or having entered into a Part IX (9) or a Part X (10) and/or similar arrangement, in any jurisdiction, or having been a director of a company that had become and/or was declared as being insolvent, or planning to do so; and</li>
                                        <li>to the best of my/our knowledge, do not have any mental condition that would affect my/our ability to understand the obligations outlined above; and</li>
                                        <li>accept that, in the event of any dispute or hardship issue, I/we will contact the Credit Provider’s IDR Manager, before contacting the External Dispute Resolution scheme; and</li>
                                        <li>attest that this loan satisfies my/our required term of loan, required amount of loan, purpose of loan, benefits of loan and other requirements and objectives; and</li>
                                        <li>accept the Contract offer and agree to all the Terms and Conditions as set out in this Contract.</li>
                                    </ol>
                                </div>
                            
                                <div id="scrollBottom" style={{ width: '1px', height: '1px' }}></div>
                                {/* <!-- DO NOT DELETE, USED FOR AUTOMATED TESTING --> */}
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-body contract-print-scroll-sm">
                            <div className="termsconditions">
                                <div className="informationStatement mb-4">
                                    <h5 className="card-title text-center">INFORMATION STATEMENT</h5>
                                    <p className="card-text"><strong>THINGS YOU SHOULD KNOW ABOUT YOUR PROPOSED CREDIT CONTRACT</strong></p>

                                    <p>This statement tells you about some of the rights and obligations of yourself and your credit provider. It does not state the terms and conditions of your contract.</p>
                                    <p>If you have any concerns about your contract, contact the credit provider and, if you still have concerns, your credit provider’s external dispute resolution scheme, or get legal advice.</p>
                                    
                                    <p>THE CONTRACT</p>
                                    <ol>
                                        <li className="mb-3">
                                            <p className="mb-0"><strong>How can I get details of my proposed credit contract?</strong></p>
                                            <p className="mb-0">Your credit provider must give you a pre-contractual statement, containing certain information about your contract.  The pre-contractual statement, and this document, must be given to you before:</p> 
                                            <ul>
                                                <li>your contract is entered into; or</li>
                                                <li>you make an offer to enter into the contract;</li>
                                            </ul>
                                            <p className="mb-0">whichever happens first.</p>
                                        </li>
                                        <li className="mb-3">
                                            <p className="mb-0"><strong>How can I get a copy of the final contract?</strong></p>
                                            <p className="mb-0">If the contract document is to be signed by you and returned to your credit provider, you must be given a copy of to keep.  Also, the credit provider must give you a copy of the final contract within 14 days after it is made.  This rule does not, however, apply if the credit provider has previously given you a copy of the contract document to keep.</p> 
                                            <p className="mb-0">If you want another copy of your contract, write to your credit provider and ask for one.  Your credit provider may charge you a fee.  Your credit provider has to give you a copy:</p>
                                            <ul>
                                                <li>within 14 days of your written request if the original contract came into existence 1 year or less before your request; or</li>
                                                <li>otherwise within 30 days of your written request.</li>
                                            </ul>
                                        </li>
                                        <li className="mb-3">
                                            <p className="mb-0"><strong>Can I terminate the contract?</strong></p>
                                            <p className="mb-0">Yes. You can terminate the contract by writing to the credit provider so long as</p>
                                            <ul>
                                                <li>you have not obtained any credit under the contract; or</li>
                                                <li>a card or other means of obtaining credit, given to you by your credit provider, has not been used to acquire goods or services for which credit is to be provided under the contract.</li>
                                            </ul>
                                            <p className="mb-0">However, you will still have to pay any fees or charges incurred before you terminated the contract.</p>
                                        </li>
                                        <li className="mb-3">
                                            <p className="mb-0"><strong>Can I pay my credit contract out early?</strong></p>
                                            <p className="mb-0">Yes.  Pay your credit provider the amount required to pay out your credit contract on the day you wish to end your contract.</p>
                                        </li>
                                        <li className="mb-3">
                                            <p className="mb-0"><strong>How can I find out the pay out figure?</strong></p>
                                            <p className="mb-0">You can write to your credit provider at any time and ask for a statement of the payout figure as at any date you specify. You can also ask for details of how the amount is made up. Your credit provider must give you the statement within 7 days after you give your request to the credit provider. You may be charged a fee for the statement.</p>
                                        </li>
                                        <li className="mb-3">
                                            <p className="mb-0"><strong>Will I pay less interest if I pay out my contract early?</strong></p>
                                            <p className="mb-0">Yes. The interest you can be charged depends on the actual time money is owing. However, you may have to pay an early termination charge (if your contract permits your credit provider to charge one) and other fees.</p>
                                        </li>
                                        <li className="mb-3">
                                            <p className="mb-0"><strong>Can my contract be changed by my credit provider?</strong></p>
                                            <p className="mb-0">Yes, but only if your contract says so.</p>
                                        </li>
                                        <li className="mb-3">
                                            <p className="mb-0"><strong>Will I be told in advance if my credit provider is going to make a change in the contract?</strong></p>
                                            <p className="mb-0">That depends on the type of change.  For example:</p>
                                            <ul>
                                                <li>you get at least same day notice for a change to an annual percentage rate.  That notice may be a written  notice to you or a notice published in a newspaper.</li>
                                                <li>you get 20 days advance written notice for -
                                                    <ul>
                                                        <li>a change in the way in which interest is calculated; or</li> 
                                                        <li>a change in credit fees and charges; or</li> 
                                                        <li>any other changes by your credit provider;</li>
                                                    </ul>
                                                </li>
                                            </ul>
                                            <p className="mb-0">except where the change reduces what you have to pay or the change happens automatically under the contract.</p>
                                        </li>
                                        <li className="mb-3">
                                            <p className="mb-0"><strong>Is there anything I can do if I think that my contract is unjust?</strong></p>
                                            <p className="mb-0">Yes. You should first talk to your credit provider. Discuss the matter and see if you can come to some arrangement.</p>
                                            <p className="mb-0">If that is not successful, you may contact your credit provider’s external dispute resolution scheme. </p>
                                            <p className="">External dispute resolution is a free service established to provide you with an independent mechanism to resolve specific complaints.  Your credit provider’s external dispute resolution provider is the Australian Financial Complaints Authority (ACFA) and they can be contacted at Phone: 1300 565 562, Email: info@afca.org.au, Postal address: GPO Box 3, Melbourne VIC 3001, or go to: <a href="http://www.afca.org.au" target="_blank">www.afca.org.au</a>.</p>
                                            <p className="">Alternatively, you can go to Court.  You may wish to get legal advice, for example from your community legal centre or Legal Aid.</p>
                                            <p className="">You can also contact ASIC, the regulator, for information on 1300 300 630 or through ASIC’s website at <a href="http://www.asic.gov.au" target="_blank">http://www.asic.gov.au</a>.</p>
                                        </li>
                                    </ol>

                                    <p>INSURANCE</p>
                                    <ol start="10">
                                        <li className="mb-3">
                                            <p className="mb-0"><strong>Do I have to take out insurance?</strong></p>
                                            <p className="mb-0">Your credit provider can insist you take out or pay the cost of types of insurance specifically allowed by law.  These are compulsory third party personal injury insurance, mortgage indemnity insurance or insurance over property covered by any mortgage.  Otherwise, you can decide if you want to take out insurance or not. If you take out insurance, the credit provider cannot insist that you use any particular insurance company.</p>
                                        </li>
                                        <li className="mb-3">
                                            <p className="mb-0"><strong>Will I get details of my insurance cover?</strong></p>
                                            <p className="mb-0">Yes, if you have taken out insurance over mortgaged property or consumer credit insurance and the premium is financed by your credit provider.  In that case the insurer must give you a copy of the policy within 14 days after the insurer has accepted the insurance proposal.</p>
                                            <p className="mb-0">Also, if you acquire an interest in any such insurance policy which is taken out by your credit provider then, within 14 days of that happening, your credit provider must ensure you have a written notice of the particulars of that insurance.</p>
                                            <p className="mb-0">You can always ask the insurer for details of your insurance contract. If you ask in writing, your insurer must give you a statement containing all the provisions of the contract.</p>
                                        </li>
                                        <li className="mb-3">
                                            <p className="mb-0"><strong>If the insurer does not accept my proposal, will I be told?</strong></p>
                                            <p className="mb-0">Yes, if the insurance was to be financed by the credit contract.  The insurer will inform you if the proposal is rejected.</p>
                                        </li>
                                        <li className="mb-3">
                                            <p className="mb-0"><strong>In that case, what happens to the premiums?</strong></p>
                                            <p className="mb-0">Your credit provider must give you a refund or credit unless the insurance is to be arranged with another insurer.</p>
                                        </li>
                                        <li className="mb-3">
                                            <p className="mb-0"><strong>What happens if my credit contract ends before any insurance contract over mortgaged property?</strong></p>
                                            <p className="mb-0">You can end the insurance contract and get a proportionate rebate of any premium from the insurer</p>
                                        </li>
                                    </ol>

                                    <p>MORTGAGES</p>
                                    <ol start="15">
                                        <li className="mb-3">
                                            <p className="mb-0"><strong>If my contract says I have to give a mortgage, what does this mean?</strong></p>
                                            <p className="mb-0">A mortgage means that you give your credit provider certain rights over any property you mortgage.  If you default under your contract, you can lose that property and you might still owe money to the credit provider.</p>
                                        </li>
                                        <li className="mb-3">
                                            <p className="mb-0"><strong>Should I get a copy of my mortgage?</strong></p>
                                            <p className="mb-0">Yes. It can be part of your credit contract or, if it is a separate document, you will be given a copy of the mortgage within 14 days after your mortgage is entered into.</p>
                                            <p className="mb-0">However, you need not be given a copy if the credit provider has previously given you a copy of the mortgage document to keep.</p>
                                        </li>
                                        <li className="mb-3">
                                            <p className="mb-0"><strong>Is there anything that I am not allowed to do with the property I have mortgaged?</strong></p>
                                            <p className="mb-0">The law says you cannot assign or dispose of the property unless you have your credit provider’s, or the court’s, permission.  You must also look after the property.  Read the mortgage document as well.  It will usually have other terms and conditions about what you can or cannot do with the property.</p>
                                        </li>
                                        <li className="mb-3">
                                            <p className="mb-0"><strong>What can I do if I find that I cannot afford my repayments and there is a mortgage over property?</strong></p>
                                            <p className="mb-0">See the answers to questions 22 and 23.</p>
                                            <p className="mb-0">Otherwise you may:</p>
                                            <ul>
                                                <li>if the mortgaged property is goods - give the property back to your credit provider, together with a letter saying you want the credit provider to sell the property for you;</li>
                                                <li>sell the property, but only if your credit provider gives permission first;</li>
                                            </ul>
                                            <p className="mb-0">OR</p>
                                            <ul>
                                                <li>give the property to someone who may then take over the repayments, but only if your credit provider gives permission first.</li>
                                            </ul>
                                            <p className="mb-0">If your credit provider won&#39;t give permission, you can contact their external dispute resolution scheme for help.</p>
                                            <p className="mb-0">If you have a guarantor, talk to the guarantor who may be able to help you.</p>
                                            <p className="mb-0">You should understand that you may owe money to your credit provider even after the mortgaged property is sold.</p>
                                        </li>
                                        <li className="mb-3">
                                            <p className="mb-0"><strong>Can my credit provider take or sell the mortgaged property?</strong></p>
                                            <p className="mb-0">Yes, if you have not carried out all of your obligations under your contract.</p>
                                        </li>
                                        <li className="mb-3">
                                            <p className="mb-0"><strong>If my credit provider writes asking me where the mortgaged goods are, do I have to say where they are?</strong></p>
                                            <p className="mb-0">Yes. You have 7 days after receiving your credit provider&#39;s request to tell your credit provider. If you do not have the goods you must give your credit provider all the information you have so they can be traced.</p>
                                        </li>
                                        <li className="mb-3">
                                            <p className="mb-0"><strong>When can my credit provider or its agent come into a residence to take possession of mortgaged goods?</strong></p>
                                            <p className="mb-0">Your credit provider can only do so if it has the court&#39;s approval or the written consent of the occupier which is given after the occupier is informed in writing of the relevant section in the National Credit Code.</p>
                                        </li>
                                    </ol>

                                    <p>GENERAL</p>
                                    <ol start="22">
                                        <li className="mb-3">
                                            <p className="mb-0"><strong>What do I do if I cannot make a repayment?</strong></p>
                                            <p className="mb-0">Get in touch with your credit provider immediately. Discuss the matter and see if you can come to some arrangement. You can ask your credit provider to change your contract in a number of ways:</p>
                                            <ul>
                                                <li>to extend the term of your contract and reduce payments; or</li>
                                                <li>to extend the term of your contract and delay payments for a set time; or</li>
                                                <li>to delay payments for a set time.</li>
                                            </ul>
                                            <p className="mb-0">You will be expected to honour, or discharge, this changed contract.</p>
                                        </li>
                                        <li className="mb-3">
                                            <p className="mb-0"><strong>What if my credit provider and I cannot agree on a suitable arrangement?</strong></p>
                                            <p className="">If the credit provider refuses your request to change the repayments, you can ask the credit provider to review this decision if you think it is wrong.</p>
                                            <p className="mb-0">If the credit provider still refuses your request you can complain to the external dispute resolution scheme that your credit provider belongs to.  Further details about this scheme are set out below in question 25.</p>
                                        </li>
                                        <li className="mb-3">
                                            <p className="mb-0"><strong>Can my credit provider take action against me?</strong></p>
                                            <p className="mb-0">Yes, if you are in default under your contract. But the law says that you cannot be unduly harassed or threatened for repayments. If you think you are being unduly harassed or threatened, contact the credit provider’s external dispute resolution scheme or ASIC, or get legal advice.</p>
                                        </li>
                                        <li className="mb-3">
                                            <p className="mb-0"><strong>Do I have any other rights and obligations?</strong></p>
                                            <p className="mb-0">Yes. The law will give you other rights and obligations. You should also READ YOUR CONTRACT carefully.</p>
                                        </li>
                                    </ol>

                                    <p className="mb-0"><strong>IF YOU HAVE ANY COMPLAINTS ABOUT YOUR CREDIT CONTRACT, OR WANT MORE INFORMATION, CONTACT YOUR CREDIT PROVIDER.  YOU MUST ATTEMPT TO RESOLVE YOUR COMPLAINT WITH YOUR CREDIT PROVIDER BEFORE CONTACTING YOUR CREDIT PROVIDER’S EXTERNAL DISPUTE RESOLUTION SCHEME.  IF YOU HAVE A COMPLAINT WHICH REMAINS UNRESOLVED AFTER SPEAKING TO YOUR CREDIT PROVIDER, YOU CAN CONTACT YOUR CREDIT PROVIDER’S EXTERNAL DISPUTE RESOLUTION SCHEME OR GET LEGAL ADVICE.</strong></p>
                                    <p className="mb-0"><strong>EXTERNAL DISPUTE RESOLUTION IS A FREE SERVICE ESTABLISHED TO PROVIDE YOU WITH AN INDEPENDENT MECHANISM TO RESOLVE SPECIFIC COMPLAINTS. YOUR CREDIT PROVIDER’S EXTERNAL DISPUTE RESOLUTION PROVIDER IS THE AUSTRALIAN FINANCIAL COMPLAINTS AUTHORITY (ACFA), PHONE: 1300 565 562, EMAIL: INFO@AFCA.ORG.AU, OR GO TO <a href="http://www.afca.org.au" target="_blank">WWW.AFCA.ORG.AU</a>, POSTAL ADDRESS: GPO BOX 3, MELBOURNE VIC 3001.</strong></p>
                                    <p className="mb-0"><strong></strong>PLEASE KEEP THIS INFORMATION STATEMENT, YOU MAY WANT SOME INFORMATION FROM IT AT A LATER DATE.</p>
                                </div>
                            
                                <div id="scrollBottom" style={{ width: '1px', height: '1px' }}></div> 
                                {/* <!-- DO NOT DELETE, USED FOR AUTOMATED TESTING --> */}
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3">
                        <div className="card-body contract-print-scroll-sm">
                            <div className="termsconditions">
                                <div className="directDebitRequest mb-4">
                                    <h5 className="card-title text-center">DIRECT DEBIT REQUEST</h5>
                                    <p>You may contact us as follows:-</p>
                                    <ul className="list-unstyled">
                                        <li>Email: customercare@cashbridge.com.au</li>
                                        <li>Mail: Level 9 530 Little Collins St, Melbourne, VIC Australia 3000</li>
                                    </ul>
                                    <p>All communication addressed to us should include your Customer Number.</p>
                                    
                                    <div className="mb-4">
                                        <p><u><strong>PART A - Your Details</strong></u></p>
                                        <div className="row">
                                            <div className="col-4">Customer Number:</div>
                                            <div className="col-8 border">
                                                { loan.token }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">Customer Name:</div>
                                            <div className="col-8 border">{ consumer.fullName } </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">Phone Number:</div>
                                            <div className="col-8 border border">{ consumer.address.mobile }
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">Email Address:</div>
                                            <div className="col-8 border">{ consumer.email }</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">Address:</div>
                                            <div className="col-8 border">{ consumer.address.rawAddress }</div>
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        <p><u><strong>PART B - Schedule</strong></u></p>
                                        <p>Payments will be debited on the due date.</p>
                                    </div>

                                    <div className="mb-4">
                                        <p><u><strong>PART C - Payment Amounts</strong></u></p>
                                        <p>Payments amount will be debited in full.</p>
                                    </div>
                                    
                                    <div className="mb-5">
                                        <p><u>PART D - Cheque/Saving Account</u></p>
                                        <p>I/We request and authorise SUNBOW FINANCE PTY LTD (314011) to arrange, through its own financial institution, a debit to your nominated account any amount SUNBOW FINANCE PTY LTD (314011), has deemed payable by you. This debit or charge will be made through the Bulk Electronic Clearing System (BECS) from your account held at the financial institution you have nominated below and will be subject to the terms and conditions of the Direct Debit Request Service Agreement.</p>
                                        
                                        {
                                            loan.bankStatement && loan.bankStatement.accounts && loan.bankStatement.accounts.map((account) => {
                                                return (
                                                    <div className="mb-2" key={account.id}>
                                                        <div className="row">
                                                            <div className="col-4">Financial Institution:</div>
                                                            <div className="col-8 border">{ account.bankName }</div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-4">Account Name:</div>
                                                            <div className="col-8 border">{ account.accountName }</div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-4">BSB No.</div>
                                                            <div className="col-8 border">{ account.accountBSB }</div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-4">Account Number</div>
                                                            <div className="col-8 border">{ account.accountNumber }</div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        <p>I/We request and authorise Acknowledement. By signing and/or providing us with a valid instruction in respect to your Direct Debit Request, you have understood and agreed to the terms and conditions governing the debit arrangements between you and SUNBOW FINANCE PTY LTD as set out in this Request and in your Direct Debit Request Service Agreement.</p>

                                        <div className="row">
                                            <div className="col-sm-2">Signature</div>
                                            <div className="col-sm-5 border text-capitalize font-italic">{ consumer.fullName }</div>
                                            <div className="col-sm-2">Date:</div>
                                            {
                                                contract.createdAt ? 
                                                <div className="col-sm-3 border">{ moment(contract.createdAt).format('DD/MM/YYYY') }</div>
                                                :
                                                <div className="col-sm-3 border">{ moment().format('DD/MM/YYYY') }</div>
                                            }
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-2"></div>
                                            <div className="col-sm-5 border"></div>
                                            <div className="col-sm-2"></div>
                                            <div className="col-sm-3 border"></div>
                                        </div>
                                    </div>

                                    <p><strong>Customer Direct Debit Request (DDR) Service Agreement</strong></p>

                                    <p>This is your Direct Debit Service Agreement with SUNBOW FINANCE PTY LTD (314011) (the Debit User). It explains what your obligations are when undertaking a Direct Debit arrangement with us. It also details what our obligations are to you as your Direct Debit provider.</p>
                                    <p>Please keep this agreement for future reference. It forms part of the terms and conditions of your <i>Direct Debit Request</i> (DDR) and should be read in conjunction with your DDR authorisation.</p>
                                    
                                    <p className="mb-2 border"><strong>Definitions</strong></p>
                                    <p><strong>account</strong> means the account held at your financial institution from which we are authorised to arrange for funds to be debited.</p>
                                    <p><strong>agreement</strong> means this Direct Debit Request Service Agreement between you and us.</p>
                                    <p><strong>banking day</strong> means a day other than a Saturday or a Sunday or a public holiday listed throughout Australia.</p>
                                    <p><strong>debit day</strong> means the day that payment by you to us is due.</p>
                                    <p><strong>debit payment</strong> means a particular transaction where a debit is made.</p>
                                    <p><strong>Direct Debit Request</strong> means the written, verbal or online request between us and you to debit funds from your account.</p>
                                    <p><strong>us</strong> or <strong>we</strong> means SUNBOW FINANCE PTY LTD (314011), (the Debit User) you have authorised by requesting a Direct Debit Request.</p>
                                    <p><strong>you</strong> means the customer who has authorised the Direct Debit Request.</p>
                                    <p><strong>your financial institution</strong> means the financial institution at which you hold the account you have authorised us to debit.</p>
                                    
                                    <p className="mb-2 border"><strong>Debiting your account</strong></p>
                                    <p>By submitting a <i>Direct Debit Request</i>, <i>you</i> have authorised <i>us</i> to arrange for funds to be debited from <i>your account</i>. The <i>Direct Debit Request</i> and this <i>agreement</i> set out the terms of the arrangement between <i>us</i> and <i>you</i>.</p>
                                    <p><i>We</i> will only arrange for funds to be debited from <i>your account</i> as authorised in the <i>Direct Debit Request</i>.</p>
                                    <p><strong>or</strong></p>
                                    <p><i>We</i> will only arrange for funds to be debited from <i>your account</i> if <i>we</i> have sent to the email / address nominated by <i>you</i> in the <i>Direct Debit Request</i>, a billing advice which specifies the amount payable by <i>you</i> to <i>us</i> and when it is due.</p>
                                    <p>If the <i>debit day</i> falls on a day that is not a <i>banking day</i>, <i>we</i> may direct your <i>financial institution</i> to debit <i>your account</i> on the following <i>banking day</i>. If <i>you</i> are unsure about which day <i>your account</i> has or will be debited <i>you</i> should ask <i>your financial institution</i>.</p>
                                
                                    <p className="mb-2 border"><strong>Amendments by us</strong></p>
                                    <p><i>We</i> may vary any details of this <i>Agreement</i> or a <i>Direct Debit Request</i> at any time by giving <i>you</i> at least <strong>fourteen (14)</strong> days written notice sent to the preferred email or address <i>you</i> have given <i>us</i> in the <i>Direct Debit Request</i>.</p>
                                
                                    <p className="mb-2 border"><strong>How to cancel or change direct debits</strong></p>
                                    <p className="mb-1"><i>You</i> can:</p>
                                    <ul>
                                        <li>(a) cancel or suspend the <i>Direct Debit Request</i>; or</li>
                                        <li>(b) change, stop or defer an individual <i>debit payment</i></li>
                                    </ul>
                                    <p>at any time by giving at least 7 days notice.</p>
                                    <p>To do so, contact <i>us</i> via customercare@cashbridge.com.au</p>
                                    <p><i>You</i> can also contact <i>your</i> own financial institution, which must act promptly on <i>your</i> instructions.</p>
                                
                                    <p className="mb-2 border"><strong><i>Your</i> obligations</strong></p>
                                    <p>It is <i>your</i> responsibility to ensure that there are sufficient clear funds available in <i>your</i> account to allow a <i>debit payment</i> to be made in accordance with the <i>Direct Debit Request</i>.</p>
                                    <p className="mb-1">If there are insufficient clear funds in <i>your account</i> to meet a <i>debit payment</i>:</p>
                                    <ul>
                                        <li>(a) <i>you</i> may be charged a fee and/or interest by <i>your financial institution</i>;</li>
                                        <li>(b) <i>we</i> may charge <i>you</i> reasonable costs incurred by <i>us</i> on account of there being insufficient funds; and</li>
                                        <li>(c) <i>you</i> must arrange for the <i>debit payment</i> to be made by another method or arrange for sufficient clear funds to be in <i>your account</i> by an agreed time so that <i>we</i> can process the <i>debit payment</i>.</li>
                                    </ul>
                                    <p><i>You</i> should check <i>your account</i> statement to verify that the amounts debited from <i>your account</i> are correct.</p>
                                    
                                    <p className="mb-2 border"><strong>Dispute</strong></p>
                                    <p>If <i>you</i> believe that there has been an error in debiting <i>your account</i>, <i>you</i> should notify <i>us</i> directly on customercare@cashbridge.com.au/03 9909 7099 and confirm that notice in writing with <i>us</i> as soon as possible so that <i>we</i> can resolve <i>your</i> query more quickly. Alternatively <i>you</i> can contact <i>your financial institution</i> for assistance.</p>
                                    <p>If <i>we</i> conclude as a result of our investigations that <i>your account</i> has been incorrectly debited <i>we</i> will respond to <i>your</i> query by arranging for <i>your financial institution</i> to adjust <i>your account</i> (including interest and charges) accordingly. <i>We</i> will also notify <i>you</i> in writing of the amount by which <i>your account</i> has been adjusted.</p>
                                    <p>If <i>we</i> conclude as a result of our investigations that <i>your account</i> has not been incorrectly debited <i>we</i> will respond to <i>your</i> query by providing <i>you</i> with reasons and any evidence for this finding in writing.</p>

                                    <p className="mb-2 border"><strong>Accounts</strong></p>
                                    <p>You should check:</p>
                                    <ul>
                                        <li>(a) with <i>your financial institution</i> whether direct debiting is available from <i>your account</i> as direct debiting is not available on all accounts offered by financial institutions.</li>
                                        <li>(b) <i>your account</i> details which <i>you</i> have provided to <i>us</i> are correct by checking them against a recent <i>account</i> statement; and</li>
                                        <li>(c) with <i>your financial institution</i> before completing the <i>Direct Debit Request</i> if <i>you</i> have any queries about how to complete the <i>Direct Debit Request</i>.</li>
                                    </ul>

                                    <p className="mb-2 border"><strong>Confidentiality</strong></p>
                                    <p><i>We</i> will keep any information (including your account details) in <i>your Direct Debit Request</i> confidential. <i>We</i> will make reasonable efforts to keep any such information that we have about <i>you</i> secure and to ensure that any of <i>our</i> employees or agents who have access to information about <i>you</i> do not make any unauthorised use, modification, reproduction or disclosure of that information.</p>
                                    <p><i>We</i> will only disclose information that we have about <i>you</i>:</p>
                                    <ul>
                                        <li>to the extent specifically required by law; or</li>
                                        <li>for the purposes of this <i>agreement</i> (including disclosing information in connection with any query or claim).</li>
                                    </ul>
                                    <p className="mb-2 border"><strong>Contacting each other</strong></p>
                                    <p>If <i>you</i> wish to notify <i>us</i> in writing about anything relating to this <i>agreement</i>, <i>you</i> should write to: </p>
                                    <ul className="list-unstyled">
                                        <li>Email: customercare@cashbridge.com.au</li>
                                        <li>Mail: Level 9 530 Little Collins St Melbourne, VIC, Australia 3000</li>
                                    </ul>
                                    <p>All communication addressed to <i>us</i> should include <i>your</i> Customer Number.</p>
                                    <p><i>We</i> will notify <i>you</i> by sending a notice to the preferred address or email <i>you</i> have given <i>us</i> in the <i>Direct Debit Request</i>. Any notice will be deemed to have been received on the second <i>banking day</i> after sending.</p>
                                </div>
                            
                                <div id="scrollBottom" style={{ width: '1px', height: '1px' }}></div> 
                                {/* <!-- DO NOT DELETE, USED FOR AUTOMATED TESTING --> */}
                            </div>
                        </div>
                    </div>

                    <div className="form-group form-check">
                        <input type="checkbox" 
                        id="confirmAgreement"
                        className="form-check-input check-confirm" 
                        checked={contract.signedAt && 'checked'}
                        disabled={contract.signedAt && 'disabled'}
                        onClick={(e) => {
                            setConfirmAgreement(e.target.checked)
                        }}/>
                        <label className="form-check-label" htmlFor="confirmAgreement">
                            I confirm that I have read, understand and accept this <strong>
                                Loan Contract (Including the Terms and Conditions), 
                                the Information Statement and the Direct Debit Request and Direct Debit Service Agreement
                            </strong>. I understand and acknowledge that when I click on the "I Agree" buton below I will be bound under the terms of the Loan Contract and the Direct Debit Request and Direct Debit Service Agreement as though I signed those documents by hand.
                        </label>
                    </div>
                    <div className="form-group form-check">
                        <input type="checkbox" 
                        id="confirmPurpose"
                        className="form-check-input check-confirm" 
                        checked={contract.signedAt && 'checked'}
                        disabled={contract.signedAt && 'disabled'}
                        onClick={(e) => {
                            setConfirmPurpose(e.target.checked)
                        }}/>
                        <label className="form-check-label" htmlFor="confirmPurpose">
                            I confirm that this loan amount meets my requirements and objectives for the 'loan purpose(s)' I indicated in my application.
                        </label>
                    </div>
                    <div className="form-group form-check">
                        <input type="checkbox" 
                        id="confirmRepayment"
                        className="form-check-input check-confirm" 
                        checked={contract.signedAt && 'checked'}
                        disabled={contract.signedAt && 'disabled'}
                        onClick={(e) => {
                            setConfirmRepayment(e.target.checked)
                        }}/>
                        <label className="form-check-label" htmlFor="confirmRepayment">
                            I confirm there are no foreseeable reasons that will affect my ability to repay this loan.
                        </label>
                    </div>

                    <div className="div-contract-submit mb-3" style={{ display: contract?.signedAt ? '' : 'none' }}>
                        <div className="card mb-3">
                            <div className="card-body">
                                <h5 className="card-title text-center"><strong>IMPORTANT</strong></h5>
                                
                                <div className="row">
                                    <div className="col-sm-6 pl-0 mb-3 mb-sm-0">
                                        <p className="text-center"><strong>BEFORE YOU SIGN</strong></p>
                                        <ul>
                                            <li id="contractImportantInfo_li1">READ THIS CONTRACT DOCUMENT so that you know exactly what contract you are entering into and what you will have to do under the contract.</li>
                                            <li id="contractImportantInfo_li2">You should also read the information statement: "THINGS YOU SHOULD KNOW ABOUT YOUR PROPOSED CREDIT CONTRACT".</li>
                                            <li id="contractImportantInfo_li3">Fill in or cross out any blank spaces.</li>
                                            <li id="contractImportantInfo_li4">Get a copy of this contract document.</li>
                                            <li id="contractImportantInfo_li5"><b>Do not sign</b> this contract document if there is anything you do not understand.</li>
                                            <li id="contractImportantInfo_li6">All communication with Cash Bridge will be made via email: <a href="mailto:customercare@cashbridge.com.au">customercare@cashbridge.com.au</a> or <a href="https://m.me/CashBridge.com.au/" target="_blank">Facebook Message</a>. If your issue/problem is not resolved with customercare, please file a complaint via internal dispute resolution at <a href="mailto:idr@cashbridge.com.au">IDR@cashbridge.com.au</a>.</li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-6 pl-0">
                                        <p className="text-center"><strong>THINGS YOU MUST KNOW</strong></p>
                                        <ul>
                                            <li>Once you sign this contract document, you will be bound by it.  However, you may end the contract before you obtain credit, or a card or other means is used to obtain goods or services for which credit is to be provided under the contract, by telling the credit provider in writing, but you will still be liable for any fees or charges already incurred.</li>
                                            <li>If this contract document says so, the credit provider can vary the annual percentage rate (the interest rate), the repayments and the fees and charges and can add new fees and charges without your consent.</li>
                                            <li>If this contract document says so, the credit provider can charge a fee if you pay out your contract early.</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <p className="card-text text-center">
                            By clicking 'I agree' you confirm you have read, understand, accept and electronically sign (as though you signed those documents by hand) the Loan Contract (including the Terms and Conditions), the Information Statement.
                        </p>
                    </div>
                </div>
            </div>
        )
    )
}

export default ContractTemplate