import './App.css';

import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation, useMatch, useNavigate, useParams } from 'react-router-dom';
import * as functions from './helper';
import { useAuthContext } from './hooks/auth';
import { library } from '@fortawesome/fontawesome-svg-core'
import { useEffect, useState } from 'react';

import Session from './pages/session';
import Apply from './pages/apply';
import Home from './pages/home';
import Login from './pages/login';
import Contact from './pages/contact';
import Application from './pages/application';
import Member from './pages/member';
import EditMember from './pages/member/edit';
import MemberDashboard from './pages/member/dashboard';
import MemberDocuments from './pages/member/documents';
import MemberLoanConfirm from './pages/member/loanConfirm';
import DocumentContract from './pages/member/documentContract';
import ReplyFailure from './pages/member/replyFailure';
import ForgotPassword from './pages/login/forgotPassword';
import AxiosInterceptorsSetup from './hooks/axios';
import ResetPassword from './pages/login/resetPassword';
import FeeStatement from './pages/documents/feeStatement';
import { usePostHog } from 'posthog-js/react';
import posthog from 'posthog-js';

library.add(fab, fas, far)
window.helper = functions

function App() {
	const { consumer } = useAuthContext();
	const [isNavigate, setIsNavigate] = useState('');

	const ProtectedRoute = () => {
		if (!consumer) {
			return <Navigate to='/login'/>;
		}
		return <Outlet />;
	}

	const ScrollToTop = () => {
		const { pathname } = useLocation();
		const posthog = usePostHog();
		const { loanToken } = useParams();

		useEffect(() => {
		  	window.scrollTo(0, 0);
		}, [pathname]);
		return null;
	}

	const ToNavigate = () => {
		const navigate = useNavigate();
		const match = useMatch('/loans/:loanToken');
		const [loanToken, setLoanToken] = useState(null);

		useEffect(() => {
			if(isNavigate) {
				const navigationPath = isNavigate.path;
				const navigationError = isNavigate.error;
				navigate(navigationPath, {
					state: {
						error: navigationError
					}
				});
				setIsNavigate(null);
			}
		}, [isNavigate]);

		useEffect(() => {
			if(match) {
				const { loanToken } = match.params;
				if(loanToken && loanToken !== 'apply') {
					setLoanToken(loanToken);
					console.log(loanToken)
					posthog.startSessionRecording();
					console.log('Session started.');
					console.log('Session ID: ', posthog.get_session_id());
				}
			} else {
				setLoanToken(null);
				posthog.stopSessionRecording();
				console.log('Session stopped.');
				console.log('Session ID: ', posthog.get_session_id());
			}
		}, [match]);
	}

	return (
		<BrowserRouter>
			<AxiosInterceptorsSetup setIsNavigate={setIsNavigate}/>
			<ScrollToTop />
			<ToNavigate />
			<Routes>
				<Route path='' element={<Session />}>
					<Route path='' element={<Home />}></Route>
					<Route path='login' element={ consumer ? <Navigate to={'/members/dashboard'}/> : <Login />}></Route>
					<Route path='apply' element={<Apply />}></Route>
					<Route path='contact-us' element={<Contact />}></Route>
					<Route path='loans'>
						<Route path='' element={<Navigate to={'/loans/apply'}/>}></Route>
						<Route path='apply' element={<Application />}></Route>
					</Route>
					<Route element={<ProtectedRoute />}>
						<Route path='apply/index/:loanToken' element={<Application />}></Route>
						<Route path='loans/:loanToken' element={<Application />}></Route>
						<Route path='members' element={<Member />}>
							<Route path='' element={<Navigate to={'/members/dashboard'}/>}></Route>
							<Route path='dashboard' element={<MemberDashboard />}></Route>
							<Route path='documents' element={<MemberDocuments />}></Route>
							<Route path='edit' element={<EditMember />}></Route>
							<Route path='loans/:loanToken/confirm' element={<MemberLoanConfirm />}></Route>
							<Route path='contracts/:contractToken' element={<DocumentContract />}></Route>
							<Route path='schedules/:scheduleToken/reply/failure' element={<ReplyFailure />}></Route>
						</Route>
					</Route>
					<Route path='forgot-password' element={<ForgotPassword />}></Route>
					<Route path='reset-password/:code/:email' element={<ResetPassword />} />
					<Route path='docs/feeStatement' element={<FeeStatement />} />
					<Route path='member/dashboard' element={<Navigate to={'/members/dashboard'}/>}></Route>
					<Route path='member/loanConfirm/:loanToken' element={<Navigate to={'/members/loans/:loanToken/confirm'}/>}></Route>
				</Route>
				<Route path='*' element={<Navigate to='/' replace />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;

