import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useAuthContext } from '../../hooks/auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WarningModal from '../../components/WarningModal';
import axios from 'axios';
import LoanCalculator from '../../components/LoanCalculator';

const Apply = () => {
    const { consumer } = useAuthContext();
    const [loanAmount, setLoanAmount] = useState(1200);
    const [loanPeriod, setLoanPeriod] = useState(10);
    const [applicationLink, setApplicationLink] = useState('/loans/apply?type=sacc');
    const [ready, setReady] = useState(false);

    const getConsumerDetails = () => {
        axios.get('/consumers/' + consumer.token)
        .then((response) => {
            if(response.status === 200) {
                if(response.data.data.loan) {
                    if(response.data.data.loan.stage.rank < 400) {
                        setApplicationLink('/loans/' + response.data.data.loan.token);
                    }else{
                        setApplicationLink('/members/dashboard');
                    }
                }
            }
        })
        .catch((err) => {
            const error = err.response?.data?.error ? err.response?.data?.error : err.message;
            global.config.methods.errorResponse(error);
        })
    }

    

    useEffect(() => {
        if(consumer) {
            setReady(true);
            getConsumerDetails();
        }
    }, []);

    return (
        <div className="public-container container-fluid">
            <div className="container">
                <form id="start_form">
                    
                    <LoanCalculator 
                    loanAmount={loanAmount}
                    setLoanAmount={setLoanAmount}
                    loanPeriod={loanPeriod}
                    setLoanPeriod={setLoanPeriod}/>

                    <div className="row">
                        {
                            consumer ? 
                            (<div className="col-12 mb-1">
                                <div className="card">
                                    <div className="card-body text-center">
                                        <Link className='w-100 btn btn-primary btn-lg btn-block' to={applicationLink}>START NEW APPLICATION</Link>
                                    </div>
                                </div>
                            </div>) :
                            <>
                                <div className="col-sm-6 mb-1">
                                    <div className="card">
                                        <div className="card-body text-center">
                                            <h4 className="card-title">I'm an existing member</h4>
                                            <Link to={'/login'} className='w-100 btn btn-dark btn-lg btn-block'>LOGIN</Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 mb-1">
                                    <div className="card">
                                        <div className="card-body text-center">
                                            <h4 className="card-title">I'm a new member</h4>
                                            <Link className='w-100 btn btn-primary btn-lg btn-block' to={applicationLink}>START NEW APPLICATION</Link>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </form>
            </div>
            {
                ready && (
                    <WarningModal />
                )
            }
        </div>
    )
}

export default Apply