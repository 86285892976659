import Loader from '../../components/Loader';
import SaccLoan from './loanType/saccLoan';
import BusinessLoan from './loanType/bizLoan';
import MotorcycleLoan from './loanType/motorLoan';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams} from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import { useAuthContext } from '../../hooks/auth';

const ProgressBar = ({ loanAmount, loan }) => {
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        if(loan) {
            if(loan.stagesId === 1) {
                //Stage 1
                setPercentage(20);
            } else if(loan.stagesId === 15) {
                // Stage 2
                setPercentage(25);
            } else if(loan.stagesId === 2) {
                setPercentage(34);
                if(loan.bankStatement) {
                    if(loan.bankStatement.accounts) {
                        setPercentage(48);
                    }
                }
            } else if(loan.stagesId === 3) {
                setPercentage(90);
            } else if(loan.stagesId === 19) {
                setPercentage(95);
            } else if(loan.stagesId === 4) {
                setPercentage(100);
            }
        }
    }, [loan]);

    return (
        <div className="row mb-4">
            <div className="col-sm-4 mb-1 mb-sm-0 order-sm-2">
                <div className="card text-white bg-primary">
                    <div className="card-body pt-3 pb-1 text-center">
                        <div className="">Requested amount</div>
                        {
                            loan ? 
                            <h4 className="" id="requestedAmount">${loan.amount}</h4>
                            :
                            <h4 className="" id="requestedAmount">${loanAmount}</h4>
                        }
                    </div>
                </div>
            </div>
            <div className="col-sm-8 pr-3 pr-sm-0 order-sm-1">
                <div className="card">
                    <div className="card-body">
                        <div className="progress">
                            <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                            style={{ width: percentage + "%" }} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <p className="card-text text-right"><span className="progress-number">{ percentage }%</span> Completed</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Application = () => {
    const [showLoader, setShowLoader] = useState(false);
    const [loanCriteria, setLoanCriteria] = useState(null);
    const [ready, setReady] = useState(false);
    const [error, setError] = useState(null);
    const [loan, setLoan] = useState(null);
    const [loanAmount, setLoanAmount] = useState(1200);
    const [searchParams, setSearchParams] = useSearchParams();
    const [type, setType] = useState(null);
    const navigate = useNavigate();
    const { loanToken } = useParams();
    const posthog = usePostHog();
    const { consumer } = useAuthContext();

    useEffect(() => {
        setReady(true);
    }, [])

    useEffect(() => {
        const type = searchParams.get('type');
        if(!loanToken) {
            if(type === 'sacc' || 
            type === 'motorcycle' ||
            type === 'business') {
                setType(type);
            } else {
                navigate('/');
            }
        }
        
        if(loanToken) {
            posthog.identify(consumer.token, {
                email: consumer.email,
                name: consumer.firstName + ' ' + consumer.lastName
            });
            posthog.capture({
                properties: {
                    loan_token: loanToken,
                },
            });
        }
    }, [loanToken])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [loan?.stagesId]);

    return (
        <div className="container public-container py-4">
            <ProgressBar loanAmount={loanAmount} loan={loan}/>
            {
                error && (
                    <div className="alert alert-danger">{ error }</div>
                )
            }
            {
                (type === 'sacc' || loanToken) ? 
                <SaccLoan 
                ready={ready} 
                loan={loan} 
                setLoan={setLoan}
                loanAmount={loanAmount} 
                setLoanAmount={setLoanAmount}
                loanCriteria={loanCriteria}
                setLoanCriteria={setLoanCriteria}
                setError={setError}
                showLoader={showLoader}
                setShowLoader={setShowLoader}/>
                :
                type === 'motorcycle' ? 
                <MotorcycleLoan 
                ready={ready} 
                loan={loan} 
                setLoan={setLoan}
                loanAmount={loanAmount} 
                setLoanAmount={setLoanAmount}
                loanCriteria={loanCriteria}
                setLoanCriteria={setLoanCriteria}
                setError={setError}
                showLoader={showLoader}
                setShowLoader={setShowLoader}/>
                :
                type === 'business' && (
                    <BusinessLoan 
                    ready={ready} 
                    loan={loan} 
                    setLoan={setLoan}
                    loanAmount={loanAmount} 
                    setLoanAmount={setLoanAmount}
                    loanCriteria={loanCriteria}
                    setLoanCriteria={setLoanCriteria}
                    setError={setError}
                    showLoader={showLoader}
                    setShowLoader={setShowLoader}/>
                )
            }
            <Loader showLoader={showLoader} loan={loan}/>
        </div>
    )
}

export default Application