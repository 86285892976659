import axios from 'axios'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import PreStage from '../prestage';
import StageOne from '../stage1';
import StageTwo from '../stage2';
import StageThree from '../stage3';
import StageFour from '../stage4';
import StageFive from '../stage5';
import StageSix from '../stage6';
import { useAuthContext } from '../../../hooks/auth';
import { Spinner } from 'react-bootstrap';

const SaccLoan = ({ 
    ready, 
    loan, 
    setLoan, 
    loanAmount, 
    setLoanAmount, 
    loanCriteria, 
    setLoanCriteria,
    setError,
    showLoader,
    setShowLoader
}) => {
    const { consumer } = useAuthContext();
    const { loanToken } = useParams();
    const navigate = useNavigate();

    const getLoanDetails = () => {
        axios.get('/loans/' + loanToken)
        .then((response) => {
            if(response.status === 200){
                const loan = response.data.data.loan;
                setLoan(loan);
            }
        })
        .catch((err) => {
            const error = err.response?.data?.error ? err.response?.data?.error : err.message;
            setError(error);
        })
    }

    const getLoanCriteria = () => {
        axios.get('/loans/criteria')
        .then((response) => {
            if(response.status === 200){
                setLoanCriteria(response.data.data.loanCriteria);
            }
        })
        .catch((err) => {
            const error = err.response?.data?.error ? err.response?.data?.error : err.message;
            global.config.methods.errorResponse(error);
        })
    }

    useEffect(() => {
        if(loanToken && consumer) {
            getLoanDetails();
            getLoanCriteria();
        }
    }, [loanToken]);

    useEffect(() => {
        if(loan) {
            const stages = [1, 15, 2, 3, 19, 4];
            if(!stages.includes(loan?.stagesId)) {
                navigate('/members/dashboard');
            }
        }
    }, [loan]);

    return (
        <>
            {
                ready && !loanToken ? 
                    <PreStage 
                    type={'sacc'}
                    showLoader={showLoader}
                    setShowLoader={setShowLoader}
                    setLoan={setLoan}
                    loanAmount={loanAmount}
                    setLoanAmount={setLoanAmount}/> :
                ready && loan && loanCriteria && loan.stagesId === 1 ?
                    <StageOne 
                    loan={loan} 
                    setLoan={setLoan} 
                    loanCriteria={loanCriteria}
                    setLoanCriteria={setLoanCriteria}
                    showLoader={showLoader} 
                    setShowLoader={setShowLoader}
                    getLoanDetails={getLoanDetails}/> : 
                ready && loan && loanCriteria && loan.stagesId === 15 ? 
                    <StageTwo 
                    loan={loan} 
                    setLoan={setLoan} 
                    loanCriteria={loanCriteria}
                    setLoanCriteria={setLoanCriteria}
                    showLoader={showLoader} 
                    setShowLoader={setShowLoader}
                    getLoanDetails={getLoanDetails}/> : 
                ready && loan && loanCriteria && loan.stagesId === 2 ? 
                    <StageThree 
                    loan={loan} 
                    setLoan={setLoan} 
                    loanCriteria={loanCriteria}
                    setLoanCriteria={setLoanCriteria}
                    showLoader={showLoader} 
                    setShowLoader={setShowLoader}
                    getLoanDetails={getLoanDetails}/> :
                ready && loan && loanCriteria && loan.stagesId === 3 ? 
                    <StageFour
                    loan={loan} 
                    setLoan={setLoan} 
                    loanCriteria={loanCriteria}
                    setLoanCriteria={setLoanCriteria}
                    showLoader={showLoader} 
                    setShowLoader={setShowLoader}
                    getLoanDetails={getLoanDetails}/> :
                ready && loan && loanCriteria && loan.stagesId === 19 ? 
                    <StageFive loan={loan} 
                    setLoan={setLoan} 
                    loanCriteria={loanCriteria}
                    setLoanCriteria={setLoanCriteria}
                    showLoader={showLoader} 
                    setShowLoader={setShowLoader}
                    getLoanDetails={getLoanDetails}/> 
                    : 
                ready && loan && loanCriteria && loan.stagesId === 4 ? (
                    <StageSix loan={loan}/>
                )
                :
                <div className="col-12 py-4 text-center" style={{ fontSize: '8px' }}>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>   
                </div>
            }
        </>
    )
}

export default SaccLoan