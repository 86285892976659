import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import { useAuthContext } from '../../hooks/auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import $ from 'jquery';
import PayoutModal from './payoutModal';

const WantToCommunicate = () => {
    const showContent = (e) => {
        if(e.currentTarget.innerText === 'MORE') {
            e.currentTarget.innerText = 'HIDE';
        } else {
            e.currentTarget.innerText = 'MORE';
        }
        $('.communication-content').slideToggle();
    }

    return (
        <div className="card bg-light mb-3 no-print contact-idr">
            <div className="card-header">
                Want to communicate?
                <button className="btn btn-sm btn-link float-end hide-contact-idr" style={{ display: 'none' }}>
                    <FontAwesomeIcon icon={'fas fa-times'}/>
                </button>
                <button className="btn btn-sm btn-link float-end show-contact-idr text-decoration-none" onClick={showContent}>more</button>
            </div>

            <div className="card-body communication-content" style={{ display: 'none' }}>
                <p className="card-text">All communication with Cash Bridge will be made via email: 
                <a href="mailto:customercare@cashbridge.com.au"> customercare@cashbridge.com.au </a> 
                or <a href="https://m.me/CashBridge.com.au/" target="_blank" rel="noreferrer">Facebook Message</a>. 
                If your issue/problem is not resolved with customercare, please file a complaint via internal dispute resolution at 
                <a href="mailto:idr@cashbridge.com.au"> idr@cashbridge.com.au</a>.</p>
            </div>
        </div>
    )
}

const Schedule = ({ consumer, loan, schedule }) => {
    return (
        <>
            <tr key={schedule.token}>
                <td className="text-center">{ schedule.repaymentNum }</td>
                <td>{ moment(schedule.dueDate).format('dddd, Do MMMM YYYY') }</td>
                <td className="text-end">${ schedule.amount }</td>
                {
                    schedule.isPaid ? 
                    <td className="text-center text-success">
                        <FontAwesomeIcon icon={'fas fa-check'} data-toggle="tooltip" data-placement="left" title="SUCCESS"/>
                    </td> :
                    schedule.isFailed ? 
                    <td className="text-center text-danger">
                        <FontAwesomeIcon icon={'fas fa-times'} data-toggle="tooltip" data-placement="left" title="FAIL"/>
                    </td> :
                    schedule.directdebitStatus === 'approved*' ? 
                    <td className="text-center text-info">In Progress</td>
                    : 
                    <td>
                    </td>
                }
            </tr>
            {
                schedule.isFailed ? (
                    schedule.latestReply ?
                    <tr>
                        <td colSpan="4" className="text-center py-1 bg-secondary text-light">
                            You has replied. <span className="badge badge-light align-middle">{ schedule.latestReply.option.numDesc }. { schedule.getLatestReply.option.description }</span>
                            {
                                (schedule.latestReply.option.token === 'rOiC' ||
                                schedule.latestReply.option.token === '7eCy') && (
                                    <span className="badge badge-light align-middle">
                                        <Link 
                                        to={'/members/schedules/' + schedule.token + '/reply/failure'}
                                        state={{ 
                                            consumer: consumer, 
                                            loan: loan, 
                                            schedule: schedule 
                                        }}>Click for payment instruction</Link>
                                    </span>
                                )
                            }
                        </td>
                    </tr>
                    :
                    <tr>
                        <td colSpan="4" className="text-center py-1 bg-secondary text-light">
                            Please select one of the three options to prevent enforcement action within 48 hours. 
                            <Link 
                            to={'/members/schedules/' + schedule.token + '/reply/failure'} 
                             state={{ 
                                consumer: consumer, 
                                loan: loan, 
                                schedule: schedule 
                            }}
                            className="btn btn-sm btn-info">GO to Select</Link>
                        </td>
                    </tr>
                ) : null
            }
        </>
    )
}

const ContractSchedules = ({ consumerDetails, loan, contract }) => {
    return (
        <>
            <h5 className="card-title text-center">Repayment schedule</h5>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col" className="text-center">No.</th>
                        <th scope="scope">Due Date</th>
                        <th scope="scope" className="text-end">Amount</th>
                        <th scope="scope" className="text-center">Paid</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        contract && contract.schedules?.map((schedule) => {
                            return (
                                <Schedule loan={loan} consumer={consumerDetails} schedule={schedule} key={schedule.token}/>
                            )
                        })
                    }
                </tbody>
                <tfoot className="table-sm table-borderless border-top">
                    <tr style={{ height: '20px' }}><td colSpan="4"></td></tr>
                    <tr>
                        <td></td>
                        <td className="text-end">Total in Schedule</td>
                        <td className="text-end">
                            <strong className="includedFee">
                                ${ (parseFloat(contract.amount) + parseFloat(contract.fee) + (contract.interest * contract.loanMonths)).toFixed(2) }
                            </strong>
                        </td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
        </>
    )
}

const ExtraContractSchedules = ({ consumerDetails, loan, contract }) => {
    return(
        <>
            <h5 className="card-title text-center">You have got a new repayment schedule adjusted.</h5>
            <h4 className="card-title text-center">Repayment schedule</h4>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col" className="text-center">No.</th>
                        <th scope="scope">Due Date</th>
                        <th scope="scope" className="text-end">Amount</th>
                        <th scope="scope" className="text-center">Paid</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        contract && contract.schedules.map((schedule) => {
                            return (
                                <React.Fragment key={schedule.token}>
                                    <tr>
                                        <td className="text-center">{ schedule.repaymentNum }</td>
                                        <td>{ moment(schedule.dueDate).format('dddd, Do MMMM YYYY') }</td>
                                        <td className="text-end">${ schedule.amount }</td>
                                        {
                                            schedule.isPaid ? 
                                            <td className="text-center text-success">
                                                <FontAwesomeIcon icon={'fas fa-check'} data-toggle="tooltip" data-placement="left" title="SUCCESS"/>
                                            </td> :
                                            schedule.isFailed ? 
                                            <td className="text-center text-danger">
                                                <FontAwesomeIcon icon={'fas fa-times'} data-toggle="tooltip" data-placement="left" title="FAIL"/>
                                            </td> :
                                            schedule.directdebitStatus === 'approved*' ? 
                                            <td className="text-center text-info">In Progress</td>
                                            : 
                                            <td></td>
                                        }
                                    </tr>
                                    {
                                        schedule.isFailed ? (
                                            schedule.latestReply ?
                                            <tr>
                                                <td colSpan="4" className="text-center py-1 bg-secondary text-light">
                                                    You has replied. <span className="badge badge-light align-middle">{ schedule.latestReply.option.numDesc } . { schedule.getLatestReply.option.description }</span>
                                                    {
                                                        (schedule.latestReply.option.token === 'rOiC' ||
                                                        schedule.latestReply.option.token === '7eCy') && (
                                                            <span className="badge badge-light align-middle">
                                                                <Link 
                                                                to={'/members/schedules/' + schedule.token + '/reply/failure'}
                                                                state={{ 
                                                                    consumer: consumerDetails, 
                                                                    loan: loan, 
                                                                    schedule: schedule 
                                                                }}>Click for payment instruction</Link>
                                                            </span>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                            :
                                            <tr>
                                                <td colSpan="4" className="text-center py-1 bg-secondary text-light">
                                                    Please select one of the three options to prevent enforcement action within 48 hours. 
                                                    <Link 
                                                    to={'/members/schedules/' + schedule.token + '/reply/failure'}  
                                                    state={{ 
                                                        consumer: consumerDetails, 
                                                        loan: loan, 
                                                        schedule: schedule 
                                                    }} 
                                                    className="btn btn-sm btn-info">GO to Select</Link>
                                                </td>
                                            </tr>
                                        ) : null
                                    }
                                </React.Fragment>
                            )
                        })
                    }
                </tbody>
            </table>
        </>
    )
}

const HasLoan = ({ consumerDetails, setConsumerDetails, loan }) => {
    const [show, setShow] = useState(false);
    const [navTab, setNavTab] = useState('schedule');
    
    return (
        <>
            {
                loan?.status && 
                loan?.status?.rank === 801 && (
                    <LocalRecoveryScreen />
                ) 
            }
            <div className="row mb-3">
                <div className={loan.stage.rank < 1300 || 
                    loan.stage.rank > 2000 ? 'col-md-6 pr-md-0' : 'col'}>
                    <div className="card card-loan-expire">
                        <div className="card-body text-center">
                        {
                            loan.stage.rank < 1000 ?
                                <>
                                    <h4 className="card-title">Requested Amount</h4>
                                    <p className="display-4 mb-0">${ loan.amount }</p>
                                    <p className="card-text">(Excl. fees)</p>
                                </>
                            : (
                                loan.stage.rank < 1200 && loan.contract ? 
                                    <>
                                        <h4 className="card-title">Approved Amount</h4>
                                        <p className="display-4 mb-0">{ loan.contract.approvedAmount}</p>
                                        <p className="card-text">(Excl. fees)</p>
                                    </>
                                : (
                                    loan.stage.rank < 2000 && loan.contract ? 
                                        <>
                                            <h4 className="card-title">Loan Amount</h4>
                                            <p className="display-4 mb-0">${ parseInt(loan.contract.amount) }</p>
                                            <p className="card-text">(Excl. fees)</p>
                                        </>
                                    :
                                    (
                                        loan.contract ? 
                                        <>
                                            <div className="no-print">
                                                <h4 className="card-title">Loan Balance</h4>
                                                <p className="display-4 mb-0">${ loan.contract.loanBalance }</p>
                                                <hr/>
                                                <h5 className="mb-2 text-secondary">Pay out amount as of today</h5>
                                                <h5 className="mb-2 text-secondary">
                                                    {
                                                        loan.contract.payoutBalance < 0 ? 
                                                        <span>"$0.00"</span> 
                                                        : 
                                                        <span>${ loan.contract.payoutBalance }</span>
                                                    }
                                                </h5>
                                                <div>
                                                    <button className="btn btn-secondary btn-sm" onClick={() => {setShow(true)}}>
                                                        <span>I want to pay out</span>
                                                        <FontAwesomeIcon icon={'fas fa-question-circle'}/>
                                                    </button>
                                                    <PayoutModal 
                                                    consumerDetails={consumerDetails}
                                                    setConsumerDetails={setConsumerDetails} 
                                                    show={show}
                                                    setShow={setShow}/>
                                                </div>
                                            </div>
                                            <div className="row only-print">
                                                <div className="col-12 text-left">
                                                    <h4>{ consumerDetails.fullName }</h4>
                                                </div>
                                                <div className="col-12"><hr/></div>
                                                <div className="col-7 text-left">
                                                    <h5 className="">Loan Balance</h5>
                                                </div>
                                                <div className="col-5">
                                                    <h5>{ loan.contract.loanBalance }</h5>
                                                </div>
                                            </div>
                                        </>
                                        :
                                            <h4 className="card-title">You don't have a proper loan status, please contact us.</h4>
                                    )
                                )
                            )
                        }
                        {
                            (loan.stage.rank < 200 ||
                            loan.stage.rank === 200 ||
                            loan.stage.rank === 300 ||
                            loan.stage.rank === 390 ) ? 
                            <Link to={'/loans/' + loan.token} className="btn btn-info">
                                <FontAwesomeIcon icon={'far fa-edit'}/> Application
                            </Link> : 
                            (
                                loan.stage.rank === 1000 ||
                                loan.stage.rank === 1100 ?
                                <Link to={'/members/loanSetup/'} className="btn btn-info">Set up</Link>
                                :
                                (
                                    loan.stage.rank === 1200 ? 
                                    <Link to={'/members/loans/' + loan.token + '/confirm'} className="btn btn-info">Credit Contract</Link> 
                                    :
                                    null
                                )
                            )
                        }
                        </div>
                    </div>
                </div>
                {
                    (loan.stage.rank < 1300 ||
                    loan.stage.rank > 2000) &&
                    (
                        loan.stage.rank === 500 ? 
                        <div className="col-md-6 pl-md-0" style={{ paddingLeft: 0 }}>
                            <div className="card card-loan-expire">
                                <div className="card-body text-center">
                                    <h4 className="card-title text-warning">Application is</h4>
                                    <h1 className="card-text text-warning">Unsuccessful</h1>
                                </div>
                            </div>
                        </div>
                        : (
                            loan.stage.rank < 1300 ? 
                            <div className="col-md-6 pl-md-0" style={{ paddingLeft: 0 }}>
                                <div className="card card-loan-expire">
                                    <div className="card-body text-center">
                                        {
                                            loan.stage.rank < 1000 ? 
                                            <h4 className="card-title">Application Expires in</h4>
                                            :
                                            <h4 className="card-title">Contract Expires in</h4>
                                        }
                                        <p className="card-text h1">
                                            <span>
                                                {
                                                    parseInt(moment.duration(moment(loan.expiredAt).diff(moment())).asHours())
                                                }
                                            </span> 
                                            <small> hrs </small>
                                            <span>
                                                {
                                                    parseInt(moment.duration(moment(loan.expiredAt).diff(moment())).asMinutes()) % 60
                                                }
                                            </span> 
                                            <small> mins </small>
                                        </p>
                                        <h4 className="card-text">
                                            { moment(loan.expiredAt).format('DD MMM, HH:mm') }
                                        </h4>
                                    </div>
                                </div>
                            </div> 
                            :
                            <div className="col-md-6 pl-md-0">
                                <div className="card border-info card-loan-expire">
                                    <div className="card-body">
                                        <p className="card-text mb-0">
                                            <span className="">Contract No.</span>
                                            <span className="float-end">
                                                <strong className="contract-number">{ loan.contract?.contractNo }</strong>
                                            </span>
                                        </p>
                                        <p className="card-text mb-0">
                                            <span className="">Contracted amount</span>
                                            <span className="float-end">${ parseInt(loan.contract?.amount) }</span>
                                        </p>
                                        <p className="card-text mb-0">
                                            <span className="">Est. Fee</span>
                                            <span className="float-end">${ parseInt(loan.contract?.fee) }</span>
                                        </p>
                                        <p className="card-text mb-0">
                                            <span className="">Interest</span>
                                            <span className="float-end">${ parseInt(loan.contract?.interest) }/Month</span>
                                        </p>
                                        <p className="card-text mb-0">
                                            <span className="">Loan months</span>
                                            <span className="float-end">{ loan.contract?.totalLoanMonths }</span>
                                        </p>
                                        <p className="card-text mb-0">
                                            <span className="">Total amount</span>
                                            <span className="float-end">${ loan.contract?.totalAmount }</span>
                                        </p>
                                        <p className="border-bottom border-info mb-1"></p>
                                        <p className="card-text mb-0">
                                            <span className="">Repaid</span>
                                            <span className="float-end">${ loan.contract?.repaidAmount}</span>
                                        </p>
                                        {
                                            (loan.contract?.saccLimit - loan.contract?.totalAmount) < 0 && (
                                                <p className="card-text text-danger mb-0">
                                                    <span className="">Adjustment</span>
                                                    <span className="float-end">${ loan.contract?.totalAmount - loan.contract?.saccLimit }</span>
                                                </p>
                                            )
                                        }
                                        <p className="border-bottom border-info mb-1"></p>
                                        <p className="card-text mb-0">
                                            <span className="">Loan Balance</span>
                                            <span className="float-end">
                                                <strong>${ loan.contract?.loanBalance }</strong>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )
                    )
                }
                
            </div>                 
            <WantToCommunicate />
            {
                loan.stage.rank > 2000 ?
                    loan.contract && (
                        <>
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    {/* // eslint-disable-next-line */}
                                    <a className={"nav-link " + (navTab === 'schedule' ? 'active' : '')} 
                                    id="schedule-tab" 
                                    data-toggle="tab" 
                                    role="tab" 
                                    aria-controls="schedule" 
                                    aria-selected="true"
                                    onClick={() => {setNavTab('schedule')}}>
                                        Schedule
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className={"nav-link " + (navTab === 'statement' ? 'active' : '')}
                                    id="statement-tab" 
                                    data-toggle="tab" 
                                    role="tab" 
                                    aria-controls="statement" 
                                    aria-selected="true"
                                    onClick={() => {setNavTab('statement')}}>
                                        Statement
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content mb-3" id="dashboardTabContent">
                                <div className={"tab-pane fade " + (navTab === 'schedule' ? 'show active' : '')} id="schedule" role="tabpanel" aria-labelledby="schedule-tab">
                                    <div className="card border-top-0" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                                        <div className="card-body table-responsive">
                                        {
                                            loan.contract.latestExtraContract && 
                                            loan.contract.latestExtraContract.sentAt ? 
                                                loan.contract.extraContracts.map((contract) => {
                                                    return (
                                                        <ExtraContractSchedules 
                                                        consumerDetails={consumerDetails} 
                                                        loan={loan} 
                                                        contract={contract} 
                                                        key={contract.id}/>
                                                    )
                                                })
                                             : 
                                                <ContractSchedules 
                                                consumerDetails={consumerDetails} 
                                                loan={loan}
                                                contract={loan.contract}/>
                                        }
                                        </div>
                                    </div>
                                </div>
                                <div className={"tab-pane fade " + (navTab === 'statement' ? 'show active' : '')} id="statement" role="tabpanel" aria-labelledby="statement-tab">
                                    <div className="card border-top-0" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                                        <div className="card-body table-responsive">
                                            <div className="text-end mb-3 no-print">
                                                <button className="btn btn-primary" onClick={() => {window.print()}}>
                                                    <FontAwesomeIcon icon={'fas fa-print'}/>
                                                </button>
                                            </div>
                                            <h5 className="card-title text-center">Scheduled Transaction</h5>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">No.</th>
                                                        <th scope="scope">Due Date</th>
                                                        <th scope="scope" className="text-end">Amount</th>
                                                        <th scope="scope" className="text-center">Paid</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        loan.contract?.schedules && 
                                                        loan.contract?.schedules
                                                        .filter(x => x.isPaid === 1 || x.isFailed === 1)
                                                        .map((schedule, index) => {
                                                            return (
                                                                <tr key={schedule.token}>
                                                                    <td>{ index + 1 }</td>
                                                                    <td>{ moment(schedule.dueDate).format('dddd, Do MMMM YYYY') }</td>
                                                                    {
                                                                        schedule.isPaid ?
                                                                        <>
                                                                            <td className="text-end">${ schedule.amount }</td>
                                                                            <td className="text-center text-success">
                                                                                <FontAwesomeIcon icon={'fas fa-check'} data-toggle="tooltip" data-placement="left" title="SUCCESS"/>
                                                                            </td>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <td className="text-end text-secondary">
                                                                                <s>${ schedule.amount }</s>
                                                                            </td>
                                                                            <td className="text-center text-danger">
                                                                                <FontAwesomeIcon icon={'fas fa-times'} data-toggle="tooltip" data-placement="left" title="FAIL"/>
                                                                            </td>
                                                                        </>
                                                                    }
                                                            </tr>
                                                            )
                                                        })
                                                    }
                                                    {
                                                        loan.contract.contracts && 
                                                        loan.contract.contracts.length > 0 &&
                                                        loan.contract.contracts.map((contract) => {
                                                            return (
                                                                contract.schedules && 
                                                                contract.schedules
                                                                .filter(x => x.isPaid === 1 || x.isFailed === 1)
                                                                .map((extraContractSchedule, index) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{ index + 1 }</td>
                                                                            <td>
                                                                                { moment(extraContractSchedule.dueDate).format('dddd, Do MMMM YYYY') }
                                                                                {
                                                                                    index === 0 && (
                                                                                        <span className="text-muted">New Schedule on { moment(extraContractSchedule.createdAt).format('DD/MM/YYYY')}</span>
                                                                                    )
                                                                                }
                                                                            </td>
                                                                            {
                                                                                extraContractSchedule.isPaid ? 
                                                                                <>
                                                                                    <td className="text-end">${ extraContractSchedule.amount }</td>
                                                                                    <td className="text-center text-success">
                                                                                        <FontAwesomeIcon icon={'fas fa-check'} data-toggle="tooltip" data-placement="left" title="SUCCESS"/>
                                                                                    </td>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <td className="text-end text-secondary">
                                                                                        <s>${ extraContractSchedule.amount }</s>
                                                                                    </td>
                                                                                    <td className="text-center text-danger">
                                                                                        <FontAwesomeIcon icon={'fas fa-times'} data-toggle="tooltip" data-placement="left" title="FAIL"/>
                                                                                    </td>
                                                                                </>
                                                                            }
                                                                        </tr>
                                                                    )
                                                                })
                                                            )
                                                        })
                                                    }
                                                    {
                                                        loan.contract.countScheduledTransactions === 0 && (
                                                            <tr>
                                                                <td colSpan="4" className="text-center">No transaction</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                            {
                                                loan.contract.countCreditRepaymentManuals > 0 && (
                                                    <>
                                                        <h5 className="card-title text-center mt-5">Manual Transaction</h5>
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="scope" className="" style={{ width: '50%' }}>Transaction Date</th>
                                                                    <th scope="scope" className="text-center">Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    loan.contract.creditManualRepayments.map((repayment) => {
                                                                        return (
                                                                            <tr className="" id={ repayment.token }>                      
                                                                                <td className="">{ moment(repayment.paidAt).format('dddd, Do MM YYYY') }</td>
                                                                                <td className="text-center">${ repayment.amount }</td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body table-responsive">  
                                {
                                    loan.contract ? 
                                        <>
                                            <h5 className="card-title text-center">Unascertainable Fees and Charges</h5>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="wm-50" style={{ minWidth: '130px' }}>Type</th>
                                                        <th scope="scope" className="text-center" style={{ minWidth: '90px' }}>Amount</th>
                                                        <th scope="scope" className="text-center">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        loan.contract.otherfees && 
                                                        loan.contract.otherfees.length > 0 ? 
                                                        loan.contract.otherfees.map((otherfee) => {
                                                                return (
                                                                    otherfee.isRefunded !== null && 
                                                                    <tr id={otherfee.token} key={otherfee.id}>
                                                                        <td className="type">
                                                                            {otherfee.type.name}
                                                                            {
                                                                                otherfee.reason && (
                                                                                    <>
                                                                                        <br />
                                                                                        <span className="text-info">Reason:</span>{ otherfee.reason }
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </td>
                                                                        <td className="amount text-center">
                                                                            {
                                                                                otherfee.discountedAmount !== null ?
                                                                                <>
                                                                                    <del>${ otherfee.amount }</del>
                                                                                    <br/>
                                                                                    <span className="text-info">${ otherfee.discountedAmount }</span>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {
                                                                                        otherfee.type.token === '43em' && 
                                                                                        otherfee.schedule && 
                                                                                        otherfee.amount < otherfee.type.amount 
                                                                                        ? 
                                                                                        <>
                                                                                            <del>${ otherfee.type.amount }</del>
                                                                                            <br/>
                                                                                            <span className="text-info">
                                                                                                ${ otherfee.amount }
                                                                                                <FontAwesomeIcon icon={'fas fa-exclamation-circle'} data-toggle="tooltip" data-placement="bottom" title="Discounted amount in 24 hours"/>
                                                                                            </span>
                                                                                        </>
                                                                                        :
                                                                                        <span>${ otherfee.amount }</span>
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </td>
                                                                        {
                                                                            !otherfee.isRefunded ? 
                                                                                <td className="status text-center">
                                                                                    {
                                                                                        otherfee.isPaid ? 
                                                                                        <span className="text-success">PAID</span>
                                                                                        :
                                                                                        otherfee.latestPayment ? 
                                                                                            otherfee.latestPayment.status === 'FAILED' ||
                                                                                            otherfee.latestPayment.status === 'DECLINED' ? 
                                                                                            <span className="text-danger text-capitalize">{ otherfee.latestPayment.status }</span>
                                                                                            :
                                                                                            otherfee.latestPayment.status === 'late_failure_settled' ?
                                                                                            <span className="text-danger">Failed</span>
                                                                                            :
                                                                                            otherfee.latestPayment.status === 'approved*' ?
                                                                                            <span className="text-info">In Progress</span>
                                                                                            :
                                                                                            <span className="text-info text-capitalize">{ otherfee.latestPayment.status }</span>
                                                                                        : null
                                                                                    }
                                                                                </td>
                                                                            : null
                                                                        }
                                                                    </tr>
                                                                )
                                                            })
                                                        :
                                                        <tr className="bg-light no-otherfee">
                                                            <td colSpan="3" className="text-center">No other fee</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </>
                                    : null
                                }
                                    
                                </div>
                            </div>
                        </>
                        
                    )
                :
                <>
                    <div className="loan-progress mb-3">
                        <h5 className="">Loan Progress</h5> 
                        <div className="card">
                            {
                                loan.stage.rank < 200 ? 
                                <div className="card-body pb-0 bg-secondary text-white">
                                    <p className="float-start mr-3">
                                        <FontAwesomeIcon icon={'far fa-list-alt'} className="fa-2x"/>
                                    </p>
                                    <p className="card-title h5 mt-1">Apply</p>
                                </div> 
                                : 
                                <div className="card-body pb-0">
                                    <p className="float-start mr-3">
                                        <FontAwesomeIcon icon={'far fa-list-alt'} className="fa-2x"/>
                                    </p>
                                    {
                                        loan.stage.rank > 110 && (
                                            <p className="float-end text-primary">
                                                <FontAwesomeIcon icon={'fas fa-check'} className="fa-2x"/>
                                            </p>
                                        )
                                    }
                                    <p className="card-title h5 mt-1">Apply</p>
                                </div>
                            }
                            {
                                (loan.stage.rank === 100 || loan.stage.rank === 110) && (
                                    <div className="card-body">
                                        <p className="card-text">Fill in the form below to begin your application.</p>
                                        <Link to={'/loans/' + loan.token} className="btn btn-info float-end">
                                            <FontAwesomeIcon icon={'far fa-edit'}/>
                                            <span>Application</span>
                                        </Link>
                                    </div>
                                )
                            }
                        </div>
                        <div className="card">
                            {
                                loan.stage.rank === 200 ?
                                <div className="card-body pb-0 bg-secondary text-white">
                                    <p className="float-start mr-3">
                                        <FontAwesomeIcon icon={'fas fa-check-double'} className="fa-2x"/>
                                    </p>
                                    <p className="card-title h5 mt-1">Verify</p>
                                </div>
                                :
                                <div className="card-body pb-0">
                                    <p className="float-start mr-3">
                                        <FontAwesomeIcon icon={'fas fa-check-double'} className="fa-2x"/>
                                    </p>
                                    {
                                        loan.stage.rank >= 200 && (
                                            <p className="float-end text-primary">
                                                <FontAwesomeIcon icon={'fas fa-check'} className="fa-2x"/>
                                            </p>
                                        )
                                    }
                                    <p className="card-title h5 mt-1">Verify</p>
                                </div>
                            }
                            {
                                loan.stage.rank === 200 && (
                                    <div className="card-body">
                                        <p className="card-text">You&#39;re almost there! Let&#39;s quickly verify your income and expenses.</p>
                                        <Link to={'/loans/' + loan.token} className="btn btn-info float-end">
                                            <FontAwesomeIcon icon={'far fa-edit'}/>
                                            <span>Application</span>
                                        </Link>
                                    </div>
                                )
                            }
                        </div>
                        <div className="card">
                            {
                                loan.stage.rank === 300 ?
                                <div className="card-body pb-0 bg-secondary text-white">
                                    <p className="float-start mr-3">
                                        <FontAwesomeIcon icon={'fas fa-upload'} className="fa-2x"/>
                                    </p>
                                    <p className="card-title h5 mt-1">Upload</p>
                                </div>
                                :
                                <div className="card-body pb-0">
                                    <p className="float-start mr-3">
                                        <FontAwesomeIcon icon={'fas fa-upload'} className="fa-2x"/>
                                    </p>
                                    {
                                        loan.stage.rank >= 300 && ( 
                                            <p className="float-end text-primary">
                                                <FontAwesomeIcon icon={'fas fa-check'} className="fa-2x"/>

                                            </p>
                                        )
                                    }
                                    <p className="card-title h5 mt-1">Upload</p>
                                </div>
                            }
                            {
                                loan.stage.rank === 300 && (
                                    <div className="card-body">
                                        <p className="card-text">Upload your photo IDs and payslips (if applicable) before we submit.</p>
                                        <Link to={'/loans/' + loan.token} className="btn btn-info float-start">
                                            <FontAwesomeIcon icon={'far fa-edit'}/>
                                            <span>Application</span>
                                        </Link>
                                    </div>
                                )
                            }
                        </div>
                        {
                            loan.stage.rank === 390 && (
                                <div className="card">
                                    <div className="card-body pb-0 bg-secondary text-white">
                                        <p className="float-start mr-3">
                                            <FontAwesomeIcon icon={'fas fa-quote-left'} className="fa-2x"/>
                                        </p>
                                        <p className="card-title h5 mt-1">Further Information Required</p>
                                    </div>
                                    <div className="card-body">
                                        <p className="card-text">Please further elaborate your purpose of loan.</p>
                                        <Link to={'/apply'} className="btn btn-info float-end">
                                            <FontAwesomeIcon icon={'far fa-edit'}/>
                                            <span>Application</span>
                                        </Link>
                                    </div>
                                </div>
                            ) 
                        }
                        {
                            <div className="card">
                                {
                                    loan.stage.rank >= 400 && loan.stage.rank < 1000 ? 
                                    <div className="card-body pb-0 bg-secondary text-white">
                                        <p className="float-start ml-1 mr-3">
                                            <FontAwesomeIcon icon={'fas fa-clipboard-check'} className="fa-2x"/>
                                        </p>
                                        <p className="card-title h5 mt-1">Submit</p>
                                    </div>
                                    :
                                    <div className="card-body pb-0">
                                        <p className="float-start ml-1 mr-3">
                                            <FontAwesomeIcon icon={'fas fa-clipboard-check'} className="fa-2x"/>
                                        </p>
                                        {
                                            loan.stage.rank >= 1000 && (
                                                <p className="float-end text-primary">
                                                    <FontAwesomeIcon icon={'fas fa-check'} className="fa-2x"/>
                                                </p>
                                            )
                                        }
                                        <p className="card-title h5 mt-1">Submit</p>
                                    </div>
                                }
                                {
                                    loan.stage.rank >= 400 && loan.stage.rank < 1000 && (
                                        loan.stage.rank === 500 ?
                                        <div className="card-body">
                                            <p className="card-title text-warning">Application Unsuccessful</p>
                                            <p className="card-text">Please re-apply in a month&#39;s time when your circumstances change.</p>
                                            <p className="card-text">If you believe there has been a mistake, please contact us via email.</p>
                                        </div>
                                        :
                                        <div className="card-body">
                                            <p className="card-title">Application Submitted.</p>
                                            <p className="card-text">Our team is working on your application. A Cash Bridge Customer Service Officer will be in contact via email and SMS once your application is processed.</p>
                                        </div>
                                    )
                                }
                            </div>
                        }
                        <div className="card">
                            {
                                loan.stage.rank === 1200 ?
                                <div className="card-body pb-0 bg-secondary text-white">
                                    <p className="float-start mr-3">
                                        <FontAwesomeIcon icon={'fas fa-file-signature'} className="fa-2x"/>
                                    </p>
                                    <p className="card-title h5 mt-1">Confirmation Notice</p>
                                </div>
                                :
                                <div className="card-body pb-0">
                                    <p className="float-start mr-3">
                                        <FontAwesomeIcon icon={'fas fa-file-signature'} className="fa-2x"/>
                                    </p>
                                    <p className="card-title h5 mt-1">Confirmation Notice</p>
                                </div>
                            }
                            {
                                loan.stage.rank === 1200 && (
                                <div className="card-body">
                                    <p className="card-title">Congratulations! Your application has been processed.</p>
                                    <p className="card-text">Your requested amount of (approvedAmount) has been approved. Please note the loan amount has been determined by the Cash Bridge Loan Assessment Team in accordance with the information you provided.</p>
                                    <p className="card-text">Please review and accept your credit contract by pressing the button below. You will receive your money into your bank account in no time.</p>
                                    <Link to={'/members/loans/' + loan.token + '/confirm'} className="btn btn-info float-end">Credit Contract</Link>
                                </div>
                                )
                            }
                        </div>
                        <div className="card">
                            {
                                loan.stage.rank >= 1300 ? 
                                <div className="card-body pb-0 bg-secondary text-white">
                                    <p className="float-start mr-3">
                                        <FontAwesomeIcon icon={'fas fa-money-bill'} className="fa-2x"/>
                                    </p>
                                    <p className="card-title h5 mt-1">Get money</p>
                                </div>
                                :
                                <div className="card-body pb-0">
                                    <p className="float-start mr-3">
                                        <FontAwesomeIcon icon={'fas fa-money-bill'} className="fa-2x"/>
                                    </p>
                                    {
                                        loan.stage.rank >= 1300 && (
                                            <p className="float-end text-primary">
                                                <FontAwesomeIcon icon={'fas fa-check'} className="fa-2x"/>
                                            </p>
                                        )
                                    }
                                    <p className="card-title h5 mt-1">Get money</p>
                                </div>
                            }
                        </div>
                    </div>
                </>
            }
        </>
    )
}

const NoLoan = ({ consumerDetails, loan }) => {
    return ( 
        <>
            {
                // Check if the previous loan is within 14 days
                loan?.warning ?
                <div className="card mb-4">
                    <div className="card-body text-center">
                        <h5 className="card-title">
                            Members can only re-apply 14 days after the commencement date of their previous contract.
                            <br/>
                            You can re-apply in
                        </h5>
                        <div className="row d-flex justify-content-center py-4" id="countdown_timer">
                            <div className="text-center">
                                <div className="card m-1 d-flex justify-content-center align-items-center">
                                    <span className="card-body timer_font" id="countdown_days" style={{ fontSize: '24px' }}>days</span>
                                </div>
                                <span>Days</span>
                            </div>
                            <div className="text-center">
                                <div className="card m-1 d-flex justify-content-center align-items-center">
                                <span className="card-body timer_font" id="countdown_hours" style={{ fontSize: '24px' }}>hours</span>
                            </div>
                            <span>Hours</span>
                            </div>
                            <div className="text-center">
                                <div className="card m-1 d-flex justify-content-center align-items-center">
                                    <span className="card-body timer_font" id="countdown_minutes" style={{ fontSize: '24px' }}>minutes</span>
                                </div>
                                <span>Minutes</span>
                            </div>
                            <div className="text-center">
                                <div className="card m-1 d-flex justify-content-center align-items-center">
                                    <span className="card-body timer_font" id="countdown_seconds" style={{ fontSize: '24px' }}>seconds</span>
                                </div>
                                <span>Seconds</span>
                            </div>
                        </div>
                    </div>
                </div>
                 :
                 <div className="card mb-4">
                    <div className="card-body text-center">
                        <h4 className="card-title">You can request up to</h4>
                        <p className="display-4">$2,000</p>
                        <Link to={'/apply'} className="btn btn-primary">Request Money</Link>
                    </div>
                </div>
            }
        </>
    )
}

const LocalRecoveryScreen = () => {
    return (
        <div className="position-absolute w-100 h-100 local-recovery-screen">
            <div className="row text-white" style={{ marginTop: '230px' }}>
                <div className="col-sm-3"></div>
                <div className="col-sm-6 text-center">
                    <h3 className="text-primary">You have defaulted.</h3>
                    <h5>Credit contract is now with Local Recoveries.</h5>
                    <h5>Please contact local recoveries.</h5>
                    <p>
                        Phone: <span className="text-primary">1300 001 182</span><br/>
                        Email: <a href="mailto:collections@localrecoveries.com.au">collections@localrecoveries.com.au</a>
                    </p>
                    <h5>Thank you.</h5>
                </div>
                <div className="col-sm-3"></div>
            </div> 
        </div>
    )
}

const Timer = ({ countdown }) => {
    const [ready, setReady] = useState(false);
    const [timer, setTimer] = useState({});
    
    useEffect(() => {
        const allowedDate = moment(countdown).add(14, 'days');
        const duration = moment.duration(allowedDate.diff(moment()));
        setTimer({
            duration: duration
        });
        setReady(true);
    }, []);

    useEffect(() => {
        if(ready) {
            setInterval(() => {
                timer.duration = timer.duration.subtract(1, 'seconds');
                setTimer({...timer});
            }, 1000)
        }
    }, [ready]);

    return (
        timer && 
        <div className="card mb-4">
            <div className="card-body text-center">
                <h5 className="card-title">Members can only re-apply 14 days after the commencement date of their previous contract.<br/>You can re-apply in</h5>
                <div className="d-flex justify-content-center py-4" id="countdown_timer">
                    <div className="text-center">
                        <div className="card m-1 d-flex justify-content-center align-items-center">
                            <span className="card-body timer_font" id="countdown_days">{ timer?.duration && Math.floor(timer?.duration?.asDays()) }</span>
                        </div>
                        <span>Days</span>
                    </div>
                    <div className="text-center">
                        <div className="card m-1 d-flex justify-content-center align-items-center">
                            <span className="card-body timer_font" id="countdown_hours">{ timer?.duration?.hours() }</span>
                        </div>
                        <span>Hours</span>
                    </div>
                    <div className="text-center">
                        <div className="card m-1 d-flex justify-content-center align-items-center">
                            <span className="card-body timer_font" id="countdown_minutes">{ timer?.duration?.minutes() }</span>
                        </div>
                        <span>Minutes</span>
                    </div>
                    <div className="text-center">
                        <div className="card m-1 d-flex justify-content-center align-items-center">
                            <span className="card-body timer_font" id="countdown_seconds">{ timer?.duration?.seconds() }</span>
                        </div>
                        <span>Seconds</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const MemberDashboard = () => { 
    const { consumer } = useAuthContext();
    const [ready, setReady] = useState(false);
    const [
        consumerDetails, 
        setConsumerDetails, 
        loan, 
        setLoan,
        countdown, 
        setCountDown
    ] = useOutletContext();

    useEffect(() => {
        setReady(true);
    }, []);

    return (
        <div className="member-container">
            <div className="contract position-relative"> 
                {
                    ready && (
                        countdown ? 
                            <Timer countdown={countdown}/>
                        :
                            (consumerDetails && loan ?
                            <HasLoan 
                            consumerDetails={consumerDetails} 
                            setConsumerDetails={setConsumerDetails} 
                            loan={loan}/>
                            :
                            <NoLoan consumerDetails={consumerDetails} loan={loan}/>)
                    )
                }
            </div>
        </div>
    )
}

export default MemberDashboard