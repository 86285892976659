import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';

const MembersTabNav = () => {
    const menu = {
        'dashboard': '/members/dashboard',
        'documents': '/members/documents',
        'edit profile': '/members/edit'
    };
    const location = useLocation();
    const [tab, setTab] = useState(location.pathname);

    return (
        <div className="col-md-3 py-4 d-none d-md-block bg-oldlace" style={{ minHeight: '600px', maxWidth: '250px' }}>
            <ul className="nav nav-pills flex-column">
                {
                    Object.keys(menu).map((key) => {
                        return (
                            <li className="nav-item-left-side-bar my-2" key={key}>
                                <Link className={"w-100 nav-link " + (tab === menu[key] ? 'active' : '')} 
                                to={menu[key]} 
                                onClick={() => {
                                    setTab(menu[key])
                                }}>
                                    {global.config.methods.capitalizeFirst(key)}
                                </Link>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default MembersTabNav