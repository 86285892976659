import moment from 'moment';
import { createContext, useEffect, useReducer, useState } from 'react';

export const AuthContext = createContext({});

export const authReducer = (state, action) => {
    switch (action.type){
        case 'LOGIN': 
            return { consumer: action.payload };
        case 'LOGOUT':
            localStorage.clear()
            return { consumer: null }
        default: 
            return state;
    }
}

export const AuthContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(authReducer, {
        consumer: JSON.parse(localStorage.getItem('consumer'))
    })
    const [ready, setReady] = useState(false);

    useEffect(() => {
        const now = moment();
        if(state.consumer) {
            var expiredAt = (moment(state.consumer.accessToken.expiredAt).diff(now));
            if(expiredAt <= 0) {
                dispatch({type: 'LOGOUT'});
            }
        }
        setReady(true);
    }, [state.consumer])
    
    return (
        <AuthContext.Provider value={{ ...state, dispatch, ready }}>
            {children}
        </AuthContext.Provider>
    )
}