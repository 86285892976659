import moment from 'moment';
import React from 'react'

const ExtraContractTemplate = ({ 
    consumer,
    loan, 
    contract,
}) => {

    return (
        consumer && loan && contract && (
            <div className="card mb-3 bg-light loan-contract-document" id={loan.token}>
                <div className="card-body">
                    <h5 className="card-title text-center">Loan Contract</h5>
                    <p className="card-text text-center">You need to scroll to you on the terms set out in this loan contract. By electronically signing this document, you indicate your acceptance of our offer.</p>

                    <div className="card mb-3">
                        <div className="card-body">
                            <div id="contractPage" className="contractPage mb-4">
                                <p className="card-text"><strong>Repayment Schedule</strong> - payments due on the following dates</p>
                                <div className="table-responsive">
                                    <table className="table table-sm table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">Date</th>
                                                <th scope="scope">Repayment No.</th>
                                                <th scope="scope" className="text-center">Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                contract.schedules && contract.schedules.map((schedule) => {
                                                    return (
                                                        <tr key={schedule.id}>
                                                            <th>{ moment(schedule.dueDate).format('DD/MM/YYYY') }</th>
                                                            <td>{ schedule.repaymentNum }</td>
                                                            <td className="text-end">${ schedule.amount }</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="contract-declarations">
                                    <p className="card-text">
                                        <strong>IF YOU CANNOT MAKE A PAYMENT, CONTACT CASH BRIDGE IMMEDIATELY TO DISCUSS YOUR SITUATION BY PHONE ON 03 9909 7099 OR AT <a href="http://localhost:3001/contact-us" rel="noopener noreferrer" target="_blank">http://localhost:3001/contact-us</a>
                                        </strong>.
                                    </p>
                                    <p className="card-text"><strong>Declarations</strong></p>
                                    <p className="card-text">By electronically signing this loan contract:</p>
                                    <ol>
                                        <li>You declare that you have carefully read this loan contract and that you understand that it establishes a legal contract between you and us&#x3b;</li>
                                        <li>You warrant that all information you have given directly or indirectly to us and our agents is accurate and not misleading&#x3b;</li>
                                        <li>You acknowledge that we are relying on the information you have given to enter this transaction&#x3b;</li>
                                        <li>You acknowledge that you can afford to make the repayments without undue hardship&#x3b;</li>
                                        <li>Other than this loan contract, you have not relied on any promise or representation by anybody when deciding to enter this loan contract.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    )
}

export default ExtraContractTemplate