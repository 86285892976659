import React, { useState } from 'react'
import { Next } from './next'
import axios from 'axios'
import $ from 'jquery'
import ReactGA from 'react-ga4';

export const StageFive = ({
    loan, 
    setLoan, 
    loanCriteria, 
    setLoanCriteria, 
    showLoader, 
    setShowLoader, 
    getLoanDetails 
}) => {
    const [elaboratedPurpose, setElaboratedPurpose] = useState(null);
    const [validationError, setValidationError] = useState(false);
    const [error, setError] = useState(null);

    const validateField = (e) => {
        if(!e.target.value) {
            setValidationError(true);
        }else{
            setValidationError(false);
        }
        setElaboratedPurpose(e.target.value);
    }

    const submit = (e) => {
        setShowLoader(true);
        if(!elaboratedPurpose) {
            setValidationError(true);
        }else{
            setValidationError(false);
        }

        if(validationError){
            setShowLoader(false);
            return false;
        }

        const criteriaToken = $('.criteriaForm').attr('data-token');
        var formdata = new FormData();
        formdata.append('criteriaToken', criteriaToken);
        formdata.append('elaboratedPurpose', elaboratedPurpose);

        axios.post('/loans/' + loan.token + '/manage', formdata)
        .then((response) => {
            setShowLoader(false);
            if(response.status === 200) {
                getLoanDetails();
                ReactGA.event('purchase', {
                    transaction_id: loan.token,
                    affiliation: global.config.properties.baseURL,
                    value: parseFloat(loan.amount),
                    currency: 'AUD',
                    items: [{
                        id: loan.token,
                        sku: loan.token,
                        name: loan.amount,
                        category: 'SACC Loan',
                        quantity: 1,
                        price: loan.amount
                    }]
                })
            }
        })
        .catch((err) => {
            setShowLoader(false);
            if(err.response?.data?.validationError) {
                setValidationError(err.response?.data?.validationError);
            }else{
                const error = err.response?.data?.error ? err.response?.data?.error : err.message;
                setError(error);
            }
        });
    }

    return (
        <div className='furtherInformation'>
            {
                error && (
                    <div className='alert alert-danger'>{error}</div>
                )
            }
            <div className='card mb-3'>
                <div className='card-body'>
                    <h3 className='card-title text-center my-4'>We require more information.</h3>

                    {
                        loanCriteria && loanCriteria.map((crit) => {
                            return (
                                crit.divId === 'furtherInformation' && (
                                    <div className='form-group' key={crit.id}>
                                        <label htmlFor={crit.formId}>{ crit.question }</label>
                                        {
                                            crit.type.type === 'text' ? 
                                            <input type='text' 
                                            id={crit.formId} 
                                            className='form-control criteriaForm' 
                                            data-token={crit.token} 
                                            maxLength={crit.digit}
                                            defaultValue={elaboratedPurpose}
                                            onChange={validateField}
                                            style={{ borderColor: validationError && 'red' }}/>
                                            :
                                            crit.type.type === 'textarea' ? 
                                            <textarea id={crit.formId} 
                                            className='form-control criteriaForm'
                                            data-token={crit.token}
                                            placeholder={crit.placeholder}
                                            maxLength={crit.digit}
                                            rows={5}
                                            style={{ resize: 'none', borderColor: validationError && 'red' }}
                                            defaultValue={elaboratedPurpose}
                                            onChange={validateField}></textarea>
                                            :
                                            crit.type.type === 'number' ? 
                                            <input type='number' 
                                            id={crit.formId} 
                                            className='form-control criteriaForm' 
                                            data-token={crit.token}
                                            maxLength={crit.digit}
                                            defaultValue={elaboratedPurpose}
                                            onChange={validateField}
                                            style={{ borderColor: validationError && 'red' }}/>
                                            :
                                            crit.type.type === 'number' && (
                                                <select id={crit.formId} 
                                                className='form-control criteriaForm'
                                                data-token={crit.token}
                                                defaultValue={elaboratedPurpose}
                                                onChange={validateField}
                                                style={{ borderColor: validationError && 'red' }}>
                                                    <option value={''}>Select</option>
                                                    {
                                                        crit.answers.map((ans) => {
                                                            return (
                                                                <option value={ans.token} key={ans.token}>{ans.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            )
                                        }
                                        {
                                            validationError && (
                                                <div className='invalid-feedback show-error'>Must be answered.</div>
                                            )
                                        }
                                    </div>
                                )
                            )
                        })
                    }
                </div>
            </div>
            <Next text={'submit'} callback={submit}/>
        </div>
    )
}

export default StageFive