import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';

const LoanCalculator = ({
    loanAmount,
    setLoanAmount,
    loanPeriod,
    setLoanPeriod,
    hideFooterText
}) => {
    const [finalWeeklyLoanAmount, setFinalWeeklyLoanAmount] = useState(null);
    const [finalFornightlyLoanAmount, setFinalFornightlyLoanAmount] = useState(null);
    const [weeklyRepaymentAmount, setWeeklyRepaymentAmount] = useState(null);
    const [fornightlyRepaymentAmount, setFornightlyRepaymentAmount] = useState(null);
    const [fornightLoanPeriod, setFornightLoanPeriod] = useState(null);
    const location = useLocation();

    const calculateRepayment = () => {
		const timesWeekly = loanPeriod;
		const timesFortnightly = Math.ceil(loanPeriod/2);
        setFornightLoanPeriod(timesFortnightly);

		const establishFee = parseInt(loanAmount) * 0.2;
		const monthlyInterest = parseInt(loanAmount) * 0.04;

		var loanWeeksWeekly = loanPeriod;
		var loanWeeksFortnightly = loanPeriod;
		if((parseInt(loanPeriod) % 2) === 0){
			loanWeeksFortnightly = loanPeriod - 1;
		}
        
		const loanMonthsWeekly = Math.floor(loanWeeksWeekly / 4) + 1;
		const loanMonthsFortnightly = Math.floor(loanWeeksFortnightly / 4) + 1;
		
		const totalInterestWeekly = monthlyInterest * loanMonthsWeekly;
		const totalInterestFortnightly = monthlyInterest * loanMonthsFortnightly;

		const totalAmountWeekly = parseInt(loanAmount) + establishFee + totalInterestWeekly;
		const totalAmountFortnightly = parseInt(loanAmount) + establishFee + totalInterestFortnightly;

		const amountWeekly = (totalAmountWeekly/timesWeekly).toFixed(2);
		const amountFortnightly = (totalAmountFortnightly/timesFortnightly).toFixed(2);

        setFinalWeeklyLoanAmount(totalAmountWeekly);
        setFinalFornightlyLoanAmount(totalAmountFortnightly);

        setWeeklyRepaymentAmount(amountWeekly);
        setFornightlyRepaymentAmount(amountFortnightly);
	}

    useEffect(() => {
        calculateRepayment();
    }, [loanAmount, loanPeriod]);

    return (
        <div className="card mb-3">
            <div className="card-body">
                <h4 className="text-center">How much do you need?</h4>
                <div className="card mb-3">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-4"></div>
                            <div className="col-sm-4">
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">$</div>
                                    </div>
                                    <input 
                                    type="number" 
                                    className="form-control text-center text-primary input-special-lg" 
                                    name="amount" 
                                    value={loanAmount} 
                                    onChange={(e) => {setLoanAmount(e.target.value)}}
                                    min="300" max="2000"/>
                                </div>
                            </div>
                            <div className="col-sm-4"></div>
                        </div>
                        <div className="row">
                            <div className="col-2 px-0 text-end">
                                <button type="button" className="btn btn-sm btn-outline-primary" id="reduce-amount" 
                                data-toggle="tooltip" title="Reduce amount">
                                    <FontAwesomeIcon icon={'fas fa-minus'} onClick={()=>{setLoanAmount((prev) => prev > 300 ? parseInt(prev) - 25 : prev)}}/>
                                </button>                         
                            </div>
                            <div className="col-8 px-2">
                                <input type="range" className="custom-range w-100" name="amountRange" id="amountRange" value={loanAmount}
                                min="300" max="2000" step="25" onChange={(e) => {setLoanAmount(e.target.value)}}/>
                            </div>
                            <div className="col-2 px-0">
                                <button type="button" className="btn btn-sm btn-outline-primary" id="increase-amount" 
                                data-toggle="tooltip" title="Increase amount">
                                    <FontAwesomeIcon icon={'fas fa-plus'} onClick={()=>{setLoanAmount((prev) => prev < 2000 ? parseInt(prev) + 25 : prev)}}/>
                                </button>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-2"></div>
                            <div className="col-4">$300</div>
                            <div className="col-4 text-end">$2,000</div>
                            <div className="col-2"></div>
                        </div>
                    </div>
                </div>

                <h4 className="text-center">How long do you need it?</h4>
                <div className="card mb-3">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-4"></div>
                            <div className="col-sm-4">
                                <div className="input-group mb-2">
                                    <input type="number" className="form-control text-center text-primary input-special-lg" name="period" value={loanPeriod} 
                                    min="9" max="13" step="1" onChange={(e) => {setLoanPeriod(e.target.value)}}/>
                                    <div className="input-group-append">
                                        <div className="input-group-text period-append">weeks</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4"></div>
                        </div>
                        <div className="row">
                            <div className="col-2 px-0 text-end">
                                <button type="button" className="btn btn-default btn-sm btn-outline-primary" id="decrease-period" 
                                data-toggle="tooltip" title="Decrease period">
                                    <FontAwesomeIcon icon={'fas fa-minus'} onClick={(e) => {setLoanPeriod((prev) => prev > 9 ? prev - 1 : prev)}}/>
                                </button>
                            </div>
                            <div className="col-8 px-2">
                                <input type="range" className="custom-range w-100" name="periodRange" id="periodRange" value={loanPeriod} 
                                min="9" max="13" step="1" onChange={(e) => {setLoanPeriod(e.target.value)}}/>
                            </div>
                            <div className="col-2 px-0">
                                <button type="button" className="btn btn-default btn-sm btn-outline-primary" id="increase-period" 
                                data-toggle="tooltip" title="Increase period">
                                    <FontAwesomeIcon icon={'fas fa-plus'} onClick={(e) => {setLoanPeriod((prev) => prev < 13 ? prev + 1 : prev)}}/>
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2"></div>
                            <div className="col-4">9 weeks</div>
                            <div className="col-4 text-end">13 weeks</div>
                            <div className="col-2"></div>
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-6 text-center text-sm-right">
                                        <p className="card-text">Weekly</p>
                                    </div>
                                    <div className="col-sm-6 text-center text-sm-left pl-sm-0">
                                        <h5 className="card-text">
                                            $<span id="amountWeekly">{ weeklyRepaymentAmount }</span> <small>x <span id="timesWeekly">{ loanPeriod }</span></small>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-6 text-center text-sm-right">
                                        <p className="card-text">Fortnightly</p>
                                    </div>
                                    <div className="col-sm-6 text-center text-sm-left pl-sm-0">
                                        <h5 className="card-text">
                                            $<span id="amountFortnightly">{ fornightlyRepaymentAmount }</span> <small>x <span id="timesFortnightly">{ fornightLoanPeriod }</span></small>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="text-center">
                    <p>Total to repay including fees and charges: 
                        $<span id="repaymentWeekly">{ global.config.methods.toCurrency(finalWeeklyLoanAmount) }</span> (Weekly repayments) or 
                        $<span id="repaymentFortnightly">{ global.config.methods.toCurrency(finalFornightlyLoanAmount) }</span> (Fortnightly repayments).
                    </p>
                    <p>
                        <small>
                            These calculations are based on the amount and term selected and are intended as a guide only. 
                            They do not constitute an offer of credit from Cash Bridge.
                        </small>
                        {
                            !hideFooterText && (
                                <>
                                    <br/>
                                    <small>
                                        THE ABOVE EXAMPLE INCLUDES AN ESTABLISHMENT FEE OF 20% AND MONTHLY FEES OF 4% PER MONTH OF THE LOAN AMOUNT 
                                        OVER THE SELECTED PAY PERIOD. THE EXAMPLE IS ALSO BASED ON THE ASSUMPTION THAT REPAYMENTS ARE MADE ON TIME 
                                        AS PER THE ORIGINAL LOAN CONTRACT TERMS OTHERWISE OTHER FEES AND CHARGES ARE PAYABLE.
                                    </small>
                                </>
                            )
                        }
                    </p>
                </div>
                {
                    location.pathname === '/' && (
                        <div className='text-center'>
                            <Link className='btn btn-lg btn-outline-primary' to={'/apply'}>APPLY NOW <FontAwesomeIcon icon={'fas fa-edit'}/></Link>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default LoanCalculator