import $ from "jquery";
import axios from "axios";
import { Next } from "./next";
import { useAuthContext } from "../../hooks/auth";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react"
import WarningModal from "../../components/WarningModal";
import { Spinner } from "react-bootstrap";

const LoanPurpose = ({ 
    validationError,
    setValidationError,
    selectedLoanPurposeCategory, 
    setSelectedLoanPurposeCategory, 
    selectedLoanPurposeSubCategory, 
    setSelectedLoanPurposeSubCategory, 
    setOtherBenefit,
    loanType 
}) => {
    const [loanPurposeCategories, setLoanPurposeCategories] = useState(null);
    const [loanPurposeSubCategories, setLoanPurposeSubCategories] = useState(null);

    const getLoanPurposeCategories = () => {
        axios.get('/loans/purposes')
        .then((response) => {
            if(response.status === 200) {
                setLoanPurposeCategories(response.data.data.loanPurposeCategories);
            } 
        })
        .catch((err) => {
            const error = err.response?.data?.error ? err.response?.data?.error : err.message;
            global.config.methods.errorResponse(error);
        })
    }

    const toggleLoanPurposeCategories = (e) => {
        e.preventDefault();
        $(".dropdown-menu").css("display", "none");
        const target = $(e.currentTarget);
        if(target.siblings(".dropdown-menu").css("display") === "none") {
            target.siblings(".dropdown-menu").css("display", "block");
        }else{
            target.siblings(".dropdown-menu").css("display", "none");
        }
    }

    const toggleLoanPurposeSubCategories = (e) => {
        e.preventDefault();
        const target = $(e.currentTarget);
        $(".dropdown-menu").css("display", "none");
        if(target.siblings(".dropdown-menu").css("display") === "none") {
            target.siblings(".dropdown-menu").css("display", "block");
        }else{
            target.siblings(".dropdown-menu").css("display", "none");
        }
    }

    const getLoanPurposeSubCategories = (token) => {
        axios.get('/loans/purposes/' + token)
        .then((response) => {
            if(response.status === 200) {
                const subCategories = response.data.data.subCategories;
                setLoanPurposeSubCategories(subCategories);
            }
        })
        .catch ((err) => {
            const error = err.response?.data?.error ? err.response?.data?.error : err.message;
            global.config.methods.errorResponse(error);
        })
    }

    const selectCategory = (e) => {
        e.preventDefault();
        const target = $(e.currentTarget);
        const token = target.attr('data-token');
        target.parents('.dropdown-menu').css('display', "none");
        const category = loanPurposeCategories.find(x => x.token === token);
        if(!category) {
            global.config.methods.warningResponse('Category not found.');
            return false;
        }
        const index = validationError.findIndex(x => x === 'purposeCategory');
        if(index !== -1) {
            validationError.splice(index, 1);
            setValidationError([...validationError]);
        }
        setSelectedLoanPurposeCategory(category);
        setSelectedLoanPurposeSubCategory(null);
        getLoanPurposeSubCategories(category.token);
    }

    const selectSubCategory = (e) => {
        e.preventDefault();
        const target = $(e.currentTarget);
        const token = target.attr('data-token');
        const subCategory = loanPurposeSubCategories.find(x => x.token === token);
        if(!subCategory) {
            global.config.methods.warningResponse('Sub category not found.');
            return false;
        }
        const index = validationError.findIndex(x => x === 'purposeSubCategory');
        if(index !== -1) {
            validationError.splice(index, 1);
            setValidationError([...validationError]);
        }
        setSelectedLoanPurposeSubCategory(subCategory);
        target.parents('.dropdown-menu').hide();
    }

    useEffect(() => {
        getLoanPurposeCategories();
    }, []);

    return (
        <section className="purpose-section" style={{ display: 'none' }}>
            <h4 className="card-title text-center">Loan Purpose</h4>
            <div className="card mb-3 d-none">
                <div className="card-body">
                    <h3 className="card-title">Left to allocate</h3>
                    <h4 className="card-text text-success text-right" id="leftRequestedAmount">amount</h4>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    {
                        loanType === 'biz' ? 
                        <button className="btn btn-lg btn-block btn-primary btn-undisabled"  data-token="" disabled>BUSINESS LOAN</button>
                        : 
                        loanType === 'motor' ?
                        <div className="row mb-1">
                            <div className="col-md-4 first mb-1 mb-sm-0">
                                <button className="btn btn-lg btn-block btn-primary btn-undisabled"  data-token="" disabled>PURCHASES</button>
                            </div>

                            <div className="col-md-8 second mb-1 mb-mb-0">
                                <button className="btn btn-lg btn-block btn-primary btn-undisabled" data-token="" disabled>VEHICLE</button>
                            </div>
                        </div> :
                        <div className="row purpose-dropdown mb-1">
                            <div className="col-md-4 first mb-1 mb-sm-0">
                                <div className="w-100 btn-group btn-block" role="group">
                                    <button 
                                    id="btnGroupDrop1" 
                                    type="button" 
                                    className={"btn btn-block dropdown-toggle " + (selectedLoanPurposeCategory ? "btn-primary" : "btn-outline-primary")}
                                    style={{ whiteSpace: 'normal', borderColor: (validationError && validationError.includes('purposeCategory') ? 'red' : '')}} 
                                    onClick={toggleLoanPurposeCategories}
                                    disabled={loanPurposeCategories ? false : true}>
                                        {
                                            selectedLoanPurposeCategory ? 
                                            selectedLoanPurposeCategory.category : 
                                            'Choose your purpose'
                                        }
                                        
                                    </button>
                                    {
                                        !loanPurposeCategories && (
                                            <div className="text-center">
                                                <Spinner animation="border" role="status" size="sm" style={{ fontSize: '8px', marginLeft: '5px' }}>
                                                    <span className="visually-hidden">loading...</span>
                                                </Spinner>
                                            </div>
                                        )
                                    }
                                    <div 
                                    className="dropdown-menu w-100" 
                                    aria-labelledby="btnGroupDrop1"
                                    style={{ 
                                        position: 'absolute',
                                        willChange: 'transform',
                                        top: '100%',
                                        left: '0px',
                                    }}>
                                        {
                                            loanPurposeCategories && loanPurposeCategories.map((category) => {
                                                return (
                                                    <button 
                                                    className="dropdown-item btn-outline-primary-item" 
                                                    style={{ whiteSpace: 'normal' }} 
                                                    data-token={category.token} 
                                                    key={category.token}
                                                    onClick={selectCategory}>{category.category}</button>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="pl-2">
                                    {
                                        validationError && validationError.includes('purposeCategory') && (
                                        <FontAwesomeIcon icon={'fas fa-asterisk'} className="required-star" id="purpose-star"/>
                                        )
                                    }
                                    </div>
                                </div>
                                {
                                    validationError && validationError.includes('purposeCategory') && (
                                        <div className="invalid-feedback show-error">Purpose is required.</div>
                                    )
                                }
                            </div>
                            <div className={"col-md-8 second mb-1 mb-mb-0 " + (selectedLoanPurposeCategory ? "" : "d-none")}>
                                <div className="w-100 btn-group btn-block" role="group">
                                    <button 
                                    type="button" 
                                    className={"btn btn-block dropdown-toggle " + (selectedLoanPurposeSubCategory ? "btn-primary" : "btn-outline-primary")}
                                    style={{ whiteSpace: 'normal', borderColor: (validationError && validationError.includes('purposeSubCategory') ? 'red' : '') }}
                                    onClick={toggleLoanPurposeSubCategories}
                                    disabled={loanPurposeSubCategories ? false : true}>
                                        {
                                            selectedLoanPurposeSubCategory ? 
                                            selectedLoanPurposeSubCategory.description : 
                                            'Choose your benefit'
                                        }
                                    </button>
                                    {
                                        !loanPurposeSubCategories && (
                                            <div className="text-center">
                                                <Spinner animation="border" role="status" size="sm" style={{ fontSize: '8px', marginLeft: '5px' }}>
                                                    <span className="visually-hidden">loading...</span>
                                                </Spinner>
                                            </div>
                                        )
                                    }
                                    <div 
                                    className="dropdown-menu w-100"
                                    style={{ 
                                        position: 'absolute',
                                        willChange: 'transform',
                                        top: '100%',
                                        left: '0px',
                                    }}>
                                        {
                                            loanPurposeSubCategories && loanPurposeSubCategories.map((sub) => {
                                                return (
                                                    <button 
                                                    className="dropdown-item btn-outline-primary-item" 
                                                    style={{ whiteSpace: 'normal' }} 
                                                    data-token={sub.token}
                                                    onClick={selectSubCategory}
                                                    key={sub.token}>{ sub.description }</button>
                                                )
                                            })
                                        }
                                    </div>
                                    <div className="pl-2">
                                        {
                                            validationError && validationError.includes('purposeSubCategory') && (
                                                <FontAwesomeIcon icon={'fas fa-asterisk fa-xs'} className="required-star benefit-star"/>
                                            )
                                        }
                                    </div>
                                </div>
                                {
                                    validationError && validationError.includes('purposeSubCategory') && (
                                        <div className="invalid-feedback show-error">Benefit is required</div>
                                    )
                                }
                            </div>
                            <div className="col-md-12 mt-2">
                                {
                                    selectedLoanPurposeSubCategory && selectedLoanPurposeSubCategory.token === 'lN9O-ijYdJfYx' && (
                                        <div className="form-group">
                                            <label htmlFor="otherBenefit">What is it for?</label>
                                            <input type="text" 
                                            className="form-control otherBenefit" 
                                            placeholder="Specific details are helpful."
                                            onChange={(e) => {setOtherBenefit(e.target.value)}}/>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </section>
    )
}

const NewConsumer = ({ place, validationError, setValidationError, consumerDetails, setConsumerDetails }) => {
    const salutation = ['Mr', 'Mrs', 'Miss', 'Ms'];
    const [manualAddress, setManualAddress] = useState(false);
    const [gaddress, setGaddress] = useState(null);

    const validateFields = (e) => {
        const id = e.currentTarget.id;
        const value = e.currentTarget.value;
        
        if(!value){
            validationError.push(id);
            setValidationError([...validationError]);
        } else {
            const index = validationError.findIndex(x => x === id);
            if(index !== -1) {
                validationError.splice(index, 1);
                setValidationError([...validationError]);
            }

            if (id === 'email') {
                //eslint-disable-next-line
                const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                const format = regex.test(value);
                if(!format) {
                    if(!validationError.includes(id + '-format')) {
                        validationError.push(id + '-format');
                        setValidationError([...validationError]);
                    }
                }else {
                    const index = validationError.findIndex(x => x === (id + '-format'));
                    if(index !== -1) {
                        validationError.splice(index, 1);
                        setValidationError([...validationError]);
                    }
                }
            } else if (id === 'emailConfirm') {
                //eslint-disable-next-line
                const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                const format = regex.test(value);
                if(!format) {
                    if(!validationError.includes(id + '-format')) {
                        validationError.push(id + '-format');
                        setValidationError([...validationError]);
                    } 
                } else {
                    const index = validationError.findIndex(x => x === (id + '-format'));
                    if(index !== -1) {
                        validationError.splice(index, 1);
                        setValidationError([...validationError]);
                    }

                    if (value !== consumerDetails['email']) {
                        if(!validationError.includes(id + '-match')) {
                            validationError.push(id + '-match');
                            setValidationError([...validationError]);
                        }
                    } else {
                        const index = validationError.findIndex(x => x === (id + '-match'));
                        if(index !== -1) {
                            validationError.splice(index, 1);
                            setValidationError([...validationError]);
                        }
                    }
                }
            } else if (id === 'password') {
                if(value.length < 6 || value > 12) {
                    if(!validationError.includes(id + '-length')) {
                        validationError.push(id + '-length');
                        setValidationError([...validationError]);
                    }
                } else {
                    const index = validationError.findIndex(x => x === (id + '-length'));
                    if(index !== -1) {
                        validationError.splice(index, 1);
                        setValidationError([...validationError]);
                    }
                }
                //eslint-disable-next-line
                if (!/^(?=.*[a-zA-Z])(?=.*[0-9]).{0,50}$/.test(value)) {
                    if(!validationError.includes(id + '-format')) {
                        validationError.push(id + '-format');
                        setValidationError([...validationError]);
                    }
                } else {
                    const index = validationError.findIndex(x => x === (id + '-format'));
                    if(index !== -1) {
                        validationError.splice(index, 1);
                        setValidationError([...validationError]);
                    }
                }
            } else if (id === 'passwordConfirm') {
                if(value.length < 6 || value > 12) {
                    if(!validationError.includes(id + '-length')) {
                        validationError.push(id + '-length');
                        setValidationError([...validationError]);
                    }
                }  else {
                    const index = validationError.findIndex(x => x === (id + '-length'));
                    if(index !== -1) {
                        validationError.splice(index, 1);
                        setValidationError([...validationError]);
                    }
                }
                //eslint-disable-next-line
                if (!/^(?=.*[a-zA-Z])(?=.*[0-9]).{0,50}$/.test(value)) {
                    if(!validationError.includes(id + '-format')) {
                        validationError.push(id + '-format');
                        setValidationError([...validationError]);
                    }
                } else {
                    const index = validationError.findIndex(x => x === (id + '-format'));
                    if(index !== -1) {
                        validationError.splice(index, 1);
                        setValidationError([...validationError]);
                    }
                }

                if (value !== consumerDetails['password']) {
                    if(!validationError.includes(id + '-match')) {
                        validationError.push(id + '-match');
                        setValidationError([...validationError]);
                    }
                } else {
                    const index = validationError.findIndex(x => x === (id + '-match'));
                    if(index !== -1) {
                        validationError.splice(index, 1);
                        setValidationError([...validationError]);
                    }
                }
            } else if (id === 'day') {
                if(isNaN(parseInt(value))) {
                    if(!validationError.includes(id + '-format')) {
                        validationError.push(id + '-format');
                        setValidationError([...validationError]);
                    }
                } else {
                    const index = validationError.findIndex(x => x === (id + '-format'));
                    if(index !== -1) {
                        validationError.splice(index, 1);
                        setValidationError([...validationError]);
                    }
                }
                
                if(parseInt(value) < 1 || parseInt(value) > 31) {
                    if(!validationError.includes(id + '-length')) {
                        validationError.push(id + '-length');
                        setValidationError([...validationError]);
                    }
                } else {
                    const index = validationError.findIndex(x => x === (id + '-length'));
                    if(index !== -1) {
                        validationError.splice(index, 1);
                        setValidationError([...validationError]);
                    }
                }
            } else if (id === 'month') {
                if(isNaN(parseInt(value))) {
                    if(!validationError.includes(id + '-format')) {
                        validationError.push(id + '-format');
                        setValidationError([...validationError]);
                    }
                } else {
                    const index = validationError.findIndex(x => x === (id + '-format'));
                    if(index !== -1) {
                        validationError.splice(index, 1);
                        setValidationError([...validationError]);
                    }
                } 
                
                if(parseInt(value) < 1 || parseInt(value) > 12){
                    if(!validationError.includes(id + '-length')) {
                        validationError.push(id + '-length');
                        setValidationError([...validationError]);
                    }
                } else {
                    const index = validationError.findIndex(x => x === (id + '-length'));
                    if(index !== -1) {
                        validationError.splice(index, 1);
                        setValidationError([...validationError]);
                    }
                }
            } else if (id === 'year') {
                if(isNaN(parseInt(value))) {
                    if(!validationError.includes(id + '-format')) {
                        validationError.push(id + '-format');
                        setValidationError([...validationError]);
                    }
                } else {
                    const index = validationError.findIndex(x => x === (id + '-format'));
                    if(index !== -1) {
                        validationError.splice(index, 1);
                        setValidationError([...validationError]);
                    }
                }
                
                if(parseInt(value) < 1900 || parseInt(value) > (new Date()).getFullYear() - 18){
                    if(!validationError.includes(id + '-length')) {
                        validationError.push(id + '-format');
                        setValidationError([...validationError]);
                    }
                } else {
                    const index = validationError.findIndex(x => x === (id + '-length'));
                    if(index !== -1) {
                        validationError.splice(index, 1);
                        setValidationError([...validationError]);
                    }
                }
            } else if(id === 'mobile') {
                if(value.length !== 10) {
                    if(!validationError.includes(id + '-length')) {
                        validationError.push(id + '-length');
                        setValidationError([...validationError]);
                    }
                } else {
                    const index = validationError.findIndex(x => x === (id + '-length'));
                    if(index !== -1) {
                        validationError.splice(index, 1);
                        setValidationError([...validationError]);
                    }
                }
                //eslint-disable-next-line
                if(!/^04[\d]{8}$/.test(value)) {
                    if(!validationError.includes(id + '-format')) {
                        validationError.push(id + '-format');
                        setValidationError([...validationError]);
                    }
                } else {
                    const index = validationError.findIndex(x => x === (id + '-format'));
                    if(index !== -1) {
                        validationError.splice(index, 1);
                        setValidationError([...validationError]);
                    }
                }
            } else if(id === 'phoneHome') {
                if(!/^02[\d]{8}$/.test(value)) {
                    if(!validationError.includes('phoneHome-format')) {
                        validationError.push('phoneHome-format');
                        setValidationError([...validationError]);
                    }else {
                        const index = validationError.findIndex(x => x === (id + '-format'));
                        if(index !== -1) {
                            validationError.splice(index, 1);
                            setValidationError([...validationError]);
                        }
                    }
                }
            } else if (id === 'creditGuideAccept' || id === 'privacyAccept') {
                if(e.target.checked) {
                    const index = validationError.findIndex(x => x === id);
                    if(index !== -1) {
                        validationError.splice(index, 1);
                        setValidationError([...validationError]);
                    }
                } else {
                    if(!validationError.includes(id)) {
                        validationError.push(id);
                        setValidationError([...validationError]);
                    }
                }
            } 
            if(id === 'creditGuideAccept' || id === 'privacyAccept') {
                setConsumerDetails({...consumerDetails, [id]: e.target.checked })
            } else {
                setConsumerDetails({...consumerDetails, [id]: value })
            }
        }
    }

    const addressChange = (e) => {
        if(e.keyCode === 8 || e.keyCode === 46) {
            e.currentTarget.value = '';
            consumerDetails.unit = null;
            consumerDetails.streetNumber = null;
            consumerDetails.streetName = null;
            consumerDetails.city = null;
            consumerDetails.state = null;
            consumerDetails.postcode = null;
            consumerDetails.countryName = null;
            consumerDetails.rawAddress = null;
            setConsumerDetails({...consumerDetails});
            setGaddress(null);
        }
    }
    
    const fillPlace = (e) => {
        consumerDetails.rawAddress = e.target.value;
        setConsumerDetails({...consumerDetails});
        place.setComponentRestrictions(
            {'country': ['au']}
        );
        
        place.addListener('place_changed', () => {
            var address = {};
            var gaddress = {};
            var googleAddress = place.getPlace();

            if (!googleAddress.geometry) {
                global.config.methods.errorResponse('No details available for input: \'' + googleAddress.name + '\'')
                return false;
            }

            for (var i = 0; i < googleAddress.address_components.length; i++) {
                gaddress[googleAddress.address_components[i].types[0]] = { long : googleAddress.address_components[i].long_name, short : googleAddress.address_components[i].short_name };
            }
            
            address.unit = (gaddress.subpremise === undefined) ? '' : gaddress.subpremise.long;
            address.streetNumber = gaddress.street_number?.long;
            address.streetName = gaddress.route?.long;
            address.street = gaddress.route?.short;
            address.city = gaddress.locality?.long;
            address.regionCode = gaddress.administrative_area_level_1?.short;
            address.regionName = gaddress.administrative_area_level_1?.long;
            address.postcode = gaddress.postal_code?.short;
            address.countryCode = gaddress.country?.short;
            address.countryName = gaddress.country?.long;
            address.address = gaddress.street_number?.long + ' ' + gaddress.route?.long + ', ' + gaddress.locality?.long + ', ' + gaddress.administrative_area_level_1?.long;
            address.formattedAddress = googleAddress.formatted_address;
            address.latitude = googleAddress.geometry.location.lat();
            address.longtitude = googleAddress.geometry.location.lng();
            address.rawAddress = $('#rawAddress').val();

            setConsumerDetails({...consumerDetails, 
                unit: address.unit,
                streetNumber: address.streetNumber,
                streetName: address.streetName,
                city: address.city,
                state: address.regionName,
                postcode: address.postcode,
                countryName: address.countryName,
                rawAddress: $('#rawAddress').val()
            });
            setGaddress(address);
        });
    }

    const triggerManualAddress = () => {
        consumerDetails.unit = null;
        consumerDetails.streetNumber = null;
        consumerDetails.streetName = null;
        consumerDetails.city = null;
        consumerDetails.state = null;
        consumerDetails.postcode = null;
        consumerDetails.countryName = null;
        consumerDetails.rawAddress = null;
        consumerDetails.manualAddress = !manualAddress;
        setConsumerDetails({...consumerDetails});
        setGaddress(null);
        setManualAddress(!manualAddress);
    }
   
    return (
        <div className="step2 position-relative consumer-details-section mb-4" id="startStep2" style={{ display: 'none' }}>
            <form className="form personal-details">
                <div className="card mb-4">
                    <div className="card-body">
                        <h3 className="card-title text-center">Personal details</h3>
                        <p className="card-text text-end text-muted">
                            <FontAwesomeIcon icon={'fas fa-asterisk'} className="fa-xs required-star-legend"/>
                            Required fields</p>
                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group">
                                    <label htmlFor="title">
                                        <span>Title</span>
                                        <FontAwesomeIcon icon={'fas fa-asterisk fa-xs'} className="required-star" id="title-star"/>
                                    </label>
                                    <select 
                                    id="title" 
                                    className={"form-control custom-select validation-required " + 
                                    (validationError && validationError.includes('title') ? 'is-invalid' : '')} 
                                    onChange={validateFields}
                                    onBlur={validateFields}>
                                        <option value={''}>...</option>
                                        {
                                            salutation.map((sal) => {
                                                return (
                                                    <option value={sal} key={sal}>{sal}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    {
                                        validationError && validationError.includes('title') && (
                                            <div className="invalid-feedback show-error">
                                                Title is required.
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="form-group">
                                    <label htmlFor="firstName">First Name
                                        <FontAwesomeIcon icon={'fas fa-asterisk fa-xs'} className="required-star" id="firstName-star" aria-hidden="true"/>
                                    </label>
                                    <input 
                                    type="text" 
                                    id="firstName" 
                                    className={"form-control validation-required " + (validationError && validationError.includes("firstName") ? "is-invalid" : "")}
                                    onChange={validateFields} 
                                    onBlur={validateFields}
                                    required/>
                                    {
                                        validationError && validationError.includes("firstName") && (
                                            <div className="invalid-feedback show-error">First name is required.</div>
                                        )
                                    }
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="form-group">
                                    <label htmlFor="middleName">Middle Name(s)</label>
                                    <input type="text" className="form-control" id="middleName" onChange={(e) => {setConsumerDetails({...consumerDetails, middleName: e.target.value})}}/>
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="form-group">
                                    <label htmlFor="lastName">
                                        <span>Last Name</span>
                                        <FontAwesomeIcon icon={'fas fa-asterisk fa-xs'} className="required-star"/>
                                    </label>
                                    <input 
                                    type="text" 
                                    className={"form-control validation-required " + (validationError && validationError.includes("lastName") ? "is-invalid" : "")}
                                    id="lastName" 
                                    onChange={validateFields} 
                                    onBlur={validateFields}
                                    required/>
                                    {
                                        validationError && validationError.includes("lastName") && (
                                            <div className="invalid-feedback show-error">Last name is required.</div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group">
                                    <label htmlFor="email">
                                        <span>Email</span>
                                        <FontAwesomeIcon icon={'fas fa-asterisk fa-xs'} className="required-star" id="email-star" aria-hidden/>
                                    </label>
                                    <input type="text" 
                                    id="email" 
                                    className={"form-control validation-required " + 
                                    (validationError && 
                                        (validationError.includes("email") || 
                                        validationError.includes("email-format")) ? "is-invalid" : "")} 
                                    onChange={validateFields} 
                                    onBlur={validateFields}
                                    required/>
                                    {
                                        (validationError && validationError.includes("email") && (
                                            <div className="invalid-feedback show-error">Email is required.</div>
                                        )) 
                                        || 
                                        (validationError && validationError.includes("email-format") && (
                                            <div className="invalid-feedback show-error">Email is not valid.</div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="form-group">
                                    <label htmlFor="emailConfirm">
                                        <span>Email Confirm</span>
                                        <FontAwesomeIcon icon={'fas fa-asterisk fa-xs'} className="required-star" id="emailConfirm-star" aria-hidden/>
                                    </label>
                                    <input 
                                    type="text" 
                                    id="emailConfirm" 
                                    className={"form-control validation-required " + 
                                    (validationError && 
                                        (validationError.includes("emailConfirm") || 
                                        validationError.includes("emailConfirm-format") ||
                                        validationError.includes("emailConfirm-match")) ? "is-invalid" : "")}
                                    onChange={validateFields} 
                                    onBlur={validateFields}
                                    required/>
                                    {
                                        (validationError && validationError.includes("emailConfirm") && (
                                            <div className="invalid-feedback show-error">Confirm email is required.</div>
                                        )) 
                                        || 
                                        (validationError && validationError.includes("emailConfirm-format") && (
                                            <div className="invalid-feedback show-error">Confirm email is not valid.</div>
                                        ))
                                        || 
                                        (validationError && validationError.includes("emailConfirm-match") && (
                                            <div className="invalid-feedback show-error">Email doesn't match confirmation.</div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group">
                                    <label htmlFor="password">
                                        <span>Password</span>
                                        <FontAwesomeIcon icon={'fas fa-asterisk fa-xs'} className="required-star" id="password-star" aria-hidden/>
                                    </label>
                                    <input 
                                    id="password"
                                    type="password" 
                                    className={"form-control validation-required " + 
                                    (validationError && 
                                        (validationError.includes("password") || 
                                        validationError.includes("password-format") ||
                                        validationError.includes("password-length")) ? "is-invalid" : "")}
                                    autoComplete="off" 
                                    maxLength={20} 
                                    onChange={validateFields} 
                                    onBlur={validateFields}
                                    required/>
                                    {
                                        (validationError && validationError.includes("password") && (
                                            <div className="invalid-feedback show-error">Password is required.</div>
                                        ))
                                        ||
                                        (validationError && validationError.includes("password-format") && (
                                            <div className="invalid-feedback show-error">Password should be alphanumeric.</div>
                                        ))
                                        ||
                                        (validationError && validationError.includes("password-length") && (
                                            <div className="invalid-feedback show-error">Password must be between 6 and 12.</div>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="form-group">
                                    <label htmlFor="passwordConfirm">
                                        <span>Password Confirm</span>
                                        <FontAwesomeIcon icon={'fas fa-asterisk fa-xs'} className="required-star" id="passwordConfirm-star" aria-hidden/>
                                    </label>
                                    <input 
                                    type="password" 
                                    id="passwordConfirm" 
                                    className={"form-control validation-required " + 
                                    (validationError && 
                                        (validationError.includes("passwordConfirm") || 
                                        validationError.includes("passwordConfirm-format") ||
                                        validationError.includes("passwordConfirm-length") ||
                                        validationError.includes("passwordConfirm-match")) 
                                    ? "is-invalid" : "")}
                                    autoComplete="off"
                                    maxLength={20}
                                    onChange={validateFields} 
                                    onBlur={validateFields}
                                    required/>
                                     {
                                        (validationError && validationError.includes("passwordConfirm") && (
                                            <div className="invalid-feedback show-error">Confirm password is required.</div>
                                        ))
                                        ||
                                        (validationError && validationError.includes("passwordConfirm-format") && (
                                            <div className="invalid-feedback show-error">Password should be alphanumeric.</div>
                                        ))
                                        ||
                                        (validationError && validationError.includes("passwordConfirm-length") && (
                                            <div className="invalid-feedback show-error">Password must be between 6 and 12.</div>
                                        ))
                                        ||
                                        (validationError && validationError.includes("passwordConfirm-match") && (
                                            <div className="invalid-feedback show-error">Password doesn't match confirmation.</div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm">
                                <div className="form-group">
                                    <label htmlFor="dateofbirth">
                                        <span>Date of Birth</span>
                                        <FontAwesomeIcon icon={'fas fa-asterisk fa-xs'} className="required-star" id="dateofbirth-star" aria-hidden/>
                                    </label>
                                    <div className="d-flex flex-row">
                                        <div>
                                            <input 
                                            type="text" 
                                            className={"form-control validation-required " + 
                                            (validationError && 
                                                (validationError.includes("day") || 
                                                validationError.includes("day-format") || 
                                                validationError.includes("day-length")) ? "is-invalid" : "")}
                                            id="day" 
                                            placeholder="Day (DD)" 
                                            maxLength={2} 
                                            min={1} 
                                            max={31}
                                            onChange={validateFields} 
                                            onBlur={validateFields}
                                            required/>
                                            {
                                                (validationError && validationError.includes("day") && (
                                                    <div className="invalid-feedback show-error">Day is required.</div>
                                                ) )
                                                || 
                                                (validationError && 
                                                    (validationError.includes("day-format") || 
                                                    validationError.includes("day-length")) && (
                                                    <div className="invalid-feedback show-error">Day is invalid.</div>
                                                )) 
                                            }
                                        </div>
                                        <div className="col-5" style={{ padding: '0 15px' }}>
                                            <input 
                                            type="text" 
                                            className={"form-control validation-required " + 
                                            (validationError && 
                                                (validationError.includes("month") ||
                                                validationError.includes("month-length") ||
                                                validationError.includes("month-format"))
                                            ? "is-invalid" : "")}
                                            id="month" 
                                            placeholder="Month (MM)" 
                                            maxLength={2} 
                                            min={1} 
                                            max={12}
                                            onChange={validateFields} 
                                            onBlur={validateFields}
                                            required/>
                                            {
                                                (validationError && validationError.includes("month") && (
                                                    <div className="invalid-feedback show-error">Month is required.</div>
                                                ))
                                                ||
                                                (validationError && 
                                                    (validationError.includes("month-format") ||
                                                    validationError.includes("month-length")) && (
                                                    <div className="invalid-feedback show-error">Month is invalid.</div>
                                                ))
                                            }
                                        </div>
                                        <div>
                                            <input 
                                            type="text" 
                                            id="year" 
                                            className={"form-control validation-required " + 
                                            (validationError && 
                                                (validationError.includes("year") || 
                                                validationError.includes("year-format") || 
                                                validationError.includes("year-length"))
                                            ? "is-invalid" : "")}
                                            placeholder="Year (YYYY)" 
                                            maxLength={4}
                                            onChange={validateFields} 
                                            onBlur={validateFields}
                                            required/>
                                            {
                                                (validationError && validationError.includes("year") && (
                                                    <div className="invalid-feedback show-error">Year is required.</div>
                                                ))
                                                ||
                                                (validationError && 
                                                    (validationError.includes("year-length") || 
                                                    validationError.includes("year-format")) && (
                                                    <div className="invalid-feedback show-error">Year is invalid.</div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm">
                                <div className="form-group">
                                    <label htmlFor="mobile">
                                        <span>Mobile Number</span>
                                        <FontAwesomeIcon icon={'fas fa-asterisk fa-xs'} className="required-star" id="mobile-star" aria-hidden/>
                                    </label>
                                    <input 
                                    type="text" 
                                    id="mobile" 
                                    className={"form-control validation-required " + 
                                    (validationError && 
                                        (validationError.includes("mobile") ||
                                        validationError.includes("mobile-length") ||
                                        validationError.includes("mobile-format")) ? "is-invalid" : "")}
                                    maxLength={10} 
                                    placeholder="0412345678"
                                    onChange={validateFields} 
                                    onBlur={validateFields}
                                    required/>
                                    {
                                        (validationError && validationError.includes("mobile") && (
                                            <div className="invalid-feedback show-error">Mobile is required.</div>
                                        ))
                                        ||
                                        (validationError && validationError.includes("mobile-length") && (
                                            <div className="invalid-feedback show-error">Mobile Number should be a valid 10 digit number.</div>
                                        )) 
                                        ||
                                        (validationError && validationError.includes("mobile-format") && (
                                            <div className="invalid-feedback show-error">Mobile Number must start with 04 followed by 8 digits.</div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="phoneHome">Home Phone</label>
                                    <input 
                                    type="text" 
                                    id="phoneHome" 
                                    className="form-control" 
                                    maxLength={10}
                                    style={{ borderColor: validationError && validationError.includes('phoneHome-format') ? 'red' : '' }}
                                    onChange={validateFields}/>
                                    {
                                        validationError && validationError.includes('phoneHome-format') && (
                                            <div className="invalid-feedback show-error">Home phone should be a valid 10 digit number</div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="form-group mb-4">
                            <div className="d-flex justify-content-between">
                                <label htmlFor="rawAddress">Address</label>
                                <span className="cant-find-address" style={{ cursor: 'pointer', color: '#e0b866' }} onClick={triggerManualAddress}>Can't find your address?</span>
                            </div>
                            <div className="input-group">
                                <input type="text" 
                                id="rawAddress" 
                                className={"form-control validation-required " + (!manualAddress && validationError && validationError.includes("rawAddress") ? "is-invalid" : "")}
                                autoComplete="off"
                                placeholder="Enter a location"
                                onKeyDown={addressChange}
                                onChange={fillPlace} 
                                onBlur={validateFields}
                                disabled={manualAddress}/>
                                {
                                    !manualAddress && validationError && validationError.includes('rawAddress') && (
                                        <div className="invalid-feedback show-error">Address is required.</div>
                                    )
                                }
                                <small className="form-text position-absolute" style={{ right: '0', marginTop: '35px' }}>
                                    <img src={ window.location.origin + "/img/powered_by_google_on_white.png"} alt="google-map-img"/>
                                </small>
                            </div>
                            <input type="text" id="rawAddressJson" hidden/>
                        </div>
                        {
                        manualAddress && (
                            <div className="manual-address">
                                <div className="row">
                                    <div className="form-group col-6 col-sm-3">
                                        <label htmlFor="unit">Unit</label>
                                        <input type="text" 
                                        id="unit" 
                                        className="form-control form-control-sm" 
                                        maxLength={10}
                                        onChange={validateFields} 
                                        onBlur={validateFields}/>
                                    </div>
            
                                    <div className="form-group col-6 col-sm-3">
                                        <label htmlFor="streetNumber">Street Number</label>
                                        <input 
                                        type="text" 
                                        id="streetNumber" 
                                        className={"form-control form-control-sm validation-required " + 
                                        (manualAddress && validationError && validationError.includes("streetNumber") ? "is-invalid" : "")} 
                                        disabled={!manualAddress}
                                        onChange={validateFields} 
                                        onBlur={validateFields}/>
                                        {
                                            manualAddress && validationError && validationError.includes("streetNumber") && (
                                                <div className="invalid-feedback show-error">Street number is required.</div>
                                            )
                                        }
                                    </div>
            
                                    <div className="form-group col-sm">
                                        <label htmlFor="streetName">Street Name</label>
                                        <input 
                                        type="text" 
                                        id="streetName" 
                                        className={"form-control form-control-sm validation-required " + 
                                        (manualAddress && validationError && validationError.includes("streetName") ? "is-invalid" : "") }
                                        disabled={!manualAddress}
                                        onChange={validateFields} 
                                        onBlur={validateFields}/>
                                        {
                                            manualAddress && validationError && validationError.includes("streetName") && (
                                                <div className="invalid-feedback show-error">Street name is required.</div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col">
                                        <label htmlFor="city">City</label>
                                        <input 
                                        type="text" 
                                        id="city" 
                                        className={"form-control form-control-sm validation-required " + 
                                        (manualAddress && validationError && validationError.includes("city") ? "is-invalid" : "") }
                                        disabled={!manualAddress}
                                        onChange={validateFields} 
                                        onBlur={validateFields}/>
                                        {
                                            manualAddress && validationError && validationError.includes("city") && (
                                                <div className="invalid-feedback show-error">City is required.</div>
                                            )
                                        }
                                    </div>
                                    <div className="form-group col">
                                        <label htmlFor="state">State</label>
                                        <input 
                                        type="text" 
                                        id="state" 
                                        className={"form-control form-control-sm validation-required " + 
                                        (manualAddress && validationError && validationError.includes("state") ? "is-invalid" : "") }
                                        disabled={!manualAddress}
                                        onChange={validateFields} 
                                        onBlur={validateFields}/>
                                        {
                                            manualAddress && validationError && validationError.includes("state") && (
                                                <div className="invalid-feedback show-error">Street number is required.</div>
                                            )
                                        }
                                    </div>
                                    <div className="form-group col">
                                        <label htmlFor="postcode">Postcode</label>
                                        <input 
                                        type="text" 
                                        id="postcode" 
                                        className={"form-control form-control-sm validation-required " + 
                                        (manualAddress && validationError && validationError.includes("postcode") ? "is-invalid" : "") }
                                        disabled={!manualAddress}
                                        onChange={validateFields} 
                                        onBlur={validateFields}/>
                                        {
                                            manualAddress && validationError && validationError.includes("postcode") && (
                                                <div className="invalid-feedback show-error">Street number is required.</div>
                                            )
                                        }
                                    </div>
                                    <div className="form-group col">
                                        <label htmlFor="countryName">Country</label>
                                        <input 
                                        type="text" 
                                        id="countryName" 
                                        className={"form-control form-control-sm validation-required " + 
                                        (manualAddress && validationError && validationError.includes("countryName") ? "is-invalid" : "") }
                                        disabled={!manualAddress}
                                        onChange={validateFields} 
                                        onBlur={validateFields}/>
                                        {
                                            manualAddress && validationError && validationError.includes("countryName") && (
                                                <div className="invalid-feedback show-error">Country name is required.</div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            )
                        }
                        {
                        gaddress && (
                            <div className="manual-address">
                                <div className="row">
                                    <div className="form-group col-6 col-sm-3">
                                        <label htmlFor="unit">Unit</label>
                                        <input type="text" 
                                        id="unit" 
                                        className="form-control form-control-sm" 
                                        maxLength={10}
                                        value={gaddress?.unit}
                                        disabled={gaddress?.unit}
                                        onChange={validateFields} 
                                        onBlur={validateFields}/>
                                    </div>
            
                                    <div className="form-group col-6 col-sm-3">
                                        <label htmlFor="streetNumber">Street Number</label>
                                        <input 
                                        type="text" 
                                        id="streetNumber" 
                                        className={"form-control form-control-sm " + 
                                        (manualAddress && validationError && validationError.includes("streetNumber") ? "is-invalid" : "")} 
                                        disabled={gaddress?.streetNumber}
                                        value={gaddress?.streetNumber}
                                        onChange={validateFields} 
                                        onBlur={validateFields}/>
                                    </div>
            
                                    <div className="form-group col-sm">
                                        <label htmlFor="streetName">Street Name</label>
                                        <input 
                                        type="text" 
                                        id="streetName" 
                                        className={"form-control form-control-sm " + 
                                        (manualAddress && validationError && validationError.includes("streetName") ? "is-invalid" : "") }
                                        disabled={gaddress?.streetName}
                                        value={gaddress?.streetName}
                                        onChange={validateFields} 
                                        onBlur={validateFields}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col">
                                        <label htmlFor="city">City</label>
                                        <input 
                                        type="text" 
                                        id="city" 
                                        className={"form-control form-control-sm " + 
                                        (manualAddress && validationError && validationError.includes("city") ? "is-invalid" : "") }
                                        disabled={gaddress?.city}
                                        value={gaddress?.city}
                                        onChange={validateFields} 
                                        onBlur={validateFields}/>
                                    </div>
                                    <div className="form-group col">
                                        <label htmlFor="state">State</label>
                                        <input 
                                        type="text" 
                                        id="state" 
                                        className={"form-control form-control-sm " + 
                                        (manualAddress && validationError && validationError.includes("state") ? "is-invalid" : "") }
                                        disabled={gaddress?.regionName}
                                        value={gaddress?.regionName}
                                        onChange={validateFields} 
                                        onBlur={validateFields}/>
                                    </div>
                                    <div className="form-group col">
                                        <label htmlFor="postcode">Postcode</label>
                                        <input 
                                        type="text" 
                                        id="postcode" 
                                        className={"form-control form-control-sm " + 
                                        (manualAddress && validationError && validationError.includes("postcode") ? "is-invalid" : "") }
                                        disabled={gaddress?.postcode}
                                        value={gaddress?.postcode}
                                        onChange={validateFields} 
                                        onBlur={validateFields}/>
                                    </div>
                                    <div className="form-group col">
                                        <label htmlFor="countryName">Country</label>
                                        <input 
                                        type="text" 
                                        id="countryName" 
                                        className={"form-control form-control-sm " + 
                                        (manualAddress && validationError && validationError.includes("countryName") ? "is-invalid" : "") }
                                        disabled={gaddress?.countryName}
                                        value={gaddress?.countryName}
                                        onChange={validateFields} 
                                        onBlur={validateFields}/>
                                    </div>
                                </div>
                            </div>
                            )
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        <div className="form-group">
                            <div className="form-check">
                                <input type="checkbox" 
                                className="form-check-input validation-required" 
                                name="privacyAccept" 
                                id="privacyAccept" 
                                onChange={validateFields}/>
                                <label className={"form-check-label " + (validationError && validationError.includes("privacyAccept") ? "text-danger" : "")} htmlFor="privacyAccept">
                                    I have read the <a href="/pdf/CashBridge_Credit-Information-Privacy-Management-Policy.pdf" target="_blank">
                                         Privacy and Electronic Authorisation 
                                    </a> and consent to the collection, use, holding and disclosure of my personal and
                                    credit-related information as well as electronic communication.
                                </label>
                                {
                                    validationError && validationError.includes('privacyAccept') && (
                                        <div className="invalid-feedback show-error">Terms and condition must be accepted</div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        <div className="form-group">
                            <div className="form-check">
                                <input 
                                type="checkbox" 
                                className="form-check-input validation-required" 
                                name="creditGuideAccept" 
                                id="creditGuideAccept"
                                onChange={validateFields}/>
                                <label className={"form-check-label " + (validationError && validationError.includes("creditGuideAccept") ? "text-danger" : "")} htmlFor="creditGuideAccept">
                                    I have accessed and read the <a href="/pdf/CashBridge_Credit-Providers-Credit-Guide.pdf" target="_blank">
                                        Credit Guide
                                    </a>.
                                </label>
                                {
                                    validationError && validationError.includes('creditGuideAccept') && (
                                        <div className="invalid-feedback show-error">Credit guide must be accepted</div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

const ReturnConsumer = ({ validationError, setValidationError, consumerDetails, setConsumerDetails}) => {
    const { consumer } = useAuthContext();

    return (
        <div className="step2 position-relative consumer-details-section mb-4" id="startStep2" style={{ display: 'none' }}>
            <form className="form personal-details">
                <div className="card mb-4">
                    <div className="card-body">
                        <h3 className="card-title text-center">Confirm your details</h3>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="mobile"> 
                                        <span>Mobile Number</span>
                                        <FontAwesomeIcon icon={'fas fa-asterisk'} className="fa-xs required-star"/>
                                    </label>
                                    <input type="text" id="mobile" className="form-control form-control-sm" placeholder="0412345678" defaultValue={consumer.address?.mobile} disabled/>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="form-group">
                                    <label htmlFor="phoneHome">Home Phone</label>
                                    <input id="phoneHome" className="form-control form-control-sm" defaultValue={consumer.address?.phoneHome} disabled/>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="address">
                                <span>Address</span>
                                <FontAwesomeIcon icon={'fas fa-asterisk fa-xs'} className="required-star"/>
                            </label>
                            <input className="form-control form-control-sm" id="address" defaultValue={consumer.address?.rawAddress} disabled/>
                        </div>
                        <div className="row">
                            <div className="form-group col-6 col-sm-3">
                                <label htmlFor="unit">Unit</label>
                                <input id="unit" className="form-control form-control-sm" defaultValue={consumer.address?.unit} disabled/>
                            </div>

                            <div className="form-group col-6 col-sm-3">
                                <label htmlFor="streetNumber">Street Number</label>
                                <input id="streetNumber" className="form-control form-control-sm" defaultValue={consumer.address?.streetNumber} disabled/>
                            </div>

                            <div className="form-group col-sm">
                                <label htmlFor="streetName">Street Name</label>
                                <input type="text" id="streetName" className="form-control form-control-sm" defaultValue={consumer.address?.streetName} disabled/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-group col">
                                <label htmlFor="city">City</label>
                                <input type="text" id="city" className="form-control form-control-sm" defaultValue={consumer.address?.city} disabled/>
                            </div>
                            <div className="form-group col">
                                <label htmlFor="regionName">State</label>
                                <input type="text" id="regionName" className="form-control form-control-sm" defaultValue={consumer.address?.regionName} disabled/>
                            </div>
                            <div className="form-group col">
                                <label htmlFor="postcode">Postcode</label>
                                <input type="text" id="postcode" className="form-control form-control-sm" defaultValue={consumer.address?.postcode} disabled/>
                            </div>
                            <div className="form-group col">
                                <label htmlFor="countryName">Country</label>
                                <input type="text" id="countryName" className="form-control form-control-sm" defaultValue={consumer.address?.countryName} disabled/>
                            </div>
                        </div>

                        <p className="card-text text-center">Need to update your contact details? Just click "Edit My Details" to get started.</p>
                        <p className="card-text text-center">
                            <Link className="btn btn-primary" to={'/members/edit'}>Edit My Details</Link>
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm">
                        <div className="form-group">
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input validation-required" name="privacyAccept" id="privacyAccept" onClick={(e) => {
                                    if (e.target.checked) {
                                        const index = validationError.findIndex(x => x === 'privacyAccept');
                                        if(index !== -1) {
                                            validationError.splice(index, 1);
                                            setValidationError([...validationError]);
                                        }
                                    } else {
                                        if(!validationError.includes('privacyAccept')) {
                                            validationError.push('privacyAccept');
                                            setValidationError([...validationError]);
                                        }
                                    }
                                    setConsumerDetails({...consumerDetails, privacyAccept: e.target.checked})
                                }}/>
                                <label className={"form-check-label " + (validationError && validationError.includes('privacyAccept') ? 'text-danger' : '')} htmlFor="privacyAccept">
                                    I have read the <a href="/pdf/CashBridge_Credit-Information-Privacy-Management-Policy.pdf" target="_blank">
                                         Privacy and Electronic Authorisation 
                                    </a> and consent to the collection, use, holding and disclosure of my personal and
                                    credit-related information as well as electronic communication.
                                </label>
                                {
                                    validationError && validationError.includes('privacyAccept') && (
                                        <div className="invalid-feedback show-error">Terms and conditions must be accepted.</div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        <div className="form-group">
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input validation-required" name="creditGuideAccept" id="creditGuideAccept" onClick={(e) => {
                                    if (e.target.checked) {
                                        const index = validationError.findIndex(x => x === 'creditGuideAccept');
                                        if(index !== -1) {
                                            validationError.splice(index, 1);
                                            setValidationError([...validationError]);
                                        }
                                    } else {
                                        if(!validationError.includes('creditGuideAccept')) {
                                            validationError.push('creditGuideAccept');
                                            setValidationError([...validationError]);
                                        }
                                    }
                                    setConsumerDetails({...consumerDetails, creditGuideAccept: e.target.checked})
                                }}/>
                                <label className={"form-check-label " + (validationError && validationError.includes('creditGuideAccept') ? 'text-danger' : '')} htmlFor="creditGuideAccept">
                                    I have accessed and read the <a href="/pdf/CashBridge_Credit-Providers-Credit-Guide.pdf" target="_blank">
                                        Credit Guide
                                    </a>.
                                </label>
                                {
                                    validationError && validationError.includes('creditGuideAccept') && (
                                        <div className="invalid-feedback show-error">Credit guide must be accepted.</div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

const BusinessPrestage = ({
    loanAmount,
    setLoanAmount,
    loanPeriod,
    setLoanPeriod,
}) => {
    return (
        <div className="define-loan-details card mb-4">
            <div className="card-body"> 
                <h3 className="card-title text-center">Make Your Business Loan</h3>
                <p className="card-subtitle mb-3 text-muted text-center">Let us know how much you need and what you need it for. Then enter the amount.</p>

                {/* <!-- Loan amount section --> */}
                <h4 className="card-title text-center">Loan Amount</h4>
                <div className="card mb-3">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-4"></div>
                            <div className="col-sm-4">
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">$</div>
                                    </div>
                                    <input 
                                    type="number" 
                                    className="form-control text-center text-primary input-special-lg" 
                                    name="amount" 
                                    value={loanAmount}
                                    onChange={(e) => {
                                        setLoanAmount(e.target.value);
                                    }}
                                    onBlur={(e) => {
                                        if(e.target.value < 10000) {
                                            setLoanAmount(10000);
                                        }
                                        if(e.target.value > 100000) {
                                            setLoanAmount(100000);
                                        }
                                    }}
                                    min="10000" 
                                    max="100000"/>
                                </div>
                            </div>
                            <div className="col-sm-4"></div>
                        </div>
                        <div className="row">
                            <div className="col-2 px-0 text-end">
                                <button type="button" 
                                className="btn btn-sm btn-outline-primary" 
                                id="reduce-amount"
                                data-toggle="tooltip" title="Reduce amount">
                                    <FontAwesomeIcon icon={'fas fa-minus'}
                                    onClick={(e) => { 
                                    loanAmount !== 10000 && 
                                    setLoanAmount(prev => parseInt(prev) - 5000)}}/>
                                </button>
                            </div>
                            <div className="col-8 px-2">
                                <input type="range" 
                                className="custom-range w-100" 
                                name="amountRange" 
                                id="amountRange" 
                                value={loanAmount}
                                onChange={(e) => {setLoanAmount(e.target.value)}}
                                min="10000" 
                                max="100000" 
                                step="5000"/>
                            </div>
                            <div className="col-2 p-0">
                                <button type="button" 
                                className="btn btn-sm btn-outline-primary" 
                                id="increase-amount"
                                data-toggle="tooltip" 
                                title="Increase amount"
                                onClick={(e) => { 
                                loanAmount !== 100000 &&
                                setLoanAmount(prev => parseInt(prev) + 5000)}}>
                                    <FontAwesomeIcon icon={'fas fa-plus'}/>
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2"></div>
                            <div className="col-4">$10,000</div>
                            <div className="col-4 text-end">$100,000</div>
                            <div className="col-2"></div>
                        </div>
                    </div>
                </div>

                {/* <!-- Loan period section --> */}
                <h4 className="text-center">Loan Period</h4>
                <div className="card mb-3">
                    <div className="card-body">
                        {
                            parseInt(loanPeriod) === 0 ? 
                            <h4 className="card-title text-center periodDisplay">6 months</h4>
                            :
                            parseInt(loanPeriod) === 5 ?
                            <h4 className="card-title text-center periodDisplay">5 years+</h4>
                            : 
                            <h4 className="card-title text-center periodDisplay">{ loanPeriod } years</h4>
                        }
                        <input type="range" 
                        className="custom-range w-100" 
                        name="periodRange" 
                        id="periodRange" 
                        value={ loanPeriod }
                        onChange={(e) => {setLoanPeriod(e.target.value)}}
                        min="0" 
                        max="5" 
                        step="1" />
                        <div className="row">
                            <div className="col-6">6 months</div>
                            <div className="col-6 text-end">5 years+</div>
                        </div>
                    </div>
                </div>

                <section className="purpose-section" style={{ display: 'none' }}>
                    <h4 className="card-title text-center">Loan Purpose</h4>
                    <div className="card mb-3 d-none">
                        <div className="card-body">
                            <h4 className="card-title">Left to allocate</h4>
                            <h4 className="card-text text-success text-right" id="leftRequestedAmount">input amount here</h4>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <button 
                            className="btn btn-lg btn-block btn-primary btn-undisabled w-100" 
                            data-token="" 
                            disabled>BUSINESS LOAN</button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

const MotorPrestage = ({
    loanAmount,
    setLoanAmount,
    loanPeriod,
    setLoanPeriod
}) => {
    return ( 
        <div className="define-loan-details card mb-4">
            <WarningModal />
            <div className="card-body"> 
                <h3 className="card-title text-center">Make Your Motorbike Loan</h3>
                <p className="card-subtitle mb-3 text-muted text-center">Let us know how much you need and what you need it for. Then enter the amount.</p>

                {/* <!-- Loan amount section --> */}
                <h4 className="card-title text-center">Loan Amount</h4>
                <div className="card mb-3">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-4"></div>
                            <div className="col-sm-4">
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">$</div>
                                    </div>
                                    <input 
                                    type="number" 
                                    className="form-control text-center text-primary input-special-lg" 
                                    name="amount" 
                                    value={loanAmount}
                                    min="5000" 
                                    max="15000"
                                    onChange={(e) => {setLoanAmount(e.target.value)}}
                                    onBlur={(e) => {
                                        if(e.target.value < 5000) {
                                            setLoanAmount(5000);
                                        }
                                        if(e.target.value > 15000) {
                                            setLoanAmount(15000);
                                        }
                                    }}/>
                                </div>
                            </div>
                            <div className="col-sm-4"></div>
                        </div>
                        <div className="row">
                            <div className="col-2 px-0 text-end">
                                <button type="button" 
                                className="btn btn-sm btn-outline-primary" 
                                id="reduce-amount"
                                data-toggle="tooltip" title="Reduce amount">
                                    <FontAwesomeIcon icon={'fas fa-minus'}
                                    onClick={(e) => { 
                                    loanAmount !== 5000 && 
                                    setLoanAmount(prev => parseInt(prev) - 500)}}/>
                                </button>
                            </div>
                            <div className="col-8 px-2">
                                <input type="range" 
                                className="custom-range w-100" 
                                name="amountRange" 
                                id="amountRange" 
                                value={loanAmount}
                                onChange={(e) => {setLoanAmount(e.target.value)}}
                                min="5000" 
                                max="15000" 
                                step="500"/>
                            </div>
                            <div className="col-2 p-0">
                                <button type="button" 
                                className="btn btn-sm btn-outline-primary" 
                                id="increase-amount"
                                data-toggle="tooltip" 
                                title="Increase amount"
                                onClick={(e) => { 
                                loanAmount !== 15000 &&
                                setLoanAmount(prev => parseInt(prev) + 500)}}>
                                    <FontAwesomeIcon icon={'fas fa-plus'}/>
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2"></div>
                            <div className="col-4">$5,000</div>
                            <div className="col-4 text-end">$15,000</div>
                            <div className="col-2"></div>
                        </div>
                    </div>
                </div>

                {/* <!-- Loan period section --> */}
                <h4 className="text-center">Loan Period</h4>
                <div className="card mb-3">
                    <div className="card-body">
                        <h4 className="card-title text-center periodDisplay">{ loanPeriod } years</h4>
                        <input type="range" 
                        className="custom-range w-100" 
                        name="periodRange" 
                        id="periodRange" 
                        value={ loanPeriod }
                        onChange={(e) => {setLoanPeriod(e.target.value)}}
                        min="1" 
                        max="3" 
                        step="1" />
                        <div className="row">
                            <div className="col-6">1 year</div>
                            <div className="col-6 text-end">3 years</div>
                        </div>
                    </div>
                </div>

                {/* <!--Loan purpose section --> */}
                <section className="purpose-section" style={{ display: 'none' }}>
                    <h4 className="card-title text-center">Loan Purpose</h4>
                    <div className="card mb-3 d-none">
                        <div className="card-body">
                            <h4 className="card-title">Left to allocate</h4>
                            <h4 className="card-text text-success text-right" id="leftRequestedAmount">input amount here</h4>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-body">
                            <div className="row mb-1">
                                <div className="col-md-4 first mb-1 mb-sm-0">
                                    <button className="w-100 btn btn-lg btn-block btn-primary btn-undisabled"  data-token="" disabled>PURCHASES</button>
                                </div>

                                <div className="col-md-8 second mb-1 mb-mb-0">
                                    <button className="w-100 btn btn-lg btn-block btn-primary btn-undisabled" data-token="" disabled>VEHICLE</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

const SaccPrestage = ({
    loanAmount,
    setLoanAmount,
    loanPeriod,
    setLoanPeriod,
    validationError,
    setValidationError,
    selectedLoanPurposeCategory,
    setSelectedLoanPurposeCategory,
    selectedLoanPurposeSubCategory,
    setSelectedLoanPurposeSubCategory,
    setOtherBenefit
}) => {
    return ( 
        <div className="define-loan-details card mb-3">
            <WarningModal />
            <div className="card-body">
                <h3 className="text-center">Make Your Loan</h3>
                <p className="card-subtitle mb-3 text-muted text-center">Let us know how much you need and what you need it for. Then enter the amount.</p>
                <h4 className="card-title text-center">Loan Amount</h4>
                <div className="card mb-3">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-4"></div>
                            <div className="col-sm-4">
                                <div className="input-group mb-2">
                                    <div className="input-group-prepend">
                                        <div className="input-group-text">$</div>
                                    </div>
                                    <input 
                                    type="number" 
                                    className="form-control text-center text-primary input-special-lg" 
                                    name="amount" 
                                    value={loanAmount} 
                                    onChange={(e) => {setLoanAmount(e.target.value)}}
                                    min="300" max="2000"/>
                                </div>
                            </div>
                            <div className="col-sm-4"></div>
                        </div>
                        <div className="row">
                            <div className="col-2 px-0 text-end">
                                <button type="button" className="btn btn-sm btn-outline-primary" id="reduce-amount" 
                                data-toggle="tooltip" title="Reduce amount">
                                    <FontAwesomeIcon icon={'fas fa-minus'} onClick={()=>{setLoanAmount((prev) => prev > 300 ? prev - 25 : prev)}}/>
                                </button>                         
                            </div>
                            <div className="col-8 px-2">
                                <input type="range" className="custom-range w-100" name="amountRange" id="amountRange" value={loanAmount}
                                min="300" max="2000" step="25" onChange={(e) => {setLoanAmount(parseInt(e.target.value))}}/>
                            </div>
                            <div className="col-2 px-0">
                                <button type="button" className="btn btn-sm btn-outline-primary" id="increase-amount" 
                                data-toggle="tooltip" title="Increase amount">
                                    <FontAwesomeIcon icon={'fas fa-plus'} onClick={()=>{setLoanAmount((prev) => prev < 2000 ? prev + 25 : prev)}}/>
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2"></div>
                            <div className="col-4">$300</div>
                            <div className="col-4 text-end">$2,000</div>
                            <div className="col-2"></div>
                        </div>
                    </div>
                </div>

                <h4 className="text-center">How long do you need it?</h4>
                <div className="card mb-3">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-4"></div>
                            <div className="col-sm-4">
                                <div className="input-group mb-2">
                                    <input type="number" className="form-control text-center text-primary input-special-lg" name="period" value={loanPeriod} 
                                    min="9" max="13" step="1" onChange={(e) => {setLoanPeriod(e.target.value)}}/>
                                    <div className="input-group-append">
                                        <div className="input-group-text period-append">weeks</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4"></div>
                        </div>
                        <div className="row">
                            <div className="col-2 px-0 text-end">
                                <button type="button" className="btn btn-default btn-sm btn-outline-primary" id="decrease-period" 
                                data-toggle="tooltip" title="Decrease period">
                                    <FontAwesomeIcon icon={'fas fa-minus'} onClick={(e) => {setLoanPeriod((prev) => prev > 9 ? prev - 1 : prev)}}/>
                                </button>
                            </div>
                            <div className="col-8 px-2">
                                <input type="range" className="custom-range w-100" name="periodRange" id="periodRange" value={loanPeriod} 
                                min="9" max="13" step="1" onChange={(e) => {setLoanPeriod(e.target.value)}}/>
                            </div>
                            <div className="col-2 px-0">
                                <button type="button" className="btn btn-default btn-sm btn-outline-primary" id="increase-period" 
                                data-toggle="tooltip" title="Increase period">
                                    <FontAwesomeIcon icon={'fas fa-plus'} onClick={(e) => {setLoanPeriod((prev) => prev < 13 ? prev + 1 : prev)}}/>
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-2"></div>
                            <div className="col-4">9 weeks</div>
                            <div className="col-4 text-end">13 weeks</div>
                            <div className="col-2"></div>
                        </div>
                    </div>
                </div>
                <LoanPurpose 
                validationError={validationError}
                setValidationError={setValidationError}
                selectedLoanPurposeCategory={selectedLoanPurposeCategory}
                setSelectedLoanPurposeCategory={setSelectedLoanPurposeCategory}
                selectedLoanPurposeSubCategory={selectedLoanPurposeSubCategory}
                setSelectedLoanPurposeSubCategory={setSelectedLoanPurposeSubCategory}
                setOtherBenefit={setOtherBenefit}/>
            </div>
        </div>
    )
}

const PreStage = ({ type, showLoader, setShowLoader, setLoan, loanAmount, setLoanAmount }) => {
    const { consumer, dispatch } = useAuthContext();
    const [loanPeriod, setLoanPeriod] = useState(10);
    const [selectedLoanPurposeCategory, setSelectedLoanPurposeCategory] = useState(null);
    const [selectedLoanPurposeSubCategory, setSelectedLoanPurposeSubCategory] = useState(null);
    const [validationError, setValidationError] = useState([]);
    const [consumerDetails, setConsumerDetails] = useState({});
    const [count, setCount] = useState(0);
    const [otherBenefit, setOtherBenefit] = useState(null);
    const [place, setPlace] = useState(null);
    const otherBenefitToken = 'lN9O-ijYdJfYx';
    const navigate = useNavigate();
    
    const next = () => {
        const purposeSection = $('.purpose-section');

        if(count === 0) {
            purposeSection.slideToggle();
            setCount((prev) => prev + 1);
        } else if(count === 1) {
            if(type === 'business' || type === 'motorcycle') {
                setCount((prev) => prev + 1);
                $('.define-loan-details').hide();
                $('.consumer-details-section').show();
                window.scrollTo(0, 0);
                return false;
            }

            if(!selectedLoanPurposeCategory){
                setValidationError(validationError ? [...validationError, 'purposeCategory'] : 'purposeCategory');
                return false;
            }

            if(!selectedLoanPurposeSubCategory) {
                setValidationError(validationError ? [...validationError, 'purposeSubCategory'] : ['purposeSubCategory']);
                return false;
            }

            if(selectedLoanPurposeSubCategory.token === otherBenefitToken) {
                if(!otherBenefit) {
                    global.config.methods.warningResponse('You have not entered proper loan purpose.');
                    return false;
                }
            }
            setCount((prev) => prev + 1);
            $('.define-loan-details').hide();
            $('.consumer-details-section').show();
            window.scrollTo(0, 0);
        }else {
            var validation = [];
            const fields = $('.validation-required').toArray();
            if(fields.length > 0){
                for(var i=0;i<fields.length;i++){
                    var id = fields[i].id;
                    var value = consumerDetails[id];
                    if(!consumerDetails[id]) {
                        validation.push(id)
                    } else {
                        if (id === 'email') {
                            //eslint-disable-next-line
                            const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                            const format = regex.test(value);
                            if(!format) {
                                validation.push(id + '-format');
                            }
                        } else if (id === 'emailConfirm') {
                            //eslint-disable-next-line
                            const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                            const format = regex.test(value);
                            if(!format) {
                                validation.push(id + '-format');
                            } else {
                                if (value !== consumerDetails['email']) {
                                    validation.push(id + '-match');
                                }
                            }
                        } else if (id === 'password') {
                            if(value.length < 6 || value > 12) {
                                validation.push(id + '-length');
                            }
                            //eslint-disable-next-line
                            if (!/^(?=.*[a-zA-Z])(?=.*[0-9]).{0,50}$/.test(value)) {
                                validation.push(id + '-format');
                            }
                        } else if (id === 'passwordConfirm') {
                            if(value.length < 6 || value > 12) {
                                validation.push(id + '-length');
                            }
                            //eslint-disable-next-line
                            if (!/^(?=.*[a-zA-Z])(?=.*[0-9]).{0,50}$/.test(value)) {
                                validation.push(id + '-format');
                            }

                            if (value !== consumerDetails['password']) {
                                validation.push(id + '-match');
                            }
                        } else if (id === 'day') {
                            if(isNaN(parseInt(value))) {
                                validation.push(id + '-format');
                            }
                            
                            if(parseInt(value) < 1 || parseInt(value) > 31) {
                                validation.push(id + '-length');
                            }
                        } else if (id === 'month') {
                            if(isNaN(parseInt(value))) {
                                validation.push(id + '-format');
                            }
                            
                            if(parseInt(value) < 1 || parseInt(value) > 12){
                                validation.push(id + '-length');
                            }
                        } else if (id === 'year') {
                            if(isNaN(parseInt(value))) {
                                validation.push(id + '-format');
                            } 

                            if(parseInt(value) < 1900 || parseInt(value) > (new Date()).getFullYear() - 18){
                                validation.push(id + '-format');
                            } 
                        } else if(id === 'mobile') {
                            if(value.length !== 10) {
                                validation.push(id + '-length');
                            } 
                            //eslint-disable-next-line
                            if(!/^04[\d]{8}$/.test(value)) {
                                validation.push(id + '-format');
                            } 
                        }
                        // else if (id === 'creditGuideAccept' || id === 'privacyAccept') {
                        //     if(!e.target.checked) {
                        //         validation.push(id);
                        //     }
                        // } 
                    }
                }
            }

            if(consumerDetails.manualAddress) {
                const index = validation.findIndex(x => x === 'rawAddress');
                if(index !== -1) {
                    validation.splice(index, 1);
                }
            }
            setValidationError(validation);

            var formdata = {...consumerDetails};
            formdata.loanAmount = loanAmount;
            formdata.loanPeriod = loanPeriod;
            formdata.otherBenefit = otherBenefit;
            formdata.loanType = type;
            if(type !== 'business' && type !== 'motorcycle') {
                formdata.purposeCategoryId = selectedLoanPurposeCategory.id;
                formdata.purposeSubCategoryId = selectedLoanPurposeSubCategory.id;
            }
            if(validation.length === 0) {
                setShowLoader(true);
                axios.post('/loans/apply', formdata)
                .then((response) => {
                    setShowLoader(false);
                    if(response.status === 200) {
                        const loanToken = response.data.data.loanToken;
                        if(!consumer) {
                            localStorage.setItem('consumer', JSON.stringify(response.data.data.consumer));
                            dispatch({type: 'LOGIN', payload: response.data.data.consumer});
                        }
                        setLoan(response.data.data.loan);
                        navigate('/loans/' + loanToken);
                    }
                })
                .catch((err) => {
                    setShowLoader(false);
                    if(err.response?.data?.validationError) {
                        setValidationError(err.response?.data?.validationError);
                    }else{
                        const error = err.response?.data?.error ? err.response?.data?.error : err.message;
                        global.config.methods.errorResponse(error);
                    }
                })
            }
        }
    }

    useEffect(() => {
        if(type === 'business') {
            setLoanAmount(50000);
            setLoanPeriod(3);
        } else if(type === 'motorcycle') {
            setLoanAmount(9000);
            setLoanPeriod(2);
        }

        document.querySelector('script.src');
        var script = document.createElement('script');
        script.src = 'https://maps.googleapis.com/maps/api/js?key=' + global.config.properties.googleKey + '&libraries=places';
        document.body.appendChild(script);
        script.addEventListener('load', () => {
            var autocomplete = new window.google.maps.places.Autocomplete(
                (document.getElementById('rawAddress')),
                {types: ['address']}
            );
            setPlace(autocomplete);
        });

        return () => script.remove();
    }, []);

    return (
        <>
            {
                type === 'sacc' ? 
                <SaccPrestage 
                loanAmount={loanAmount}
                setLoanAmount={setLoanAmount}
                loanPeriod={loanPeriod}
                setLoanPeriod={setLoanPeriod}
                validationError={validationError}
                setValidationError={setValidationError}
                selectedLoanPurposeCategory={selectedLoanPurposeCategory}
                setSelectedLoanPurposeCategory={setSelectedLoanPurposeCategory}
                selectedLoanPurposeSubCategory={selectedLoanPurposeSubCategory}
                setSelectedLoanPurposeSubCategory={setSelectedLoanPurposeSubCategory}
                setOtherBenefit={setOtherBenefit}
                /> :
                type === 'motorcycle' ?
                <MotorPrestage 
                loanAmount={loanAmount}
                setLoanAmount={setLoanAmount}
                loanPeriod={loanPeriod}
                setLoanPeriod={setLoanPeriod}/> :
                type === 'business' && 
                <BusinessPrestage 
                loanAmount={loanAmount}
                setLoanAmount={setLoanAmount}
                loanPeriod={loanPeriod}
                setLoanPeriod={setLoanPeriod}/>
            }
            {
                consumer ? 
                <ReturnConsumer 
                validationError={validationError}
                setValidationError={setValidationError}
                consumerDetails={consumerDetails} 
                setConsumerDetails={setConsumerDetails}/>
                :
                <NewConsumer 
                place={place}
                validationError={validationError}
                setValidationError={setValidationError}
                consumerDetails={consumerDetails} 
                setConsumerDetails={setConsumerDetails}/>
            }
            {
                !showLoader && (
                    <Next callback={next}/>
                )
            }
        </>
    )
}

export default PreStage;

