import axios from "axios";
import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useAuthContext } from "../../hooks/auth";
import moment from "moment";
import { Spinner } from "react-bootstrap";

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const { dispatch } = useAuthContext();
    const navigate = useNavigate();
    const { state } = useLocation();

    const loginConsumer = (e) => {
        setLoading(true);
        e.preventDefault();
        
        if(username === '') {
            setLoading(false);
            setError('Email cannot be empty.');
            window.scrollTo(0, 0);

            return false;
        }

        if(password === ''){
            setLoading(false);
            setError('Password cannot be empty.');
            window.scrollTo(0, 0);
            return false;
        }

        var formdata = new FormData();
        formdata.append('username', username);
        formdata.append('password', password);
        setLoading(true);

        axios.post('/consumers/login', formdata)
        .then((response) => {
            if(response.status === 200) {
                setLoading(false);
                var consumer = response.data.consumer;
                var bearer = response.data.token;
                var jwtExpiry = moment().add(1, 'hours');
                consumer.accessToken = {
                    token: bearer,
                    expiredAt: jwtExpiry
                };
                localStorage.setItem('consumer', JSON.stringify(consumer));
                dispatch({type: 'LOGIN', payload: consumer});
                navigate('/members/dashboard');
            }
        })
        .catch((err) => {
            window.scrollTo(0, 0);
            setLoading(false);
            const error = err.response?.data?.error ? err.response.data.error : err.message;
            setError(error);
        })
    }
    
    useEffect(() => {
        if(state){
            setError(state.error);
            window.history.replaceState({}, document.title);
        }
        // eslint-disable-next-line
    }, [state]);

    return (
        <div className="container public-container py-4">
            <div className="row">
                {
                    error && (
                        <div className="alert alert-danger">
                            {error}
                        </div>
                    )
                }
                <div className="col-md-3"></div>
                    <div className="col-md-6">
                        <div className="card my-5">
                            <div className="card-body bg-light">
                                <h2 className="card-title text-left">Log In</h2>
                                <form id="login_form" onSubmit={loginConsumer}>
                                    <div className="form-group">
                                        <label htmlFor="username">Username</label>
                                        <input className="form-control" id="username" onChange={(e) => {setUsername(e.target.value)}}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password">Password</label>
                                        <input type="password" className="form-control" id="password" onChange={(e) => {setPassword(e.target.value)}}/>
                                    </div>

                                    <div className="form-group form-check mb-3">
                                        <input type="checkbox" className="form-check-input"/>
                                        <label htmlFor="remember" className="form-check-label">Remember Me</label>
                                        <Link to={"/forgot-password"} className="float-end" style={{ textDecoration: 'none' }}>Forgot my password</Link>
                                    </div>
                                    {
                                        loading ? 
                                        <div className="text-center btn btn-block btn-primary w-100">
                                            <span>Loading </span>
                                            <Spinner animation="border" role="status" size="sm" style={{ fontSize: '8px', marginLeft: '5px' }}>
                                                <span className="visually-hidden">loading...</span>
                                            </Spinner>
                                        </div>
                                        : 
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-block btn-primary w-100">Log In</button>
                                        </div>
                                    }
                                </form>
                            </div>
                        </div>
                    </div>
                <div className="col-md-3"></div>
            </div>
        </div>
    )
}

export default Login