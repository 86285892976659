import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { useAuthContext } from '../../hooks/auth';
import axios from 'axios';
import { Next } from './next';
import $ from 'jquery';
import { Spinner } from 'react-bootstrap';

const EmergencyContact = ({ 
    loan,
    setLoan,
    loanCriteria, 
    formData, 
    setFormData, 
    validationError, 
    setValidationError }) => {

    const validateFields = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        if(value){
            const index = validationError.findIndex(x => x === id);
            if (index !== -1) {
                validationError.splice(index, 1);
                setValidationError([...validationError]);
            } 
        }else{
            if(!validationError.includes(id)){
                validationError.push(id);
                setValidationError([...validationError]);
            }
        }
        setFormData(prev => ({...prev, [id]: value}));
    };

    useEffect(() => {
        if(loan.applications.emergencyContactNumber) {
            setFormData(prev => ({...prev, emergencyContactNumber: loan.applications.emergencyContactNumber.value}));
        }

        if(loan.applications.emergencyContactName) {
            setFormData(prev => ({...prev, emergencyContactName: loan.applications.emergencyContactName.value}));
        }
    }, [loan])

    return (
        <div className="emergency-contact-form card form mb-4">
            <div className="card-body">
                <h3 className="card-title text-center">Emergency Contact</h3>
                <p className="card-title text-center">Please provide an emergency contact (Such as Next of Kin etc).</p>
                <div className="row">
                    {
                        loanCriteria && loanCriteria.filter(x => x.divId === 'emergencyContact').map((crit) => {
                            return (
                                <div className="col-sm-6" key={crit.id}>
                                    <div className="form-group">
                                        <label htmlFor={crit.formId}>
                                            <span>{ crit.question }</span>
                                            <FontAwesomeIcon icon={'fas fa-asterisk fa-xs'} className="required-star"/>
                                        </label>
                                        {
                                            crit.type.type === 'text' ? 
                                            crit.token === 'PJDitpAO' ? 
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <div className="input-group-text">04</div>
                                                </div>
                                                <input type="text" 
                                                id={crit.formId}
                                                className={"form-control criteriaForm " + (crit.isRequired ? "validation-required" : "")}
                                                data-token={crit.token}
                                                maxLength={crit.digit}
                                                placeholder={crit.placeholder || ''}
                                                required={crit.isRequired ? true : false}
                                                defaultValue={loan.applications.emergencyContactNumber?.value}
                                                onChange={validateFields}
                                                style={{ borderColor: validationError && validationError.includes(crit.formId) ? 'red' : '' }}/>
                                                {
                                                    validationError && validationError.includes('emergencyContactNumber') && (
                                                        <div className="invalid-feedback show-error">Must be answered.</div>
                                                    )
                                                }
                                            </div>
                                            :
                                            <>
                                                <input type="text" 
                                                id={crit.formId}
                                                className={"form-control criteriaForm " + (crit.isRequired ? "validation-required" : "")}
                                                data-token={crit.token}
                                                maxLength={crit.digit}
                                                placeholder={crit.placeholder || ''}
                                                required={crit.isRequired ? true : false}
                                                defaultValue={loan.applications[crit.formId]?.value}
                                                style={{ borderColor: validationError && validationError.includes(crit.formId) ? 'red' : '' }}
                                                onChange={validateFields}/>
                                                {
                                                    validationError && validationError.includes(crit.formId) && (
                                                        <div className="invalid-feedback show-error">Must be answered.</div>
                                                    )
                                                }
                                            </>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

const EmploymentDetails = ({ 
    loan, 
    setLoan, 
    loanCriteria, 
    formData, 
    setFormData, 
    validationError, 
    setValidationError }) => {
    const employmentTypes = {
        'Full Time': '4ADk69ij-9uSw',
        'Part Time': '4ADk69ij-96mN',
        'Self Employed': '4ADk69ij-3QCk',
        'Contractor': '4ADk69ij-M9Vg',
        'Temporary': '4ADk69ij-rSDZ',
        'Pension': '4ADk69ij-vefW',
        'Centerlink': '4ADk69ij-dkl3'
    };

    const paymentCycles = {
        Monthly: '9SOC3vcL-Y9O4',
        Fortnightly: '9SOC3vcL-gdPJ',
        Weekly: '9SOC3vcL-Y92M'
    }

    const paydays = {
        Monday: 0,
        Tuesday: 1,
        Wednesday: 2,
        Thursday: 3,
        Friday: 4,
        Saturday: 5,
        Sunday: 6
    };

    const validateFields = (e) => {
        const id = e.target.id;
        const value = e.target.value;

        if(value){
            const index = validationError.findIndex(x => x === id);
            if (index !== -1) {
                validationError.splice(index, 1);
                setValidationError([...validationError]);
            } 
        }else{
            if(!validationError.includes(id)){
                validationError.push(id);
                setValidationError([...validationError]);
            }
        }
        setFormData(prev => ({...prev, [id]: value}));
    };

    const addNewIncome = () => {
        axios.post('/loans/' + loan.token + '/income')
        .then((response) => {
            if(response.status === 200) {
                loan.applications.employmentDetails.push(response.data.data.app);
                setLoan(prev => ({...prev, loan}));
            } 
        })
        .catch((err) => {
            const error = err.response?.data?.error ? err.response?.data?.error : err.message;
            global.config.methods.errorResponse(error);
        })
    }

    const removeIncome = (e) => {
        const target = $(e.currentTarget);
        const index = target.attr('data-index');
        const token = target.attr('data-income');

        axios.delete('/loans/' + loan.token + '/income/' + token)
        .then((response) => {
            if(response.status === 200){
                loan.applications.employmentDetails.splice(index, 1);
                setLoan(prev => ({...prev, loan}));
            }
        })
        .catch((err) => {
            const error = err.response?.data?.error ? err.response?.data?.error : err.message;
            global.config.methods.errorResponse(error);
        })
    }

    useEffect(() => {
        if(loan.applications.employmentDetails && 
            loan.applications.employmentDetails.length > 0) {
                for(var i=0;i<loan.applications.employmentDetails.length;i++){
                    const data = loan.applications.employmentDetails[i];
                    setFormData(prev => ({...prev, ['employmentType-' + data.token]: data.value.employmentType}));
                    setFormData(prev => ({...prev, [data.token + '-amount']: data.value.amount}));
                    setFormData(prev => ({...prev, [data.token + '-paymentCycle']: data.value.paymentCycle}));
                    setFormData(prev => ({...prev, [data.token + '-paymentDay']: data.value.paymentDay}));
                    setFormData(prev => ({...prev, [data.token + '-companyName']: data.value.companyName}));
                }
        }

        if(loan.applications.moreGovernmentBenefit) {
            const criteria = loanCriteria.find(x => x.id === loan.applications.moreGovernmentBenefit.criteriaId);
            setFormData(prev => ({...prev, [criteria?.formId]: loan.applications.moreGovernmentBenefit.value}))
        }
    }, [loan])

    return (
        <div className={"card form mb-4"} id="yourIncome">
            <div className="card-body">
                <h3 className="card-title text-center">Your Employment Details</h3>
                <p className="card-title text-center">As responsible lenders we need to check that your loan is affordable for you</p>

                <div className="card mb-3">
                    <div className="card-header">
                        Your income
                    </div>
                    <div className="card-body income">
                        {
                            loan.applications.employmentDetails && (
                                loan.applications.employmentDetails.map((employment, index) => {
                                    return (
                                    <div 
                                    className="border-bottom mb-2 added employment-div" 
                                    id={'employment-' + employment.token}
                                    key={index}>
                                        {
                                            index > 0 && (
                                                <div className="text-end">
                                                    <button 
                                                    className="btn btn-sm remove" 
                                                    data-income={employment.token} 
                                                    data-index={index} 
                                                    onClick={removeIncome}>
                                                        <FontAwesomeIcon icon={'far fa-trash-alt'} className="text-danger" data-income={employment.token} data-index={index}/>
                                                    </button>
                                                </div>
                                            )
                                            
                                        }
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <div className="form-group employmentDetails">
                                                    <label htmlFor={'employmentType-' + employment.token}>
                                                        <span>Type</span> 
                                                        <FontAwesomeIcon icon={'fas fa-asterisk'} className="fa-xs required-star"/>
                                                    </label>
                                                    <select id={'employmentType-' + employment.token} 
                                                    className="form-control employmentType validation-required" 
                                                    defaultValue={employment.value.employmentType}
                                                    data-index={index}
                                                    style={{ borderColor: validationError && validationError.includes('employmentType-' + employment.token) && 'red' }}
                                                    onChange={
                                                        (e) => {
                                                            validateFields(e);
                                                            loan.applications.employmentDetails[index].value.employmentType = e.target.value;
                                                            setLoan({...loan})
                                                            setFormData(prev => ({...prev, [e.target.id]: e.target.value}));
                                                        }
                                                    } required>
                                                        <option value={''}>Select</option>
                                                        {
                                                            Object.keys(employmentTypes).map((key) => {
                                                                return (
                                                                    <option value={employmentTypes[key]} key={key}>{ key }</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {
                                                        validationError && validationError.includes('employmentType-' + employment.token) && (
                                                            <div className="invalid-feedback show-error">Must be answered.</div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="form-group">
                                                    <label htmlFor="{{app.token}}-amount">
                                                        <span>Amount</span> 
                                                        <FontAwesomeIcon icon={'fas fa-asterisk'} className="fa-xs required-star"/>
                                                    </label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text">$</div>
                                                        </div>
                                                        <input type="number" id={employment.token + '-amount'} 
                                                        className="form-control text-end amount validation-required"
                                                        value={parseFloat(employment.value.amount)}
                                                        min={0}
                                                        max={9999999999}
                                                        data-index={index}
                                                        style={{ borderColor: validationError && validationError.includes(employment.token + '-amount') && 'red' }}
                                                        onChange={
                                                            (e) => {
                                                                validateFields(e);
                                                                loan.applications.employmentDetails[index].value.amount = parseFloat(e.target.value);
                                                                setLoan({...loan});
                                                                setFormData(prev => ({...prev, [e.target.id]: parseFloat(e.target.value)}));
                                                            }
                                                        }
                                                        required/>
                                                        {
                                                            validationError && validationError.includes(employment.token + '-amount') && (
                                                                <div className="invalid-feedback show-error">Must be answered.</div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="form-group">
                                                    <label htmlFor={employment.token + '-paymentCycle'}>
                                                        <span>Cycle</span> 
                                                        <FontAwesomeIcon icon={'fas fa-asterisk'} className="fa-xs required-star"/>
                                                    </label>
                                                    <select 
                                                    className="form-control paymentcycle validation-required" 
                                                    id={employment.token + '-paymentCycle'}
                                                    defaultValue={employment.value.paymentCycle}
                                                    data-index={index}
                                                    style={{ borderColor: validationError && validationError.includes(employment.token + '-paymentCycle') && 'red'}}
                                                    onChange={
                                                        (e) => {
                                                            validateFields(e);
                                                            loan.applications.employmentDetails[index].value.paymentCycle = e.target.value;
                                                            setLoan({...loan});
                                                            setFormData(prev => ({...prev, [e.target.id]: e.target.value}));
                                                        }
                                                    }
                                                    required>
                                                        <option value={''}>Select</option>
                                                        {
                                                            Object.keys(paymentCycles).map((key) => {
                                                                return (
                                                                    <option value={paymentCycles[key]} key={key}>{ key }</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {
                                                        validationError && validationError.includes(employment.token + '-paymentCycle') && (
                                                            <div className="invalid-feedback show-error">Must be answered.</div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="form-group">
                                                    <label htmlFor={employment.token + '-paymentDay'}>
                                                        <span>Pay Day</span> 
                                                        <FontAwesomeIcon icon={'fas fa-asterisk'} className="fa-xs required-star"/>
                                                    </label>
                                                    <select 
                                                    id={employment.token + '-paymentDay'} 
                                                    className="form-control paymentDay validation-required" 
                                                    defaultValue={employment.value.paymentDay}
                                                    data-index={index}
                                                    style={{ borderColor: validationError && validationError.includes(employment.token + '-paymentDay') && 'red' }}
                                                    onChange={
                                                        (e) => {
                                                            validateFields(e);
                                                            loan.applications.employmentDetails[index].value.paymentDay = e.target.value;
                                                            setLoan({...loan});
                                                            setFormData(prev => ({...prev, [e.target.id]: e.target.value}));
                                                        }
                                                    }
                                                    required>
                                                        <option value={''}>Select</option>
                                                        {
                                                            Object.keys(paydays).map((day, key) => {
                                                                return (
                                                                    <option value={key} key={key}>{day}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {
                                                        validationError && validationError.includes(employment.token + '-paymentDay') && (
                                                            <div className="invalid-feedback show-error">Must be answered.</div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            employment.value.employmentType && employment.value.employmentType !== '4ADk69ij-dkl3' && (
                                                <div className="employed-detail">
                                                    <div className="form-group">
                                                        <label htmlFor={employment.token + '-companyName'}>
                                                            Company Name 
                                                            <FontAwesomeIcon icon={'fas fa-asterisk'} className="fa-xs required-star"/>
                                                        </label>
                                                        <input id={employment.token + '-companyName'} 
                                                        className="form-control companyName validation-required" 
                                                        defaultValue={employment.value.companyName} 
                                                        data-income={employment.token}
                                                        data-index={index}
                                                        style={{ borderColor: validationError && validationError.includes(employment.token + '-companyName') && 'red' }}
                                                        onChange={
                                                            (e) => {
                                                                validateFields(e);
                                                                loan.applications.employmentDetails[index].value.companyName = e.target.value;
                                                                setLoan({...loan});
                                                                setFormData(prev => ({...prev, [e.target.id]: e.target.value}));
                                                            }
                                                        }/>
                                                        {
                                                            validationError && validationError.includes(employment.token + '-companyName') && (
                                                                <div className="invalid-feedback show-error">Must be answered</div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>)
                                })
                            ) 
                        }
                        <div className="text-end">
                            <button className="btn btn-primary add-another-income" onClick={addNewIncome}>
                                <FontAwesomeIcon icon={'far fa-plus-square'}/>
                                <span>Add another Income</span>
                            </button>
                        </div>
                    </div>
                </div>
                {
                    loanCriteria && loanCriteria.filter(x => x.divId === 'incomeExtra').map((crit) => {
                        return (
                            <div className="form-group row" key={crit.id}>
                                <label htmlFor={crit.formId} className="col-sm-9 col-form-label">
                                    <span>{ crit.question }</span>
                                    <FontAwesomeIcon icon={'fas fa-asterisk'} className="fa-xs required-star"/>
                                </label>
                                <div className="col-sm-3">
                                    {
                                        crit.type.type === 'text' ? 
                                        <input type="text" id={crit.formId} className="form-control criteriaForm validation-required" data-token={crit.token}/>
                                        :
                                        crit.type.type === 'number' ? 
                                        <input type="number" id={crit.formId} className="form-control criteriaForm validation-required" data-token={crit.token}/>
                                        :
                                        crit.type.type === 'select' ?
                                            crit.type.isYN ? 
                                            <>
                                                <select 
                                                id={crit.formId} 
                                                className="form-control criteriaForm validation-required" 
                                                style={{ borderColor: validationError && validationError.includes(crit.formId) ? 'red' : '' }}
                                                data-token={crit.token}
                                                defaultValue={loan.applications.moreGovernmentBenefit?.value}
                                                onChange={validateFields}>
                                                    <option value={''}>Select</option>
                                                    <option value={1}>Yes</option>
                                                    <option value={2}>No</option>
                                                </select>
                                                {
                                                    validationError && validationError.includes(crit.formId) && (
                                                        <div className="invalid-feedback show-error">Must be answered.</div>
                                                    )
                                                }
                                            </>
                                            :
                                            <div>{ crit.answers }</div>
                                        : null
                                    }
                                </div>
                            </div>
                        )
                    }) 
                }
            </div>
        </div>
    )
}

const StageOne = ({ 
    loan, 
    setLoan, 
    loanCriteria, 
    setLoanCriteria, 
    showLoader, 
    setShowLoader, 
    getLoanDetails 
}) => {
    const [error, setError] = useState(null);
    const { consumer } = useAuthContext();
    const { loanToken } = useParams();
    const [verification, setVerification] = useState(false);
    const [code, setCode] = useState(null);
    const [validationError, setValidationError] = useState([]);
    const [formData, setFormData] = useState({});
    const [countDown, setCountDown] = useState(null);
    const [timeout, setTimeOut] = useState(null);
    const [sending, setSending] = useState(false);
    const [verifying, setVerifying] = useState(false);

    const getCode = () => {
        setSending(true);
        axios.get('/loans/' + loanToken + '/code')
        .then((response) => {
            setSending(false);
            if(response.status === 200) {
                setError(null);
                setVerification(true);
                setCountDown(Math.round(response.data.data.expiredIn));
            }
        })
        .catch((err) => {
            setSending(false);
            if(err.response?.data?.expiredIn) {
                setVerification(true);
                setCountDown(Math.round(err.response.data.expiredIn));
            }
            const error = err.response?.data?.error ? err.response?.data?.error : err.message;
            setError(error);
        })
    }

    const verifyCode = () => {
        setVerifying(true);
        axios.post('/loans/' + loan.token + '/code/verify', {code: code})
        .then((response) => {
            setVerifying(false);
            if(response.status === 200) {
                loan.isVerifiedMobile = 1;
                setLoan({...loan});
                setError(null);
            }
        })
        .catch((err) => {
            setVerifying(false);
            const error = err.response?.data?.error ? err.response?.data?.error : err.message;
            setError(error);
        })
    }

    const next = () => {
        if(loan.isVerifiedMobile === 0) {
            setError('Mobile has to be verified first.');
            return false
        }

        const requiredFields = $(".validation-required").toArray();
        if(requiredFields.length > 0){
            for(var i=0;i<requiredFields.length;i++) {
                const id = requiredFields[i].id;
                if(!formData[requiredFields[i].id]){
                    if(!validationError.includes(id)){
                        validationError.push(id);
                        setValidationError([...validationError]);
                    } 
                }
            }

            if(validationError.length === 0){
                setShowLoader(true);
                axios.post('/loans/' + loan.token + '/manage', formData)
                .then(() => {
                    setShowLoader(false);
                    getLoanDetails();
                })
                .catch((err) => {
                    setShowLoader(false);
                    const error = err.response?.data?.error ? err.response?.data.error : err.message;
                })
            }
        }
    }

    useEffect(() => {
        clearInterval(timeout);
        if(countDown && countDown !== 0) {
            const timer = setTimeout(() => {
                setCountDown(prev => (parseInt(prev) - 1))
            }, 1000);
            setTimeOut(timer);
        } else {
            setVerification(false);
        }
    }, [countDown])

    return (
        <div className="step1 application-step position-relative mb-4" id="applicationStep1">
            {
                error && (
                    <div className="alert alert-danger">{ error }</div>
                )
            }
            <div className="card form mb-4" id="mobileVerification">
                <div className="card-body">
                    <h3 className="card-title text-center">Mobile number verification via SMS</h3>
                    {
                        loan?.isVerifiedMobile ? 
                        <p className="card-text text-center">Your mobile number is verified.</p>
                        :
                        <>
                            <div className="row mobile-verification">
                                <div className="col-sm mobile-number" style={{ transition: '0.5s ease' }}>
                                    <div className="row">
                                        <div className="col-7">
                                            <div className="form-group">
                                                <label htmlFor="">Mobile Number</label>
                                                <input className="form-control" defaultValue={consumer?.address?.mobile} disabled required/>
                                            </div>
                                        </div>
                                        <div className="col-5">
                                            <button 
                                            className="btn btn-primary btn-block send-sms" 
                                            data-loading="Sending..."
                                            style={{ marginTop: '27px', width: 'auto' }} 
                                            onClick={getCode}
                                            disabled={sending}>
                                                {
                                                    sending ? 
                                                    <div className="text-center">
                                                        <span>Sending</span>
                                                        <Spinner animation="border" role="status" size="sm" style={{ fontSize: '8px', marginLeft: '5px' }}>
                                                            <span className="visually-hidden">loading...</span>
                                                        </Spinner>
                                                    </div>
                                                    : 
                                                    <span>Get SMS</span>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <small className="text-muted">
                                            <FontAwesomeIcon icon={'fas fa-question-circle'}/>
                                            Need to update your mobile number?
                                        <Link to={'/members/edit'}>Edit Profile <FontAwesomeIcon icon={'fas fa-link'}/></Link>
                                        </small>
                                    </div>
                                </div>
                                {
                                    verification && (
                                        <div className="col-sm verify-mobile">
                                            <div className="row">
                                                <div className="col-7">
                                                    <div className="form-group">
                                                        <label htmlFor="verificationCode">Verification</label>
                                                        <input 
                                                        type="text" 
                                                        id="verificationCode" 
                                                        className="form-control verificationCode" 
                                                        maxLength={4} 
                                                        onChange={(e) => {setCode(e.target.value)}}
                                                        required/>
                                                        {
                                                            countDown && countDown !== 0 && (
                                                                <small className="form-text text-danger text-end verificationExpired" >
                                                                    expires in <span className="countdown">{ countDown }</span> sec
                                                                </small>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-5">
                                                    <button 
                                                    className="btn btn-primary btn-block verify" 
                                                    data-loading="Verifing..." 
                                                    style={{ marginTop: '27px' }}
                                                    onClick={verifyCode}
                                                    disabled={verifying}>
                                                        {
                                                            verifying ? 
                                                            <div className="text-center">
                                                                <span>Verifying</span>
                                                                <Spinner animation="border" role="status" size="sm" style={{ fontSize: '8px', marginLeft: '5px' }}>
                                                                    <span className="visually-hidden">loading...</span>
                                                                </Spinner>
                                                            </div>
                                                            : 
                                                            <span>Verify</span>
                                                        }
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            <p className="card-text text-center mobile-verification-success" style={{ display: 'none' }}>Your mobile number has been verified.</p>
                        </>
                    }
                </div>
            </div>
            {
                loan?.isVerifiedMobile ? (
                    <>
                        <EmergencyContact 
                        loan={loan}
                        setLoan={setLoan}
                        loanCriteria={loanCriteria} 
                        formData={formData} 
                        setFormData={setFormData}
                        validationError={validationError}
                        setValidationError={setValidationError}/>
                        <EmploymentDetails 
                        loan={loan} 
                        setLoan={setLoan} 
                        loanCriteria={loanCriteria} 
                        formData={formData} 
                        setFormData={setFormData}
                        validationError={validationError}
                        setValidationError={setValidationError}/>
                    </>
                ) : null
            }
            <Next callback={next}/>
        </div>
    )
}

export default StageOne