import $ from 'jquery';
import axios from 'axios';
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Illion = ({
    loan,
    setInitiateWhileLoop
}) => {
    var interval_id,
    last_hash,
    cache_bust = 1,
    attached_callback;

    useEffect(() => {
        let script = document.createElement('script');
        script.setAttribute('class', 'embed-script-illion');
        script.src = 'https://www.bankstatements.com.au/js/iframeResizer.min.js';
        script.onload = (e) => {
            initializeIframe(loan.token);
        }
        document.querySelector('body').append(script);
    }, []);

    const postMessage = (message, target_url, target) => {
        if (!target_url) {
            return;
        }

        // target = target;  // default to parent
        if (window['postMessage']) {
            // the browser supports window.postMessage, so call it with a targetOrigin
            // set appropriately, based on the target_url parameter.
            target['postMessage'](message, target_url.replace(/([^:]+:\/\/[^\/]+).*/, '$1'));
        } else if (target_url) {
            // the browser does not support window.postMessage, so use the window.location.hash fragment hack
            target.location = target_url.replace(/#.*$/, '') + '#' + (+new Date) + (cache_bust++) + '&' + message;
        }
    }

    const receiveMessage = (callback, source_origin) => {
        if (window['postMessage']) {
            // bind the callback to the actual event associated with window.postMessage
            if (callback) {
                attached_callback = function (e) {
                    if ((typeof source_origin === 'string' && e.origin !== source_origin)
                        || (Object.prototype.toString.call(source_origin) === "[object Function]" && source_origin(e.origin) === !1)) {
                        return !1;
                    }
                    callback(e);
                };
            }
            if (window['addEventListener']) {
                window[callback ? 'addEventListener' : 'removeEventListener']('message', attached_callback, !1);
            } else {
                window[callback ? 'attachEvent' : 'detachEvent']('onmessage', attached_callback);
            }
        } else {
            // a polling loop is started & callback is called whenever the location.hash changes
            interval_id && clearInterval(interval_id);
            interval_id = null;
            if (callback) {
                interval_id = setInterval(function () {
                    var hash = document.location.hash,
                        re = /^#?\d+&/;
                    if (hash !== last_hash && re.test(hash)) {
                        last_hash = hash;
                        callback({data: hash.replace(re, '')});
                    }
                }, 100);
            }
        }
    }

    const initializeIframe = (loanToken) => {
        $('#bankStmIframe').css({
            display: 'block'
        }).html('<iframe id="illionIFrame" src="'+process.env.REACT_APP_ILLION_URL+'/iframe/start/'+process.env.REACT_APP_ILLION_LENDERCODE+'-' + loanToken + '" style="width: 100%" onload="iFrameResize([{log:false}])" scrolling="no"></iframe>');

        receiveMessage((event) => {
            try {
                if (typeof(event.data) === 'undefined'){
                    throw new Error('event data is undefined');
                }

                if (typeof event.data === 'string' && event.data.includes('[iFrameSizer]')) {
                    //filter out the messages from the iFrame resizer 
                    // that are used to dynamically resize the iframe height.
                    throw new Error('ignore event');
                }
                let msg_data = typeof event.data !== 'object' ? JSON.parse(event.data) : event.data;
                if (typeof(msg_data.event) == 'undefined' || !msg_data.event){
                    throw new Error('data event is invalid');
                }

                axios.post('/bankstatement/log?type=2', {subject: msg_data.event, data: msg_data})

                if (typeof(msg_data.status) === 'undefined' || msg_data.status !== 'success'){
                    throw new Error('Skip');
                }

                switch (msg_data.event){
                    case 'terms_accepted':
                        break;
                    case 'bank_selected':
                        break;
                    case 'login_clicked':
                        break;
                    case 'accounts_selected':
                        break;
                    case 'submission_complete':
                        setInitiateWhileLoop(true);
                        // sendingBankDetails();
                        // onApplicationSuccess();
                        break;
                    case 'submit_all':
                        // {# Bank feeds - multiple bank #}
                        //sendingBankDetails();
                        //onApplicationSuccess();
                        break;
                    default:
                        break;
                }
            } catch (err) {
                // console.log(err)
            }
        }, (origin) => {
            let urlobj = new URL(origin);
            let host_allow_arr = [
                'scv-test.bankstatements.com.au', 
                'scv.bankstatements.com.au',
                'test.bankstatements.com.au', 
                'www.bankstatements.com.au', 
                'localhost'
            ];
            return host_allow_arr.includes(urlobj.hostname);
        });
    }

    return (
        <div className="step2 application-step position-relative mb-4" id="applicationStep2">
            <div className="card form mb-4">
                <div className="card-body">
                    <h3 className="card-title text-center">Your bank statement</h3>
                    <p className="card-subtitle mb-3 text-muted text-center">To fast track your application we calculate your income and expenses automatically by connecting to your bank.</p>
    
                    <div className="card mb-3" id="creditSense" style={{ minHeight: '459px' }}>
                        <div className="card-body">
                            <div id="bankStmIframe"></div>
                            <div id="bankStmUploading" className="d-none">
                                <div className="page-header">
                                    <h1 className="text-success" style={{ marginBottom: '30px' }}>
                                        Uploading bank statement
                                    </h1>
                                    <div>
                                        <em>
                                            Please wait few minutes for the collection process to complete.
                                            <br/>
                                            Please <a id="bankStmContinue" href="" className="text-info" style={{ fontWeight: 'bold' }}>click here</a> to continue.
                                        </em>
                                    </div>
                                </div>
    
                                <div className="d-none">
                                    <p className="lead ng-binding">
                                        Bank account statement:
                                        <span style={{ marginLeft: '10px' }} retries="0">
                                            <span className="BankResultLabel success">
                                                <FontAwesomeIcon icon={'icon-ok'} style={{ marginTop: '6px' }}/>
                                                success 
                                            </span>
                                        </span>
                                        <br />
                                    </p>
                                    <div><em>Please wait few minutes for the collection process to complete.</em></div>
                                </div>
                            </div>
                            <div id="bankStmError d-none" className="d-none">
                                <div className="page-header">
                                    <h1 id="bankStmErrorHeader" className="text-danger" style={{ marginBottom: '30px' }}>
                                        Sorry, something went wrong
                                    </h1>
                                    <div id="bankStmErrorMsg"><em>Please <a id="bankStmRetry" href="" className="text-info" style={{ fontWeight: 'bold' }}>click here</a> to try again.</em></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Illion