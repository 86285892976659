/* eslint-disable */ 
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';

const CreditSense = ({
    loan,
    setInitiateWhileLoop
}) => {
    const [ready, setReady] = useState(false);
    const intervalIDRef = React.useRef(null);
    const intervalJqueryRefID = React.useRef(null);
    const [whileLoop, setWhileLoop] = useState(false);

    useEffect(() => {
        var jqueryScript = document.createElement('script');
        jqueryScript.src = 'https://ajax.googleapis.com/ajax/libs/jquery/1.12.4/jquery.min.js';
        document.querySelector('body').append(jqueryScript);
        setWhileLoop(true);
    }, []);

    const setUpCreditSense = () => {
        let script = document.createElement('script');
        script.setAttribute('class', 'embed-script-creditsense');
        script.src = 'https://6dadc58e31982fd9f0be-d4a1ccb0c1936ef2a5b7f304db75b8a4.ssl.cf4.rackcdn.com/CS-Integrated-Iframe-v1.min.js';
        script.onload = function (e) {
            intervalIDRef.current = setInterval(() => {
                if(!ready) {
                    initializeIframe(loan.token);
                }
            }, 5000)
        }
        document.querySelector('body').append(script);
    }

    useEffect(() => {
        if(ready) {
            clearInterval(intervalIDRef.current);
            intervalIDRef.current = null;
        }
    }, [ready]);

    useEffect(() => {
        intervalJqueryRefID.current = setInterval(() => {
            if(jQuery) {
                clearInterval(intervalJqueryRefID.current);
                setUpCreditSense();
            }
        }, 1000);
        return () => clearInterval(intervalJqueryRefID.current)
    }, [whileLoop])

    const initializeIframe = (loanToken) => {
        $('#bankStmIframe')
        .css({display: 'block'})
        .html('<iframe id="creditSenseIFrame" src="about:blank" style="height: 580px; width:98%; border: none">iframes are not supported in this browser</iframe>');
        
        jQuery?.CreditSense?.Iframe({
            client: process.env.REACT_APP_CREDIT_SENSE_CODE,
            elementSelector: '#creditSenseIFrame',
            enableDynamicHeight: true,
            params: {
                appRef: loanToken, // Set this value to a unique reference for the application
                uniqueAppRef: true, // true // indicates that the appRef provided is unique
                centrelink: 'skip',
                termsBeforeCredentials: true,
                debugBanks: true
            },
            __custom: {
                showLoading: false,
                callbackDisabled: false,
            },
            callback: function(response, data) {
                if (isNaN(response)){
                    return;
                }

                if(response && response !== 0) {
                    setReady(true);
                }

                if (this.__custom.callbackDisabled){
                    return;
                }
                
                if (!['0', '-1', '2'].includes(response)){
                    axios.post('/bankstatement/log?type=1', {subject: response})
                    .then((res) => {
                    })
                    .catch((err) => {
                    })
                }
                

                if (response == '99' || 
                response == '100'){
                    if (!this.__custom.showLoading){
                        this.__custom.showLoading = true;
                        sendingBankDetails();
                    }

                    if (response == '100'){
                        this.__custom.callbackDisabled = true;
                        setInitiateWhileLoop(true);
                    }
                } else {
                    if (this.__custom.showLoading){
                        this.__custom.showLoading = false;
                        sendingBankDetails(false);
                    }
                }
            }
        });
    }

    return (
        <div className="step2 application-step position-relative mb-4" id="applicationStep2">
            <div className="card form mb-4">
                <div className="card-body">
                    <h3 className="card-title text-center">Your bank statement</h3>
                    <p className="card-subtitle mb-3 text-muted text-center">To fast track your application we calculate your income and expenses automatically by connecting to your bank.</p>

                    <div className="card mb-3" id="creditSense" style={{ minHeight: "459px" }}>
                        <div className="card-body">
                            {
                                !ready && (
                                    <div className="text-center">
                                        <span>Loading...</span>
                                        <Spinner animation="border" role="status" size="sm" style={{ fontSize: '8px', marginLeft: '5px' }}>
                                            <span className="visually-hidden">loading...</span>
                                        </Spinner>
                                    </div>
                                )
                            }
                            <div id="bankStmIframe"></div>
                            <div id="bankStmUploading" className="d-none">
                                <div className="page-header">
                                    <h1 className="text-success" style={{ marginBottom: "30px" }}>
                                        Uploading bank statement
                                    </h1>
                                    <div>
                                        <em>
                                            Please wait few minutes for the collection process to complete.
                                            <br/>
                                            Please <a id="bankStmContinue" href="" className="text-info" style={{ fontWeight: "bold" }}>click here</a> to continue.
                                        </em>
                                    </div>
                                </div>

                                <div className="d-none">
                                    <p className="lead ng-binding">
                                        Bank account statement:
                                        <span style={{ marginLeft: "10px" }} retries="0">
                                            <span className="BankResultLabel success">
                                                <FontAwesomeIcon icon={'icon-ok'} style={{ marginTop: "6px" }}/>
                                                success 
                                            </span>
                                        </span>
                                        <br />
                                    </p>
                                    <div><em>Please wait few minutes for the collection process to complete.</em></div>
                                </div>
                            </div>
                            <div id="bankStmError d-none" className="d-none">
                                <div className="page-header">
                                    <h1 id="bankStmErrorHeader" className="text-danger" style={{ marginBottom: "30px" }}>
                                        Sorry, something went wrong
                                    </h1>
                                    <div id="bankStmErrorMsg"><em>Please <a id="bankStmRetry" href="" className="text-info" style={{ fontWeight: "bold" }}>click here</a> to try again.</em></div>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreditSense