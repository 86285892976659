import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useAuthContext } from '../../hooks/auth'
import MembersTabNav from './membersTabNav';
import { Outlet, useLocation } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

const Member = () => {
    const { consumer } = useAuthContext();
    const [consumerDetails, setConsumerDetails] = useState(null);
    const [countdown, setCountDown] = useState(null);
    const [loan, setLoan] = useState(null);
    const location = useLocation();

    const getConsumer = () => {
        axios.get('/consumers/' + consumer.token)
        .then((response) => {
            if(response.status === 200) {
                setConsumerDetails(response.data.data.consumer);
                setLoan(response.data.data.loan);
                setCountDown(response.data.data.countdown);
            }
        })
        .catch((err) => {
            const error = err.response?.data?.error ? err.response?.data?.error : err.message;
            global.config.methods.errorResponse(error);
        })
    }

    useEffect(() => {
        if(consumer) {
            getConsumer();
        }
    }, [consumer]);

    useEffect(() => {
        getConsumer();
    }, [location.pathname]);

    return (
        <div className="public-container container-fluid">
            <div className="row bg-light" style={{ minHeight: 'inherit' }}>
                <MembersTabNav/>
                {
                    consumerDetails ? (
                        <div className="col-md-9 py-4">
                            <Outlet context={[
                                consumerDetails, setConsumerDetails,
                                loan, setLoan,
                                countdown, setCountDown
                            ]}/>
                        </div>
                    )
                    :
                    <div className="col-md-9 py-4 text-center">
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>   
                    </div>
                }
            </div>
        </div>
    )
}

export default Member