import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <footer>
            <div style={{ backgroundImage: 'url(/img/footer-background-image.jpg)', backgroundPosition: 'center center', backgroundSize: 'cover' }}>
                <div className="container text-uppercase" style={{ padding: '75px 0px' }}> 
                    <div className="row text-md-left">
                        <div className="col-md-4 mb-5 mb-md-0">
                            <h6 className="px-3 mb-3 text-uppercase text-white">Legal</h6>
                            <ul className="nav flex-column">
                                <li className="nav-item">
                                    <a className="w-100 nav-link footer-nav-link text-white-50 text-md-left" href="/pdf/CashBridge_Credit-Information-Privacy-Management-Policy.pdf" target='_blank'>Credit Information (Privacy) Management Policy</a>
                                </li>
                                <li className="nav-item">
                                    <a className="w-100 nav-link footer-nav-link text-white-50 text-md-left" href="/pdf/CashBridge_Credit-Providers-Credit-Guide.pdf" target="_blank">Credit Provider&#8217;s Credit Guide</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-4 mb-5 mb-md-0 border-left border-right">
                            <h6 className="px-3 mb-3 text-uppercase text-white">Menu</h6>
                            <ul className="nav flex-column">
                                <li className="nav-item"><Link to={'/'} className='w-100 nav-link footer-nav-link text-white-50'>Home</Link></li>
                                <li className="nav-item"><Link to={'/contact-us'} className='w-100 nav-link footer-nav-link text-white-50'>Contact</Link></li>
                                <li className="nav-item"><Link to={'/apply'} className='w-100 nav-link footer-nav-link text-white-50'>Apply</Link></li>
                                <li className="nav-item"><Link to={'/members'} className='w-100 nav-link footer-nav-link text-white-50'>Member</Link></li>
                            </ul>
                        </div>
                        <div className="col-md-4">
                            <Link to={'/'}>
                                <img src='/img/Cashbridge_White.png' alt='footer-img' className="img-fluid" style={{ maxWidth: '250px' }}/>
                            </Link>
                            <p className="px-3 text-white"><span className="text-nowrap">Level 9, 530 Little Collins St,</span> <span className="text-nowrap">Melbourne VIC 3000</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-dark p-4 text-white-50">
                <div className="row text-center">
                    <div className="col-md-6 text-md-left">
                        <p className="mb-0">ABN 39 611 822 916 | Australian Credit License 505901</p>
                    </div>
                    <div className="col-md-6 text-md-right">
                        <p className="mb-0">{ new Date().getFullYear() } © Cash Bridge. All right reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer