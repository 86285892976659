import swal from 'sweetalert2';

export const successResponse = async (data) => {
    if(data.isHTML) {
        return await new swal({
            title: data.title,
            icon: 'success',
            html: data.html,
            allowOutsideClick: false,
            confirmButtonColor: '#e0b866',
        })
    } else {
        return await new swal({
            title: data.title,
            text: data.text,
            icon: 'success',
            confirmButtonColor: '#e0b866'
        })
    }
}

export const warningResponse = (text) => {
    new swal({
        title: 'Warning!',
        text: text,
        icon: 'warning',
        confirmButtonColor: 'cornflowerblue'
    })
}

export const errorResponse = (text = null) => {
    new swal({
        title: 'Error!',
        text: text ? text : '',
        icon: 'error',
        confirmButtonColor: 'cornflowerblue'
    })
}

export const questionResponse = async (data) => {
    return await new swal({
        title: data.title,
        icon: 'question',
        text: data.text,
        showCancelButton: true,
        confirmButtonColor: '#b3e0ff',
        cancelButtonColor: 'lightgrey'
    })
}

export const validator = (data) => {
    var ret = {
        status: false,
        message: ''
    };

    const keys = Object.keys(data);
    for(var i=0;i<keys.length;i++){
        const val = data[keys[i]];
        if(val === ''){
            ret.message = keys[i][0].toUpperCase() + keys[i].slice(1) + ' cannot be empty!';
            return ret;
        }
    }
    ret.status = true;
    return ret;
}

export const appendImage = (data) => {
    if (data.files && data.files[0]) {
        data.callback();
    //   setImage(URL.createObjectURL(data.files[0]));
    }
}

export const capitalizeFirst = (string) => {
    var first = string[0].toUpperCase();
    return first + string.slice(1);
}

export const capitalizeWords = (string) => {
    var array = string.split(' ');
    var stringArray = [];
    if(array.length > 0) {
        for(var i=0;i<array.length;i++) {
            stringArray.push(array[i][0].toUpperCase() + array.slice(1));
        }
    }
    return stringArray.join(' ');
}

export const getBase64 = (file) => {
    return new Promise(resolve => {
        var reader = new FileReader();
        reader.readAsDataURL(file)
        reader.onload = function () {
            resolve(reader.result)
        }
        reader.onerror = function (error) {
            resolve(false)
        };
    })
}

export const toCurrency = (val) => {
    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(val?.toString());
}