import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useOutletContext } from 'react-router-dom';
import { useAuthContext } from '../../hooks/auth';
import Spinner from 'react-bootstrap/Spinner';

const MemberDocuments = () => {
    const { consumer } = useAuthContext();
    const [consumerDetails, setConsumerDetails] = useOutletContext();
    const [documents, setDocuments] = useState(null);
    const [ready, setReady] = useState(false);

    const getDocuments = () => {
        axios.get('/consumers/' + consumer.token + '/contracts')
        .then((response) => {
            if(response.status === 200) {
                setReady(true);
                setDocuments(response.data.data.contracts);
            }
        })
        .catch((err) => {
            const error = err.response?.data?.error ? err.response?.data?.error : err.message;
            global.config.methods.errorResponse(error);
        })
    }

    useEffect(() => {
        getDocuments();
    }, [])

    return (
        <div className="documents">
            <h3>Documents</h3>
            <div className="card">
                <div className="card-body table-responsive">
                    <table className="table table-sm">
                        <thead>
                            <tr>
                                <th className="">Contract No.</th>
                                <th className="text-center">Contract Document</th>
                                <th className="text-center">Privacy Policy</th>
                                <th className="text-center">Information Statement</th>
                            </tr>
                        </thead>
                        {
                            ready ? 
                            <tbody>
                            {
                                consumerDetails && documents && documents.length > 0 ? 
                                    documents.map((contract, index) => {
                                        return (
                                            <React.Fragment key={contract.token}>
                                                <tr>
                                                    <td className="">{ contract.contractNo }</td>
                                                    <td className="text-center">
                                                        <Link to={'/members/contracts/' + contract.token} className="btn btn-primary btn-sm">
                                                            <FontAwesomeIcon icon={'fas fa-file-contract'} />
                                                            View
                                                        </Link>
                                                    </td>
                                                    <td className="text-center">
                                                        <a href="/pdf/CashBridge_Credit-Information-Privacy-Management-Policy.pdf" 
                                                        className="btn btn-primary btn-sm" 
                                                        target="_blank"
                                                        style={{ display: 'inline-flex' }}>
                                                            <FontAwesomeIcon icon={'fas fa-file-alt'}/>
                                                            View
                                                        </a>
                                                    </td>
                                                    <td className="text-center">
                                                        <a href="/pdf/CashBridge_Credit-Providers-Credit-Guide.pdf" 
                                                        className="btn btn-primary btn-sm" 
                                                        target="_blank"
                                                        style={{ display: 'inline-flex' }}>
                                                            <FontAwesomeIcon icon={'fas fa-file-alt'}/>
                                                            View
                                                        </a>
                                                    </td>
                                                </tr>
                                                {
                                                    contract.extraContracts?.map((extra) => {
                                                        return (
                                                            <tr key={extra.id}>
                                                                <td className="border-0 text-center">
                                                                    {'Reschedule #' + extra.contractNo}
                                                                </td>
                                                                <td className="text-center">
                                                                    <Link to={'/members/contracts/' + extra.token} className="btn btn-primary btn-sm">
                                                                        <FontAwesomeIcon icon={'fas fa-file-contract'}/> View
                                                                    </Link>
                                                                </td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </React.Fragment>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td className="text-center" colSpan="4">You currently don't have a document to view.</td>
                                    </tr>
                            }
                            </tbody>
                            :
                            <tbody>
                                <tr>
                                    <td className="text-center" colSpan="4">
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    </td>
                                </tr>
                            </tbody>
                        }
                    </table>
                </div>
            </div> 
        </div>
    )
}

export default MemberDocuments