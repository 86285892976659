import React from 'react';
import { Outlet } from 'react-router-dom';
import TopNav from '../components/TopNav';
import Footer from '../components/Footer';

const Session = () => {
    return (
        <div>
            <TopNav />
            <Outlet />
            <Footer />
        </div>
    )
}

export default Session