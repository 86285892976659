import $ from 'jquery';
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useOutletContext } from 'react-router-dom';
import ContractTemplate from './contractTemplate';
import axios from 'axios';

const MemberLoanConfirm = () => {
    const [confirmPurpose, setConfirmPurpose] = useState(false);
    const [confirmAgreement, setConfirmAgreement] = useState(false);
    const [confirmRepayment, setConfirmRepayment] = useState(false);
    const [consumerDetails, setConsumerDetails, loan, setLoan] = useOutletContext();
    const [accepted, setAccepted] = useState(null);
    const isExtraContract = false;
    const navigate = useNavigate();

    const confirmLoan = (val) => {
        if(val === 1) {
            setAccepted(val);
            $(".confirm-amount").slideToggle();

            setTimeout(() => {
                $(".approved-contract").slideToggle();
            }, 200);

            setTimeout(() => {
                $(".down-confirm-contract").slideToggle();
                $(".confirm-contract").slideToggle();
            }, 500);
        } else {
            setAccepted(val);
            axios.get('/loans/' + loan.token + '/decline')
            .then((response) => {
                if(response.status === 200) {
                    $(".declined-contract").slideToggle();

                    setTimeout(() => {
                        $(".confirm-amount").slideToggle();
                    }, 200);
                }
            })
            .catch((err) => {
                const error = err.response?.data?.error ? err.response?.data?.error : err.message
                global.config.methods.errorResponse(error);
            })
        }
    }

    const creditContract = async (e) => {
        const data = {
            title: 'Would you like to sign the contract?'
        };
        const response = await global.config.methods.questionResponse(data);
        if(!response.isConfirmed){
            return false;
        }

        const formData = new FormData();
        formData.append('confirmAgreement', confirmAgreement);
        formData.append('confirmPurpose', confirmPurpose);
        formData.append('confirmRepayment', confirmRepayment);

        axios.post('/loans/' + loan.token + '/confirm', formData)
        .then((response) => {
            if(response.status === 200) {
                let html = '';
                if (isExtraContract) {
                    html =  '<p>You have completed your set-up.</p>' +
                            '<p>You can view your credit contract and other relevant documents in your members page.</p>';
                }
                else {
                    html =  '<p>You have completed your set-up. You will receive your money within 24 hours.</p>' +
                            '<p>You can view your credit contract and other relevant documents in your members page.</p>';
                }
                const data = {
                    title: '<strong>Congratulations</strong>',
                    html: html,
                    isHTML: true
                };
                
                global.config.methods.successResponse(data)
                .then((result) => {
                    if(result.isConfirmed) {
                        window.location.reload();
                    }
                });
            }
        })
        .catch((err) => {
            const error = err.response?.data?.error ? err.response?.data?.error : err.message;
            global.config.methods.errorResponse(error);
        })
    }

    const printContract = (e) => {
        e.preventDefault();
        var printContents = $('.loan-contract-document').html();
        $('body').html(printContents);
        window.print();
        window.location.reload();
    }

    useEffect(() => {
        if(loan?.stagesId !== 9) {
            navigate('/members/dashboard');
        }
    }, [consumerDetails]);

    useEffect(() => {
        if(confirmAgreement && confirmPurpose && confirmRepayment) {
            $('.div-contract-submit').slideDown();
        }else {
            $('.div-contract-submit').slideUp();
        }
    }, [confirmAgreement, confirmPurpose, confirmRepayment]);

    return (
        <div className="loanConfirm">
            {
                isExtraContract ? 
                <div className="card">
                    <div className="card-body border-bottom">
                        <h4 className="card-text my-3 text-center">Final Step! Confirm your new schedule.</h4>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title text-danger">Important - Action Required</h5>
                        <p className="card-text">Almost done!</p>
                        <p className="card-text">All that's left to do is:</p>
                        <ul>
                            <li><strong>Scroll through your "New Loan Schedule".</strong></li>
                            <li>Review the documents below.</li>
                            <li>Once you're happy, click "I Agree".</li>
                        </ul>

                        <div className="card-body text-right px-0 pt-0">
                            <button type="button" 
                            className="btn btn-primary" 
                            id="print-contract" title="Print Contract"
                            onClick={printContract}>
                                <FontAwesomeIcon icon={'fas fa-print'}/>
                            </button>
                        </div>

                        {/* {{ partial('layouts/extraContractTemplate') }} */}

                        {/* <div className="div-contract-submit">
                            <div className="text-center">
                                <button className="btn btn-primary btn-lg">test</button>
                            </div>
                        </div> */}
                    </div>
                </div>
                :
                <div className="card">
                    <div className="card-body border-bottom confirm-amount">
                        <h4 className="card-text my-3 text-center confirm-text">Pre-approved amount</h4>
                        <h3 className="card-text text-center">${ parseInt(loan?.contract?.amount) }</h3>
                        <hr/>
                        <h5 className="card-title text-center">Does the approved amount fit your purpose?</h5>
                        <div className="row">
                            <div className="col-sm-2"></div>
                            <div className="col-12 col-sm-8">
                                <div className="alert alert-info text-center">
                                    *Your contract will be declined if the approved amount does not fit your purpose.
                                </div>
                            </div>
                            <div className="col-sm-2"></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-2"></div>
                            <div className="col-6 col-sm-4 text-center">
                                <button className="btn btn-info btn-block confirm-amount-yes w-100" onClick={() => {confirmLoan(1)}}>YES</button>
                            </div>
                            <div className="col-6 col-sm-4 text-center">
                                <button className="btn btn-danger btn-block confirm-amount-no w-100" onClick={() => {confirmLoan(0)}}>NO</button>
                            </div>
                            <div className="col-sm-2"></div>
                        </div>
                    </div>
                    <div className="card-body border-bottom confirm-contract-title">
                        <h5 className="card-text text-center text-muted approved-contract" style={{ display: 'none' }}>Your loan has been approved.</h5>
                        <h4 className="card-text my-3 text-center">Final Step! Confirm your credit contract.</h4>
                        <h5 className="card-text text-center text-muted down-confirm-contract">You will see your contract details below.</h5>
                        <h3 className="card-text text-center text-muted down-confirm-contract">
                            <FontAwesomeIcon icon={'fas fa-angle-double-down'}/>
                        </h3>
                    </div>
                    <div className="card-body confirm-contract" style={{ display: 'none' }}>
                        <h5 className="card-title text-danger">Important - Action Required</h5>
                        <p className="card-text">Almost done and you'll have your money soon!</p>
                        <p className="card-text">All that's left to do is:</p>
                        <ul>
                            <li><strong>Scroll through your "Loan Contract".</strong></li>
                            <li>Review the documents below.</li>
                            <li>Once you're happy, click "I Agree".</li>
                        </ul>

                        <div className="card-body text-end px-0 pt-0">
                            <button type="button" 
                            className="btn btn-primary" 
                            id="print-contract" 
                            title="Print Contract"
                            onClick={printContract}>
                                <FontAwesomeIcon icon={'fas fa-print'}/>
                            </button>
                        </div>

                        <ContractTemplate 
                        loan={loan}
                        contract={loan.contract}
                        consumer={consumerDetails}
                        setConsumerDetails={setConsumerDetails}
                        setConfirmPurpose={setConfirmPurpose}
                        setConfirmAgreement={setConfirmAgreement}
                        setConfirmRepayment={setConfirmRepayment}
                        />

                        <div className="div-contract-submit" style={{ display: 'none' }}>
                            <div className="text-center">
                                <button className="btn btn-primary btn-lg" onClick={creditContract}>I AGREE</button>
                            </div>
                        </div>
                    </div>
                    {
                        accepted === 0 && (
                            <div className="card-body decline-contract">
                                <h5 className="card-title text-center text-secondary">Your credit contract has been declined.</h5>
                            </div>
                        )
                    }
                </div>
            }
            </div>
    )
}

export default MemberLoanConfirm