import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Next } from './next';
import Swal from 'sweetalert2';
import axios from 'axios';

const StageTwo = ({ 
    loan, 
    setLoan, 
    loanCriteria, 
    setLoanCriteria, 
    showLoader, 
    setShowLoader, 
    getLoanDetails 
}) => {
    const [totalIncome, setTotalIncome] = useState(0);
    const [totalExpenses, setTotalExpenses] = useState(0);
    const [surplusIncome, setSurplusIncome] = useState(0);
    const [validationError, setValidationError] = useState([]);
    const [dependent, setDependent] = useState(null);
    const [otherLoans, setOtherLoans] = useState(null);
    var expensesObject = {};
    loanCriteria.filter(x => x.divId === 'expenses').map((crit) => {
        expensesObject[crit.token] = 0;
    })
    const [expenses, setExpenses] = useState(expensesObject);

    useEffect(() => {
        setSurplusIncome(totalIncome - totalExpenses);
    }, [totalExpenses]);

    useEffect(() => {
        if(loan.applications.expenses && loan.applications.expenses.length > 0) {
            for(const key in loan.applications.expenses) {
                const exp = loan.applications.expenses[key];
                const criteria = loanCriteria.find(x => x.id === exp.criteriaId);
                const token = criteria.token;
                const valueArray = exp.value.split('|');
                const value = valueArray[0];
                expenses[token] = parseFloat(value);
                setExpenses({...expenses})
            }
        }

        if(loan.applications.employmentDetails) {
            setSurplusIncome(0);
            setTotalIncome(0);
            for(var i=0;i<loan.applications.employmentDetails.length;i++) {
                const employmentDetail = loan.applications.employmentDetails[i];
                const paymentCycle = employmentDetail.value.paymentCycle;
                const amount = parseFloat(employmentDetail.value.amount);
                if(paymentCycle === '9SOC3vcL-Y9O4') { 
                    // Monthly
                    setSurplusIncome(prev => prev + amount)
                    setTotalIncome(prev => prev + amount);
                } else if(paymentCycle === '9SOC3vcL-gdPJ') {
                    // Fornightly
                    setSurplusIncome(prev => prev + amount*26/12)
                    setTotalIncome(prev => prev + amount*26/12);
                } else {
                    // Weekly
                    setSurplusIncome(prev => prev + amount*52/12)
                    setTotalIncome(prev => prev + amount*52/12);
                }
            }
        }

        if(loan.applications.dependentNumber) {
            const criteria = loanCriteria.find(x => x.id === loan.applications.dependentNumber.criteriaId);
            if(!criteria) {
                global.config.methods.errorResponse('Error. Dependant criteria not found.');
                return false;
            }
            setDependent({
                token: criteria.token,
                value: loan.applications.dependentNumber.value
            });
        }

        if(loan.applications.extraExpenses) {
            const criteria = loanCriteria.find(x => x.id === loan.applications.extraExpenses.criteriaId);
            if(!criteria) {
                global.config.methods.errorResponse('Error. Dependant criteria not found.');
                return false;
            }
            setOtherLoans({
                token: criteria.token,
                value: loan.applications.extraExpenses.value
            });
        }
    }, []);

    const updateExpenses = (e) => {
        const token = e.target.dataset.token;
        setExpenses(prev => ({...prev, [token]: parseFloat(e.target.value.replaceAll(',',''))}));
    }

    const validateFields = (e) => {
        if(e.target.value){
            const index = validationError.findIndex(x => x === e.target.id);
            if(index !== -1) {
                validationError.splice(index, 1);
                setValidationError([...validationError]);
            }
        }else{
            if(!validationError.includes(e.target.id)){
                validationError.push(e.target.id);
                setValidationError([...validationError]);
            }
        }

        if(e.target.id === 'dependentNumber') {
            setDependent({
                token: e.target.dataset.token,
                value: e.target.value
            });
        } else if (e.target.id === 'otherLoans') {
            setOtherLoans({
                token: e.target.dataset.token,
                value: e.target.value
            });
        }
    }   

    const next = async () => {
        if(!dependent) {
            if(!validationError.includes('dependentNumber')) {
                validationError.push('dependentNumber');
                setValidationError([...validationError]);
            }
        }

        if(!otherLoans){
            if(!validationError.includes('otherLoans')) {
                validationError.push('otherLoans');
                setValidationError([...validationError]);
            }
        }

        if(validationError.length === 0){
            if(totalExpenses === 0){
                const response = await new Swal({
                    title: 'Confirm your total expense',
                    icon: 'question',
                    iconColor: '#e0b866',
                    text: 'Your total expense is $0.00',
                    showCancelButton: true,
                    confirmButtonColor: '#e0b866',
                    confirmButtonText: 'Confirm',
                    cancelButtonColor: 'lightgrey'
                })

                if(!response.isConfirmed) {
                   return false;
                }
            }
            setShowLoader(true);
            var formdata = {
                dependentNumber: JSON.stringify(dependent),
                otherLoans: JSON.stringify(otherLoans),
                expenses: JSON.stringify(expenses)
            };

            axios.post('/loans/' + loan.token + '/manage', formdata)
            .then((response) => {
                setShowLoader(false);
                if(response.status === 200) {
                    getLoanDetails();
                }
            })
            .catch((err) => {
                setShowLoader(false);
                const error = err.response?.data?.error ? err.response?.data?.error : err.message;
                global.config.methods.errorResponse(error);
            })
        }
    }

    const focusInput = (e) => {
        const token = e.target.dataset.token;
        if((expenses[token].toString().length === 1) && e.keyCode === 8) {
            setExpenses(prev => ({...prev, [token]: 0}));
            e.preventDefault();
            return false;
        }

        if(e.type === 'keydown') {
            var k = e.which;
            //For integers only
            if ( (k < 48 || k > 57) && 
            (k < 96 || k > 105) && 
            k!== 8 && 
            k!==37 && 
            k!== 39) {
                e.preventDefault();
                return false;
            }
        }
    }

    const toCurrencyWithoutDecimals = (value) => {
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value.toString());
    }

    useEffect(() => {
        setTotalExpenses(0);
        Object.keys(expenses).map((key) => {
            setTotalExpenses(prev => prev += parseFloat(expenses[key]));
        });
    }, [expenses]);

    return (
        <div className="step1-1 application-step position-relative mb-4" 
        id="applicationStep1-1">
            <div className="card form mb-4" 
            id="yourExpenses">
                <div className="card-body">
                    <h3 className="card-title text-center">Your Monthly Expenses</h3>
                    <p className="card-text text-center mb-0">To offer you affordable repayments we need to know about your financial commitments</p>
                    <p className="card-text text-center text-info">By providing an accurate estimate, it will speed up your application process.</p>

                    <div className="card mb-3">
                        <div className="card-body expenses">
                            {
                                loanCriteria.filter(x => x.divId === 'expenses').map((crit) => {
                                    return (
                                        <div className={"form-group row " + crit.formId} key={crit.token}>
                                            <label htmlFor={crit.formId} className="col-sm-6 col-form-label">
                                                { crit.name }
                                                <small className="text-muted">
                                                    <FontAwesomeIcon 
                                                    icon={'fas fa-info-circle'} 
                                                    data-toggle="tooltip" 
                                                    data-placement="auto" 
                                                    title={ crit.question }/>
                                                </small>
                                            </label>
                                            <div className="col-sm-6">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text">$</div>
                                                    </div>
                                                    <input type="text" 
                                                    id={crit.formId} 
                                                    className="form-control text-end criteriaForm expenses expenses-field"
                                                    data-token={crit.token}
                                                    maxLength={10}
                                                    value={toCurrencyWithoutDecimals(expenses[crit.token])}
                                                    onChange={updateExpenses}
                                                    onKeyUp={updateExpenses}
                                                    onKeyDown={focusInput}
                                                    onClick={focusInput}
                                                    onBlur={toCurrencyWithoutDecimals}/>
                                                    <div className="input-group-append">
                                                        <span className="input-group-text">Monthly</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="card-body border-top total">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="totalIncome">Total income</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">$</div>
                                            </div>
                                            <input 
                                            type="text"
                                            id="totalIncome"
                                            className="form-control text-end totalIncome expenses-field"
                                            value={global.config.methods.toCurrency(totalIncome)}
                                            disabled
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">Monthly</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <label htmlFor="totalExpense">Total Expense</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">$</div>
                                            </div>
                                            <input 
                                            type="text"
                                            className="form-control text-end totalExpense expenses-field"
                                            value={global.config.methods.toCurrency(totalExpenses)}
                                            disabled
                                            />
                                            <div className="input-group-append">
                                                <span className="input-group-text">Monthly</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="surplusIncome">Surplus Income</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <div className="input-group-text">$</div>
                                            </div>
                                            {/* <input type="text"/> */}
                                            <input type="text" 
                                            className="form-control text-end surplusIncome expenses-field"
                                            value={global.config.methods.toCurrency(surplusIncome)}
                                            disabled/>
                                            <div className="input-group-append">
                                                <span className="input-group-text">Monthly</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        loanCriteria.filter(x => x.divId === 'expensesExtra').map((crit) => {
                            return (
                                <div className="form-group row" key={crit.id}>
                                    <label htmlFor={ crit.formId } 
                                        className="col-sm-9 col-form-label">{ crit.question }
                                        <FontAwesomeIcon icon={'fas fa-asterisk'} className="fa-xs required-star"/>
                                    </label>
                                    <div className="col-sm-3">
                                        {
                                            crit.type.type === 'text' ? 
                                            <input type="text" className="form-control criteriaForm validation-required" data-token={crit.token}/> :
                                            crit.type.type === 'number' ? 
                                            <input type="number" className="form-control criteriaForm validation-required" data-token={crit.token}/> :
                                            crit.type.type === 'select' && 
                                                <>
                                                {
                                                    crit.type.isYN ? 
                                                    <select 
                                                    id={crit.formId} 
                                                    className="form-control criteriaForm validation-required" 
                                                    style={{ borderColor: validationError && validationError.includes(crit.formId) && 'red' }}
                                                    data-token={crit.token}
                                                    defaultValue={crit.formId === 'otherLoans' ? (otherLoans?.value || '') : ''}
                                                    onChange={validateFields}>
                                                        <option value={''}>Select</option>
                                                        <option value={1}>Yes</option>
                                                        <option value={0}>No</option>
                                                    </select>:
                                                    <select
                                                    id={crit.formId} 
                                                    className="form-control criteriaForm validation-required" 
                                                    style={{ borderColor: validationError && validationError.includes(crit.formId) && 'red' }}
                                                    data-token={crit.token}
                                                    value={crit.formId === 'dependentNumber' ? (dependent?.value || '') : ''}
                                                    onChange={validateFields}>
                                                        <option value={''}>Select</option>
                                                        {
                                                            crit.answers && crit.answers.map((ans) => {
                                                                return (
                                                                    <option value={ans.token} key={ans.token}>{ans.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select> 
                                                }
                                                {
                                                    validationError && validationError.includes(crit.formId) && (
                                                        <div className="invalid-feedback show-error">Must be answered.</div>
                                                    )
                                                }
                                                </>
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            {/* 
            <div className="card mb-3" id="identification">
                <div className="card-header">
                    IDENTIFICATION NUMBERS <small className="text-muted">- Identification purposes only</small>
                </div>
                <div className="card-body">
                    <div className="row">
                        {% for app in identificationApplications %}
                        <div className="col-sm-6" id="{{ app.token }}">
                            <div className="form-group">
                                <label htmlFor="{{ app.criteria.formId }}">{{ app.criteria.question }} 
                            {% if app.criteria.placeholder %}
                                    <small>({{ app.criteria.placeholder }})</small>
                            {% endif %}
                                </label>
                            {% if app.criteria.formId == 'medicareNumber' %}
                                <div className="form-row">
                                    <div className="col pr-0">
                                        {{ text_field(app.criteria.formId, 'class': 'form-control criteriaForm',
                                        'maxlength': '10', 'placeholder': '1234567890', 'data-token': app.criteria.token) }}
                                        <!-- {{ text_field(app.criteria.formId, 'class': 'form-control criteriaForm validation-required',
                                        'maxlength': '10', 'placeholder': '1234567890', 'data-token': app.criteria.token) }} -->
                                    </div>
                                    <div className="col-1 px-0 pt-2 text-center">/</div>
                                    <div className="col-3 pl-0">
                                        {{ text_field('medicareNumberIRN', 'class': 'form-control criteriaForm',
                                        'maxlength': '1', 'placeholder': 'IRN', 'size': '1') }}
                                    </div>
                                    <!-- <div className="col-3 pl-0">
                                        {{ text_field('medicareNumberIRN', 'class': 'form-control criteriaForm validation-required',
                                        'maxlength': '1', 'placeholder': 'IRN', 'size': '1') }}
                                    </div> -->
                                </div>
                                <script type="text/javascript">
                                $(function (){
                                    $('#{{ app.criteria.formId }}, #medicareNumberIRN').on('focus', function (){
                                        this.select();
                                    });
                                });
                                </script>
                            {% else %}
                                <div className="form-row">
                                    <div className="col-12">
                                        {{ text_field(app.criteria.formId, 'class': 'form-control criteriaForm',
                                        'maxlength': app.criteria.digit, 'placeholder': app.criteria.placeholder, 'data-token': app.criteria.token) }}
                                        <!-- {{ text_field(app.criteria.formId, 'class': 'form-control criteriaForm validation-required',
                                        'maxlength': app.criteria.digit, 'placeholder': app.criteria.placeholder, 'data-token': app.criteria.token) }} -->
                                    </div>
                                </div>
                            {% endif %}
                            {% if app.criteria.formId == 'photoIdNumber' %}
                                <small className="form-text text-info">Driver's License, Passport or Proof of Age must be provided.</small>
                            {% endif %}
                            </div>
                        </div>
                        {% endfor %}
                    </div>
                </div>
            </div> */}
            <Next callback={next}/>
        </div>
    )
}

export default StageTwo