import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';

const ResetPassword = () => {
    const form = useRef(null);
    const [error, setError] = useState(null);
    const { code, email } = useParams();
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [validationError, setValidationError] = useState([]);
    const [consumersToken, setConsumersToken] = useState(null);
    const [success, setSuccess] = useState(null);
    
    const checkValidity = () => {
        axios.get(`/consumers/${email}/code/${code}`)
        .then((response) => {
            if(response.status) {
                setConsumersToken(response.data.data.token);
            }
        })
        .catch((err) => {
            const error = err.response?.data?.error ? err.response?.data?.error : err.message;
            navigate('/forgot-password', {
                state: {
                    error: error
                }
            });
        })
    }

    const changePassword = (e) => {
        e.preventDefault();
        var validation = [];

        if(!password) {
            validation.push('Password is required.');
        }

        if(!confirmPassword) {
            validation.push('The confirmation password is required.');
        }
       
        if (password.length < 6 || password.length > 12 ) {
            validation.push('Password is too short. Minimum 6 characters. Maximum 12 characters.');
        } 

        if(!/^(?=.*[a-zA-Z])(?=.*[0-9]).{0,50}$/.test(password)) {
            validation.push('Password should contain letter and number.');
        }

        if(password !== confirmPassword) {
            validation.push(`Password doesn't match confirmation`);
        }

        setValidationError(validation);
        if(validation.length > 0) {
            return false;
        }

        var formdata = new FormData();
        formdata.append('password', password);
        formdata.append('confirmPassword', confirmPassword);
        formdata.append('code', code);
        formdata.append('email', email);

        axios.post(`/consumers/${consumersToken}/password/reset`, formdata)
        .then((response) => {
            if(response.status === 200) {
                setSuccess('Successfully reset your password.');
                setPassword('');
                setConfirmPassword('');
                setTimeout(() =>{
                    navigate('/login');
                }, 3000);
            }
        })
        .catch((err) => {
            if(err.response?.data?.validationError) {
                setValidationError(err.response?.data?.validationError);
                setPassword('');
                setConfirmPassword('');
            } else {
                const error = err.response?.data?.error ? err.response?.data?.error : err.message;
                setError(error);
            }
        })
    }

    useEffect(() => {
        checkValidity();
    }, []);

    return (
        <div className="container public-container py-4 pt-5">
            {
                success && (
                    <div className="alert alert-success">{ success }</div>
                )
            }
            {
                error && (
                    <div className="alert alert-danger">{ error }</div>
                )
            }
            {
                validationError && validationError.map((err) => {
                    return (
                        <div className="alert alert-danger">{ err }</div>
                    )
                })
            }
            <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="card-title">Change Password</h2>

                            <form ref={form}>
                                <div className="center scaffold">
                                    <div className="form-group">
                                        <label htmlFor="password">Password</label>
                                        <input 
                                        type="password" 
                                        className="form-control" 
                                        id="password" 
                                        maxLength={12} 
                                        autoComplete="new-password"
                                        onChange={(e) => {setPassword(e.target.value)}}/>
                                    </div>
                            
                                    <div className="form-group">
                                        <label htmlFor="confirmPassword">Confirm Password</label>
                                        <input 
                                        type="password" 
                                        className="form-control" 
                                        id="confirmPassword" 
                                        maxLength={12} 
                                        autoComplete="new-password"
                                        onChange={(e) => {setConfirmPassword(e.target.value)}}/>
                                    </div>
                                    <div className="text-end">
                                        <button type="submit" className="btn btn-primary" onClick={changePassword}>Change Password</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword